import dotenv from 'dotenv'

dotenv.config({ path: '.env.local' })

const envVars = {
  NEXT_PUBLIC_BASEURL: process.env.NEXT_PUBLIC_BASEURL,
  NEXT_PUBLIC_SERVICE_BASEURL: process.env.NEXT_PUBLIC_SERVICE_BASEURL,
  NEXT_PUBLIC_HEALTH_URL: process.env.NEXT_PUBLIC_HEALTH_URL,
  NEXT_PUBLIC_HEALTH_URL_V3: process.env.NEXT_PUBLIC_HEALTH_URL_V3,
  NEXT_PUBLIC_HUBSPOT_URL: process.env.NEXT_PUBLIC_HUBSPOT_URL,
  NEXT_PUBLIC_MARKETING_ROOT_URL: process.env.NEXT_PUBLIC_MARKETING_ROOT_URL,
  NEXT_PUBLIC_GOODS_ID: process.env.NEXT_PUBLIC_GOODS_ID,
  NEXT_PUBLIC_ACCESSORY_PACKAGE_BIGCOMMERCE_ID:
    process.env.NEXT_PUBLIC_ACCESSORY_PACKAGE_BIGCOMMERCE_ID,
  NEXT_PUBLIC_RPC_CLIENT_URL: process.env.NEXT_PUBLIC_RPC_CLIENT_URL,
  NEXT_PUBLIC_RPC_CLIENT_SECRET: process.env.NEXT_PUBLIC_RPC_CLIENT_SECRET,
  NEXT_PUBLIC_ORDER_URL: process.env.NEXT_PUBLIC_ORDER_URL,
  NEXT_PUBLIC_FULFILLMENT_URL: process.env.NEXT_PUBLIC_FULFILLMENT_URL,
  NEXT_PUBLIC_AUTH_BASEURL: process.env.NEXT_PUBLIC_AUTH_BASEURL,
  NEXT_PUBLIC_AUTH_API_KEY: process.env.NEXT_PUBLIC_AUTH_API_KEY,
  NEXT_PUBLIC_SCHEDULE_PHONE_CALL_URL:
    process.env.NEXT_PUBLIC_SCHEDULE_PHONE_CALL_URL,
  NEXT_PUBLIC_ENVIDENTLY_ACCESS_KEY:
    process.env.NEXT_PUBLIC_ENVIDENTLY_ACCESS_KEY,
  NEXT_PUBLIC_ENVIDENTLY_SECRET_ACCESS_KEY:
    process.env.NEXT_PUBLIC_ENVIDENTLY_SECRET_ACCESS_KEY
}

export const getEnvVar = (key: keyof typeof envVars): string => {
  const envVar = envVars[key]
  if (!envVar) {
    throw new Error(`Couldn't find environment variable: ${key}`)
  } else {
    return envVar
  }
}

export default getEnvVar
