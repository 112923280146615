import { ax, axService } from '../../AxiosFactory'

const requestDataExport = async (uuid) => {
  const res = await axService.post('user_data/user_data:request_export', {
    user_id: uuid
  })
  return res
}

const requestDataDeleteAll = async (uuid) => {
  const res = await axService.post('user_data/user_data:request_delete', {
    user_id: uuid,
    data_scopes: ['all']
  })
  return res
}

const confirmDataDeleteAll = async (uuid, xAuthToken) => {
  const res = await axService.post(
    'user_data/user_data:confirm_deletion_request',
    {
      user_id: uuid,
      data_scopes: ['all']
    },
    {
      headers: {
        'X-Auth-Token': xAuthToken
      }
    }
  )
  return res
}

const requestSurveyDataDelete = async (user_id) => {
  return await axService.post('user_data/user_data:request_delete', {
    user_id,
    data_scopes: ['kyc_survey_results']
  })
}

const confirmSurveyDataDelete = async (uuid, xAuthToken) => {
  const res = await axService.post(
    'user_data/user_data:confirm_deletion_request',
    {
      user_id: uuid,
      data_scopes: ['kyc_survey_results']
    },
    {
      headers: {
        'X-Auth-Token': xAuthToken
      }
    }
  )
  return res
}

const patchUser = async (updatedUserDetails) => {
  const res = await ax.get('users', {})
  const id = res.data.results[0].id
  return await ax.patch(`users/${id}/`, updatedUserDetails)
}

export {
  requestDataExport,
  requestDataDeleteAll,
  confirmDataDeleteAll,
  requestSurveyDataDelete,
  confirmSurveyDataDelete,
  patchUser
}
