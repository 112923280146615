import axios from 'axios'
import { getEnvVar } from '@/env'
import { reportError } from '@/src/utils/frontendLogger'

interface RequestResponseBase<T> {
  code: number
  message: string
  data: T
}

// Possible error response
// interface ErrorResponse {
//   type: string
//   message: string
//   details: {
//     issue: string
//     solution: string
//     content: Array<any>
//   }
//   log_url: string
//   doc_url: string
// }

const authInstance = axios.create({
  baseURL: `${getEnvVar('NEXT_PUBLIC_AUTH_BASEURL')}/authenticationSys`,
  headers: {
    'Content-Type': 'application/json',
    'X-API-KEY': getEnvVar('NEXT_PUBLIC_AUTH_API_KEY')
  }
})

authInstance.interceptors.response.use(
  (res) => res,
  function (error) {
    // if request is forbidden, remove tokens and lead user to signin page
    if (error.response.status === 403) {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('refreshToken')
      window.location.href = '/SignIn'
    }
    return Promise.reject(error.response)
  }
)

interface UserResponseData {
  exists: boolean
  verified: boolean
  firebase_id: string[]
  id: string
  region: string
  created_at: string
  username: string
  email: string
  belonging_site: string
  access: {
    manageGroupMembership: boolean
    view: boolean
    mapRoles: boolean
    impersonate: boolean
    manage: boolean
  }
}

type GetUsersRequestResponse = RequestResponseBase<UserResponseData[]>
interface GetUsersParams {
  email?: string
  guest?: boolean //when set to true, combining with email indicating guestEmail
  emailVerified?: boolean
  enabled?: boolean
  exact?: boolean
  first?: number
  firstName?: string
  lastName?: string
  max?: number // Maximum results size (defaults to 100)
  // A query to search for custom attributes, format: 'key1:value2 key2:value2'
  q?: string
  search?: string
  username?: string
}
/**
 * GET /authenticationSys/users
 * get users by parameters
 */
const getUsers = async (
  params: GetUsersParams
): Promise<GetUsersRequestResponse> => {
  const res = await authInstance.get<GetUsersRequestResponse>('/users', {
    params
  })

  return res.data
}

type GetUserRequestResponse = RequestResponseBase<UserResponseData>
/**
 * GET /authenticationSys/users
 * get user by id
 */
const getUserById = async (userId: string): Promise<GetUserRequestResponse> => {
  const res = await authInstance.get<GetUserRequestResponse>(`/users/${userId}`)
  return res.data
}

function getKycUserId(email: string, isLoggedIn: boolean): Promise<string>
function getKycUserId(email: string): Promise<string>
async function getKycUserId(
  email: string,
  isLoggedIn?: boolean
): Promise<string> {
  let userId: string
  if (isLoggedIn || isLoggedIn === undefined) {
    const userRes = await getUsers({ email, exact: true })
    const userResId = userRes.data[0]?.id
    if (userResId) {
      userId = userResId
      return userId
    }
  }

  const guestUserRes = await getUsers({ guest: true, email, exact: true })
  const guestUserResId = guestUserRes.data[0]?.id
  if (guestUserResId) {
    userId = guestUserResId
    return userId
  }

  const createUserRes = await postCreateUser({
    username: email,
    guest: true
  })
  userId = createUserRes.data.user_id

  return userId
}

/**
 * POST /authenticationSys/users
 * create a new user
 */
const postCreateUser = async ({
  username,
  guest,
  region = 'US'
}: {
  username: string
  guest?: boolean
  region?: string
}): Promise<RequestResponseBase<{ user_id: string }>> => {
  const res = await authInstance.post<RequestResponseBase<{ user_id: string }>>(
    '/users',
    {
      username,
      guest,
      region
    }
  )
  return res.data
}

type VerifyUserRequestResponse = RequestResponseBase<{
  user_id: string
  temp_code: string
}>
/**
 * POST /authenticationSys/users:verify
 * verify a new user
 */
const postVerifyUser = async (body: {
  username: string
  code: string
}): Promise<VerifyUserRequestResponse> => {
  try {
    const res = await authInstance.post<VerifyUserRequestResponse>(
      '/users:verify',
      body
    )
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

type UpdatePasswordRequestResponse = RequestResponseBase<{ user_id: string }>
/**
 * POST /authenticationSys/users/password:set
 * set password
 * pass the code for authorization check,
 * if no code is given, fall back to token authorization
 */
const postUserUpdatePassword = async (body: {
  code?: string
  username: string
  password: string
}): Promise<boolean> => {
  if (body?.code) {
    try {
      const res = await authInstance.post<UpdatePasswordRequestResponse>(
        '/users/password:set',
        body
      )
      return res?.status === 200
    } catch (error) {
      return false
    }
  } else {
    const token = window.localStorage.getItem('token')
    if (!token) return false
    try {
      const res = await authInstance.post<UpdatePasswordRequestResponse>(
        '/users/password:set',
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // api key and token should not be passed at the same time,
            // as the former api-key has higher priority than the later
            'X-API-KEY': ''
          }
        }
      )
      return res?.status === 200
    } catch (error) {
      return false
    }
  }
}

type UserLoginRequestResponse = RequestResponseBase<{
  access_token: string
  token_type: string
  expires_in: number
  refresh_expires_in: number
  id_token: string
  refresh_token: string
  not_before_policy: number
  session_state: string
  scope: string
}>
/**
 * /authenticationSys/users:login
 * 'legacy' must be true in the current version,required by BE
 */
const postUserLogin = async (
  username: string,
  password: string
): Promise<UserLoginRequestResponse> => {
  const res = await authInstance.post<UserLoginRequestResponse>(
    '/users:login',
    { username, password },
    // important, lead to different result data structure
    { params: { legacy: true } }
  )
  return res?.data
}
const userReAuth = async (
  reAuthEmail: string,
  reAuthPassword: string
): Promise<'success' | 'fail'> => {
  try {
    await authInstance.post<UserLoginRequestResponse>(
      '/users:login',
      { username: reAuthEmail, password: reAuthPassword },
      { params: { legacy: true } }
    )
    return 'success'
  } catch {
    return 'fail'
  }
}

/**
 * /authenticationSys/users/verification_email:send
 * @return whether email is sent successfully
 */
const sendVerifyEmail = async (email: string): Promise<boolean> => {
  const res = await authInstance
    .post('/users/verification_email:send', null, {
      params: {
        email
      }
    })
    .catch((err) => {
      reportError(err, `Failed on sending verification email to ${email}`)
      console.error(err)
      return false
    })
  return res['status'] === 204
}

/**
 * /authenticationSys/users/reset_password_email:send
 * @return whether email is sent successfully
 */
const sendResetPasswordEmail = async (email: string): Promise<boolean> => {
  const res = await authInstance.post(
    '/users/reset_password_email:send',
    null,
    {
      params: {
        email
      }
    }
  )
  return res['status'] === 204
}

interface TokenData {
  access_token: string
  expires_in: number
  refresh_expires_in: number
  refresh_token: string
  token_type: string
  not_before_policy: number
  session_state: string
  scope: string
  id_token: string
}
/**
 * /authenticationSys/token:refresh
 * refresh token
 */
type RefreshTokenResponse = RequestResponseBase<TokenData>
const postRefreshToken = async (refreshToken: string): Promise<TokenData> => {
  const res = await authInstance.post<RefreshTokenResponse>('/token:refresh', {
    refresh_token: refreshToken
  })
  return res.data.data
}

interface UserDecryptionData {
  email: string
  is_expired: boolean
}
type UserDecryptionResponse = RequestResponseBase<UserDecryptionData>
/**
 * /authenticationSys/user-decryption
 * user decryption
 */
const decryptUser = async (aesCode: string): Promise<UserDecryptionData> => {
  const res = await authInstance.post<UserDecryptionResponse>(
    '/user-decryption',
    {
      aes_code: aesCode
    }
  )
  return res.data.data
}

interface UserEncryptionResponse {
  code: number
  message: string
  aes_code: string
}
/**
 * /authenticationSys/user-encryption
 * user encryption
 */
const encryptUser = async (
  email: string,
  expirationTime: number
): Promise<UserEncryptionResponse> => {
  const res = await authInstance.post<UserEncryptionResponse>(
    '/user-decryption',
    {
      email,
      expiration_time: expirationTime
    }
  )
  return res.data
}

export {
  getUsers,
  getUserById,
  getKycUserId,
  postCreateUser,
  postUserUpdatePassword,
  postUserLogin,
  postVerifyUser,
  userReAuth,
  sendVerifyEmail,
  sendResetPasswordEmail,
  postRefreshToken,
  decryptUser,
  encryptUser
}
