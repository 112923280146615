// -----------
// --  错误码存储，错误消息前端展示。错误码主要来自后台接口
// -----------

/**
 * 预约冲突，常见于多人同时预约同1个时间段，且当前时间段仅有1个客服
 */
const Schedule_Already_Taken = {
  code: 'ScheduleAlreadyTaken',
  message:
    'The current time has already been reserved by others, please choose another time.'
}

/**
 * 预约取消失败，一般是当前预约状态不是scheduled，例如过期、已取消、已完成等
 */
const Schedule_Invalid_Appointment_Status = {
  code: 'InvalidAppointmentStatus',
  message: 'The current time has expired, please reschedule.'
}

/**
 * 当前预约不存在，一般是预约id错误
 */
const Schedule_Not_Found = {
  code: 'AppointmentNotFound',
  message: 'The current appointment does not exist, please reschedule.'
}

export {
  Schedule_Already_Taken,
  Schedule_Invalid_Appointment_Status,
  Schedule_Not_Found
}
