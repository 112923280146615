import { createSlice, configureStore } from '@reduxjs/toolkit'
import promotionReducer from '../components/Promotion/promotionSlice'
import leaveQuestionReducer from '../components/LeaveQuestions/LeaveQuestionsSlices'
import headerReducer from '../components/HeaderV2/headerSlice'

import state from './state'
import actions from './action'

import schedule from '@/src/components/Schedule2/redux/selector'
import product from '@/src/components/Product/OrkaTwo/redux/selector'

export const SLICE_NAME = 'base'

const OrkaTwoSlice = createSlice({
  name: SLICE_NAME,
  initialState: state,
  reducers: actions
})

const store = configureStore({
  reducer: {
    [SLICE_NAME]: OrkaTwoSlice.reducer,
    promotion: promotionReducer,
    leaveQuestions: leaveQuestionReducer,
    header: headerReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  // middleware: [thunkMiddleware],
  enhancers: []
})

const command = OrkaTwoSlice.actions
const selector = {
  selectUserInfo: (state) => state[SLICE_NAME]?.userInfo,
  schedule,
  product
}

export default store
export { command, selector }

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
