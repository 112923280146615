import axios from 'axios'

async function sendLog(error_type, react_info, message, attachment) {
  try {
    attachment = JSON.stringify(attachment)
  } catch {
    attachment = attachment.toString()
  }
  try {
    message = JSON.stringify(message)
  } catch {
    message = message.toString()
  }
  try {
    react_info = JSON.stringify(react_info)
  } catch (e) {
    react_info = react_info.toString()
  }
  axios.post(process.env.NEXT_PUBLIC_BASEURL + 'frontend_logger/', {
    error_type,
    url: typeof window !== 'undefined' ? window.location.href : 'SERVER_SIDE',
    react_info,
    message,
    attachment
  })
}

const reportReactError = (error, error_type) => {
  sendLog('react', JSON.stringify({ error: error.toString(), error_type }))
}

const report404Error = () => {
  sendLog('404')
}

const report500Error = () => {
  sendLog('500')
}
const sendErrorAndEmail = (message, email, product) => {
  try {
    message = JSON.stringify(message)
  } catch {
    message = message.toString()
  }
  axios.post(process.env.NEXT_PUBLIC_BASEURL + 'frontend_logger/', {
    error_type: 'manual',
    url: 'checkout',
    message: message,
    react_info: email,
    product_name: product
  })
}
const reportError = (react_info, message) => {
  sendLog('manual', react_info, message)
}

const reportServiceWarning = (error, message, email, product) => {
  try {
    message = JSON.stringify(message)
  } catch {
    message = message.toString()
  }
  try {
    error = JSON.stringify(error)
  } catch (e) {
    error = error.toString()
  }
  axios.post(process.env.NEXT_PUBLIC_BASEURL + 'frontend_logger/', {
    error_type: 'service',
    url: typeof window !== 'undefined' ? window.location.href : 'SERVER_SIDE',
    email: email,
    product: product,
    react_info: error,
    message
  })
}

export {
  sendLog,
  reportReactError,
  report404Error,
  report500Error,
  reportError,
  sendErrorAndEmail,
  reportServiceWarning
}
