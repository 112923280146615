import { ax, axHubspotWithoutInterceptor } from '../../AxiosFactory'

const patchLike = async (info) => {
  const res = await ax.patch('forum/update-like/', info)
  return res
}

const postStory = async (details) => {
  return await ax.post('forum/forum-user-info/', details)
}

const getAllForum = async () => {
  return await ax.get('forum/')
}

const submitUserQuestions = async (info) => {
  const res = await axHubspotWithoutInterceptor.post(
    'hubspot_sys/ticket/submission',
    info
  )
  return res
}

export { patchLike, postStory, getAllForum, submitUserQuestions }
