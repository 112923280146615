import { createSlice } from '@reduxjs/toolkit'

export interface LeaveQuestionsStoredValues {
  modalClosed?: boolean
}

export const leaveQuestionsSlice = createSlice({
  name: 'leaveQuestions',
  initialState: {
    modalClosed: true
  },

  reducers: {
    showLeaveQuestionsModal: (state) => {
      state.modalClosed = false
    },
    closeLeaveQuestionsModal: (state) => {
      state.modalClosed = true
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  showLeaveQuestionsModal,
  closeLeaveQuestionsModal,
} = leaveQuestionsSlice.actions

export default leaveQuestionsSlice.reducer
