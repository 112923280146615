import {
  getAllTimeSlots,
  postSchedule,
  putSchedule,
  cancelSchedule,
  reSchedule,
  selectSchedule,
  getUpcomingAppointmentsById,
  getKycUserId
} from '@/src/providers/RemotePoint'
import { SLICE_NAME, SCHEDULE_VIEWS } from './state'
import store, { command, selector } from '@/src/redux/store'
import {
  Schedule_Already_Taken,
  Schedule_Invalid_Appointment_Status,
  Schedule_Not_Found
} from '@/src/code/Error'

const actionSetCurrentSchedultView = (state, value) => {
  state[SLICE_NAME].currentSchedule = value?.payload
}

const actionSetChooseTimeSlot = (state, value) => {
  // 目前仅可以选1个时间段，如果以后运行选多个时间段，将下列代码替换为push即可
  state[SLICE_NAME].chooseTimeSlot = value?.payload && [value?.payload]
}

const actionSetChooseTimeZone = (state, value) => {
  state[SLICE_NAME].chooseTimeZone = value?.payload
}

const actionSetAllTimeSlots = (state, value) => {
  state[SLICE_NAME].allTimeSlots = value?.payload
}

const actionSetScheduleId = (state, value) => {
  state[SLICE_NAME].scheduleId = value?.payload
}

const actionSetAgent = (state, value) => {
  state[SLICE_NAME].agent = value?.payload
}

const actionSetScheduleInfo = (state, value) => {
  state[SLICE_NAME].scheduleInfo = value?.payload
}

const actionSetMessage = (state, value) => {
  state[SLICE_NAME].message = value?.payload
}

const actionSetCloseMask = (state, value) => {
  state[SLICE_NAME].closeMask = value?.payload
}

const actionSetScheduleSuccessMessage = (state, value) => {
  state[SLICE_NAME].scheduleSuccessMessage = value?.payload
}

const actionSetScheduleCancelled = (state, value) => {
  state[SLICE_NAME].scheduleCancelled = value?.payload
}

function getTimePeriods() {
  // Get the current date
  const currentDate = new Date()

  // Calculate the last day of the current month
  const lastDayOfMonth = new Date()
  lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1)

  // 只能预约24小时以后的时间
  currentDate.setDate(currentDate.getDate() + 1)
  // Return the time periods as an object
  return {
    start: currentDate,
    end: lastDayOfMonth
  }
}

const actionGetAllTimeSlots = (state, value) => {
  const payload = { value }
  let currentMonthStartEnd = payload
  if (!payload || !payload.length) {
    currentMonthStartEnd = getTimePeriods()
    currentMonthStartEnd.start = currentMonthStartEnd.start
      .toISOString()
      .replace(/\.\d{3}/, '')
    currentMonthStartEnd.end = currentMonthStartEnd.end
      .toISOString()
      .replace(/\.\d{3}/, '')
    currentMonthStartEnd.type = 'fitting'
  }
  asyncGetAllTimeSlots(currentMonthStartEnd)
}

const asyncGetAllTimeSlots = async (value) => {
  return getAllTimeSlots(value).then((res) => {
    if (res?.data) {
      store.dispatch(command.actionSetAllTimeSlots(res.data))
      return res.data
    }
  })
}

const actionPostSchedule = (state, { payload }) => {
  asyncPostSchedule(payload)
}

const asyncPostSchedule = async (value) => {
  // 如果 attendee_id 为 "00000000-0000-0000-0000-000000000000"，则表示用户未登录
  if (value.attendee_id === '00000000-0000-0000-0000-000000000000') {
    // 使用提供的电子邮件地址获取或创建客户用户 ID
    const email = value.attendee_info.email
    const userId = await getKycUserId(email)
    value.attendee_id = userId // 更新 attendee_id
  }
  const upcomingAppointments = await getUpcomingAppointmentsById(
    value.attendee_id
  )

  // 取消所有未进行的日程安排(在此前的情况下可能存在多个未来的appointment出现)
  const cancelPromises = upcomingAppointments.map((appointment) =>
    asyncCancelSchedule(appointment.id)
  )
  await Promise.all(cancelPromises)

  // 发布新的日程安排
  return postSchedule(value)
    .then((res) => {
      if (res?.data?.id) {
        store.dispatch(command.actionSetScheduleId(res.data.id))
        store.dispatch(command.actionSetCurrentSchedultView(SCHEDULE_VIEWS[1]))
        store.dispatch(command.actionSetAgent(res.data?.host?.first_name))
      } else {
        store.dispatch(
          command.actionSetMessage(
            res.data?.message || 'Appointment failed, please try again'
          )
        )
        setTimeout(() => {
          store.dispatch(command.actionSetCloseMask(true))
        }, 2000)
      }
    })
    .catch((err) => {
      const errCode = err?.response?.data?.details?.issue
      let msg = 'Appointment failed, please try again !'
      if (errCode === Schedule_Already_Taken.code) {
        msg = Schedule_Already_Taken.message
      }
      store.dispatch(command.actionSetMessage(msg))
      setTimeout(() => {
        store.dispatch(command.actionSetCloseMask(true))
      }, 2000)
    })
}

const actionReSchedule = (state, { payload }) => {
  asyncReSchedule(payload.id, payload.value)
}

const asyncReSchedule = async (id, value) => {
  return reSchedule(id, value).then((res) => {
    if (res?.data) {
      store.dispatch(command.actionSetScheduleId(''))
      store.dispatch(command.actionSetCurrentSchedultView(SCHEDULE_VIEWS[1]))
    }
  })
}

const actionCancelSchedule = (state, { payload }) => {
  const { scheduleId, callback } = payload
  asyncCancelSchedule(scheduleId).then((value) => {
    value && callback && callback()
  })
}

const asyncCancelSchedule = async (value) => {
  return cancelSchedule(value)
    .then((res) => {
      if (!res?.data) {
        console.error('cancelSchedule error', res)
        return false
      }
      return true
    })
    .catch((err) => {
      const errCode = err?.response?.data?.details?.issue
      console.log(errCode)
      let msg = 'Appointment failed, please try again !'
      if (errCode === Schedule_Invalid_Appointment_Status.code) {
        msg = Schedule_Invalid_Appointment_Status.message
      }
      store.dispatch(command.actionSetScheduleSuccessMessage(msg))
    })
}

const actionSelectSchedule = (state, { payload }) => {
  asyncSelectSchedule(payload)
}

const asyncSelectSchedule = async (value) => {
  return selectSchedule(value)
    .then((res) => {
      if (res?.data) {
        const currentSchedule = res.data
        if (currentSchedule.status === 'cancelled') {
          store.dispatch(command.actionSetScheduleCancelled(true))
          return
        }
        store.dispatch(command.actionSetScheduleId(currentSchedule.id))
        store.dispatch(
          command.actionSetChooseTimeZone(currentSchedule.timezone)
        )
        store.dispatch(
          command.actionSetChooseTimeSlot({
            start: +new Date(currentSchedule.timeslot.start_at),
            end: +new Date(currentSchedule.timeslot.end_at),
            attendee: currentSchedule.attendee.id
          })
        )
        store.dispatch(
          command.actionSetScheduleInfo({
            name: currentSchedule.attendee.extra_info.name,
            phone: currentSchedule.attendee.extra_info.phone,
            email: currentSchedule.attendee.extra_info.email
          })
        )
        store.dispatch(command.actionSetCurrentSchedultView(SCHEDULE_VIEWS[1]))
      } else {
        console.error('Miss the schedule info', res)
        return
      }
    })
    .catch((err) => {
      store.dispatch(command.actionSetCurrentSchedultView(SCHEDULE_VIEWS[1]))
      const errCode = err?.response?.data?.details?.issue
      let msg = 'Appointment failed, please try again !'
      if (errCode === Schedule_Not_Found.code) {
        msg = Schedule_Not_Found.message
      }
      store.dispatch(command.actionSetScheduleSuccessMessage(msg))
    })
}

const actionPutSchedule = (state, { payload }) => {
  asyncPutSchedule(payload.id, payload.value)
}

const asyncPutSchedule = async (id, value) => {
  return putSchedule(id, value)
    .then((res) => {
      if (!res.data) {
        console.error('Modify userinfo error', res)
      }
    })
    .catch((err) => {
      store.dispatch(
        command.actionSetScheduleSuccessMessage(
          err?.response?.data?.details?.solution ||
            'Update failed, please try again'
        )
      )
    })
}

export default {
  actionSetCurrentSchedultView,
  actionSetChooseTimeSlot,
  actionSetChooseTimeZone,
  actionSetAllTimeSlots,
  actionGetAllTimeSlots,
  actionSetScheduleId,
  actionPostSchedule,
  actionPutSchedule,
  actionReSchedule,
  actionCancelSchedule,
  actionSelectSchedule,
  actionSetScheduleInfo,
  actionSetMessage,
  actionSetCloseMask,
  actionSetScheduleSuccessMessage,
  actionSetScheduleCancelled,
  actionSetAgent
}
