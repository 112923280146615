import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface PromotionStoredValues {
  localStorageKey?: string
  bannerClosed?: boolean
  modalClosed?: boolean
  exitIntentCounter?: number
}

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState: {
    localStorageKey: 'promotion.normal',
    bannerClosed: true,
    modalClosed: true,
    exitIntentCounter: 0
  },

  reducers: {
    showPromotionModal: (state) => {
      state.modalClosed = false
    },
    closePromotionModal: (state) => {
      state.modalClosed = true
    },
    showPromotionBanner: (state) => {
      state.bannerClosed = false
    },
    closePromotionBanner: (state) => {
      state.bannerClosed = true
    },
    updateExitIntentCounter: (state, action: PayloadAction<number>) => {
      state.exitIntentCounter = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  showPromotionModal,
  closePromotionModal,
  showPromotionBanner,
  closePromotionBanner,
  updateExitIntentCounter
} = promotionSlice.actions

export default promotionSlice.reducer
