const getLocalStorageItem = <T>(key: string): T => {
  try {
    const storedValue = window.localStorage.getItem(key)
    return storedValue ? JSON.parse(storedValue) : null
  } catch {
    console.warn(`Failed to parse localStorage item ${key}`)
    return null
  }
}

const setLocalStorageItem = <T>(key: string, value: T) => {
  const storedValue = getLocalStorageItem(key)
  const newValue =
    typeof storedValue === 'object' &&
    !Array.isArray(storedValue) &&
    !!storedValue
      ? {
          ...storedValue,
          ...value
        }
      : value
  window.localStorage.setItem(key, JSON.stringify(newValue))
}

export { getLocalStorageItem, setLocalStorageItem }
