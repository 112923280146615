import { ax } from '../../AxiosFactory'

const verifyFeedbackSurveyCode = async (codeInfo) => {
  try {
    const res = await ax.post('checkout/coupon-code/verify/', codeInfo)
    return res
  } catch (e) {
    return e
  }
}

const setupIntent = async (obj) => {
  const res = await ax.post('checkout/setup-intent/', obj)
  return res
}

const visitedCheckout = async () => {
  await ax.post('checkout/visited-checkout/')
}

const placeOrder = async (obj) => {
  const res = await ax.post('checkout/order/', obj)
  return res
}

export { verifyFeedbackSurveyCode, setupIntent, visitedCheckout, placeOrder }
