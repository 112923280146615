import { ax, axMulti } from '../../AxiosFactory'
import { getOrderDetails } from '../../RemotePoint'

const getRefundSupportTime = async (id) => {
  const res = await ax.get(`refund/${id}/support-time`)
  return res
}

// form headers
const patchOrderRefund = async (refundInfo, id) => {
  const res = await axMulti.patch(`refund/${id}/`, refundInfo)
  await getOrderDetails()
  return res
}

export { getRefundSupportTime, patchOrderRefund }
