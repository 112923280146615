import schedule from '@/src/components/Schedule2/redux/state'
import product from '@/src/components/Product/OrkaTwo/redux/state'

const IS_LOGIN = 'isLogin'
const EMAIL = 'email'
const NAME = 'name'
const PHONE = 'phone'
const UUID = 'uuid'
const USER_DETAIL = 'userDetail'
const AUDIOGRAM = 'audiogram'
const LOADING = 'loading'
const ORDER_DETAIL = 'orderDetail'
const TOKEN = 'token'
const APPOINTMENT = 'appointment'

const OrkaTwoStore = {
  userInfo: {
    [IS_LOGIN]: undefined,
    [EMAIL]: '',
    [NAME]: '',
    [PHONE]: '',
    [UUID]: '',
    [USER_DETAIL]: null,
    [AUDIOGRAM]: null,
    [LOADING]: false,
    [ORDER_DETAIL]: null,
    [TOKEN]: '',
    [APPOINTMENT]: null
  },
  schedule,
  product
}

export default OrkaTwoStore

export {
  IS_LOGIN,
  EMAIL,
  NAME,
  PHONE,
  UUID,
  USER_DETAIL,
  AUDIOGRAM,
  LOADING,
  ORDER_DETAIL,
  TOKEN,
  APPOINTMENT
}
