import schedule from '@/src/components/Schedule2/redux/action'
import product from '@/src/components/Product/OrkaTwo/redux/action'

const actionSetUserInfo = (state, data) => {
    const {payload} = data
    Object.keys(payload).forEach(key => {
        if (key) {
            state.userInfo[key] = payload[key]
        } else {
            console.error('key is not defined', key)
        }  
    })
}



export default {
    actionSetUserInfo,
    ...schedule,
    ...product,
}