import crypto from 'crypto'

const hashMD5 = (input: string) => {
  if (typeof crypto !== 'undefined') {
    return crypto.createHash('md5').update(input).digest('hex')
  } else {
    return ''
  }
}

const hashSHA256 = (input: string) => {
  if (typeof crypto !== 'undefined') {
    return crypto.createHash('sha256').update(input).digest('hex')
  } else {
    return ''
  }
}

export { hashMD5, hashSHA256 }
