import {
  APPOINTMENT,
  AUDIOGRAM,
  EMAIL,
  IS_LOGIN,
  LOADING,
  NAME,
  ORDER_DETAIL,
  PHONE,
  TOKEN,
  USER_DETAIL,
  UUID
} from '@/src/redux/state'
import { ax, axMulti, axSchedule, postUTMHistory } from './AxiosFactory'
import {
  cancelAppointment,
  cancelSchedule,
  createAppointment,
  getAllTimeSlots,
  getAppointment,
  getAppointmentById,
  getAvailableTimeslots,
  getUpcomingAppointmentsById,
  postSchedule,
  putSchedule,
  reSchedule,
  rescheduleAppointment,
  selectSchedule
} from './services/schedule/index'
import {
  checkProductAvailable as checkProductAvailableFun,
  getCheckoutUrl as getCheckoutUrlFun,
  getCheckoutUrlWithOutAccount as getCheckoutUrlWithOutAccountFun,
  getLatestRefund,
  getOneDetails,
  getTwoDetails,
  orderLookup,
  postOrderRefunded,
  sendRefundEmail,
  updateOrderStatus
} from './services/order/index'
import {
  checkoutEmail as checkoutEmailFun,
  deleteHearingTestResult as deleteHearingTestResultFun,
  emailSubscribe as emailSubscribeFun,
  getAllKYCResults,
  getHearingTestResult,
  insiderSubscribe as insiderSubscribeFun,
  kycQuestionnaireSubmit,
  messageBoardSubscribe,
  patchHearingTestResult as patchHearingTestResultFun,
  patchKYCResult,
  saveHearingTestSurvey,
  sendFeedBackInfo
} from './services/mkt/index'
import {
  confirmDataDeleteAll,
  confirmSurveyDataDelete,
  patchUser as patchUserFun,
  requestDataDeleteAll,
  requestDataExport,
  requestSurveyDataDelete as requestSurveyDataDeleteFun
} from './services/user/index'
import {
  deleteAudiogram as deleteAudiogramFun,
  getAudiogram,
  uploadAudiogram
} from './services/healthData/index'
import {
  getAllForum,
  patchLike,
  postStory,
  submitUserQuestions
} from './services/forum/index'
import {
  getKycUserId,
  getUsers,
  postCreateUser,
  postRefreshToken,
  postUserLogin
} from './auth'
import {
  getPrefittingStatus,
  postCampaignCandidateInfo,
  postFaqFeedback,
  postFaqQuestion,
  sendPrefittingInfo
} from './services/survey'
import { getPromotionCode, verifyPromotionCode } from './services/program/index'
import { getRefundSupportTime, patchOrderRefund } from './services/refund/index'
import { getSchedule, setCalendar } from './services/agent/index'
import {
  getTroubleDetails,
  updateTroubleShootingComment
} from './services/troubleshooting/index'
import {
  placeOrder,
  setupIntent,
  verifyFeedbackSurveyCode,
  visitedCheckout
} from './services/checkout/index'
import store, { SLICE_NAME, command } from '../redux/store'
import {
  submitBlackFridayUserInfo,
  submitCampaignFormAndGetCoupon,
  submitChristmasUserInfo,
  submitCyberMondayUserInfo,
  submitExitIntentForm,
  submitHearingScreening,
  submitOutOfStockSubscriptionContactInfo,
  submitPresidentDayUserInfo,
  submitUserInfo
} from './services/campaign/index'

import { getEnvVar } from '@/env'
import { getLocalStorageItem } from '@/src/utils/localStorage'
import { getTwoShipment } from './services/fulfillment/index'
import jwtDecode from 'jwt-decode'
import orderCombination from '/utils/orderCombination'
import { patchBlogLike } from './services/blog/index'
import { reportError } from '@/src/utils/frontendLogger'
import { setTradeinAnswer } from './services/promotion/index'
import { submitReview } from './services/review/index'

export const setOrderDetails = (value) =>
  store.dispatch(command.actionSetUserInfo({ [ORDER_DETAIL]: value }))

export const setLoading = (value) =>
  store.dispatch(command.actionSetUserInfo({ [LOADING]: value }))

export const setToken = (value) =>
  store.dispatch(command.actionSetUserInfo({ [TOKEN]: value }))

export const setAudiograms = (value) =>
  store.dispatch(command.actionSetUserInfo({ [AUDIOGRAM]: value }))

export const setUserDetails = (value) =>
  store.dispatch(command.actionSetUserInfo({ [USER_DETAIL]: value }))

export const setAppointment = (value) =>
  store.dispatch(command.actionSetUserInfo({ [APPOINTMENT]: value }))

const isTestEnv = getEnvVar('NEXT_PUBLIC_BASEURL').includes('test')
const isSignIn = () => !!store.getState()[SLICE_NAME].userInfo[TOKEN]

const getOrderDetails = async () => {
  const [orderDetails1, orderDetails2, shipmentDetails] = await Promise.all([
    getOneDetails(),
    getTwoDetails(),
    getTwoShipment()
  ])
  const resDetail = orderCombination(
    orderDetails1,
    orderDetails2,
    shipmentDetails
  )
  setOrderDetails(resDetail)
  return resDetail
}

const getAllOrderDetails = async () => {
  const details = await getOrderDetails()
  setOrderDetails(details)
  return details
}

const asyncUserLogIn = async (username, password) => {
  try {
    const res = await postUserLogin(username, password)
    if (res?.data) {
      heap.identify(username + (isTestEnv ? ' (test)' : ''))
      heap.addUserProperties({ email: username })
      if (window) window.localStorage.setItem('userID', username)

      const { access_token, refresh_token } = res.data
      window.localStorage.setItem('token', access_token)
      window.localStorage.setItem('refreshToken', refresh_token)
      const uuid = jwtDecode(access_token)['sub']
      window.localStorage.setItem('uuid', uuid)
      store.dispatch(
        command.actionSetUserInfo({
          [IS_LOGIN]: true,
          [EMAIL]: username,
          [TOKEN]: access_token,
          [UUID]: uuid
        })
      )
      return res?.data
    } else {
      throw Error('Failed to login')
    }
  } catch (error) {}
}

const asyncUserSignupFirst = async (username) => {
  await postCreateUser({ username })
  heap.identify(username + (isTestEnv ? ' (test)' : ''))
  heap.addUserProperties({ email: username })
  if (window) window.localStorage.setItem('userID', username)
}

// return boolean
const asyncUserEmailTakenStatus = async (email) => {
  const res = await getUsers({ email, exact: true }).then((data) => {
    const user = data?.data[0]
    if (user) {
      localStorage.setItem('uuid', user.id)
      return user.exists
    }
    return false
  })
  return res
}

// return UserData
const asyncUserEmailTakenStatusAll = async (email) => {
  const res = await getUsers({ email, exact: true })
  return res?.data?.[0]
}

// return boolean
const asyncUserEmailVerifiedStatus = async (email) => {
  const res = await getUsers({ email, exact: true, emailVerified: true })
  return res?.data?.length > 0
}

const asyncUserIsFirebaseUser = async (email) => {
  const res = await getUsers({ email, exact: true })
  return res?.data?.[0].firebase_id.length > 0
}

const verifyToken = async () => {
  if (
    window.localStorage.getItem('token') &&
    window.localStorage.getItem('refreshToken')
  ) {
    const decodeAccess = jwtDecode(window.localStorage.getItem('token'))
    const decodeRefresh = jwtDecode(window.localStorage.getItem('refreshToken'))

    const expireAccessTime = decodeAccess.exp
    const expireRefreshTime = decodeRefresh.exp
    const currTime = Date.now() / 1000

    if (expireRefreshTime < currTime) {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('refreshToken')

      window.location.href = '/SignIn'
    } else if (expireAccessTime < currTime) {
      const res = await postRefreshToken(
        window.localStorage.getItem('refreshToken')
      )

      if (res) {
        window.localStorage.setItem('token', res.access_token)
        window.localStorage.setItem('refreshToken', res.refresh_token)
        setToken(res.access_token)
      } else {
        window.location.href =
          '/SignIn' +
          `?redirect_uri=${encodeURIComponent(
            location.origin + location.pathname + location.search
          )}`
      }
    }
  }
  return window.localStorage.getItem('token')
}

const fetchUserAudiogram = async () => {
  try {
    const userId = getUserId()
    if (!userId) {
      console.error('User ID is not available.')
      return
    }
    const res = await getAudiogram(userId, true)
    if (res?.data?.data) {
      const sortedAudiograms = res.data.data.sort(
        (a, b) => new Date(b.upload_time) - new Date(a.upload_time)
      )
      setAudiograms(sortedAudiograms)
    } else {
      console.error('No audiograms received or invalid data.')
    }
  } catch (error) {
    console.error('Error in fetchUserAudiogram:', error)
  }
}

const userSignOut = () => {
  if (isSignIn()) {
    setLoading(true)
    try {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('refreshToken')
      window.localStorage.removeItem('userID')
      window.localStorage.removeItem('uuid')
      store.dispatch(
        command.actionSetUserInfo({
          [IS_LOGIN]: false,
          [EMAIL]: '',
          [NAME]: '',
          [PHONE]: '',
          [UUID]: ''
        })
      )
      setLoading(false)
      setToken(undefined)
      location.href = '/'
      return { result: 'success' }
    } catch (e) {
      setLoading(false)
      return e
    }
  }
}

const patchUser = async (updatedUserDetails) => {
  setLoading(true)
  if (isSignIn()) {
    try {
      const result = await patchUserFun(updatedUserDetails)
      setUserDetails(result.data)
    } catch (err) {
      console.error(err)
    }
  }
  setLoading(false)
}

const deleteHearingTestResult = async () => {
  if (isSignIn()) {
    return await deleteHearingTestResultFun()
  }
}

const patchHearingTestResult = async (results) => {
  if (isSignIn()) {
    return await patchHearingTestResultFun(results)
  }
}

const getCheckoutUrl = async (lineItems) => {
  if (isSignIn()) {
    try {
      setLoading(true)
      const res = await getCheckoutUrlFun(lineItems)
      setLoading(false)
      return res
    } catch (err) {}
  }
}

const getCheckoutUrlWithOutAccount = async (lineItems) => {
  const res = await getCheckoutUrlWithOutAccountFun(lineItems)
  return res
}

const emailSubscribe = async (info) => {
  if (window.sessionStorage.getItem('source')) {
    const queryString = window.sessionStorage.getItem('source')
    const queryObject = new URLSearchParams(queryString)
    const source = {
      utm_source: queryObject.get('utm_source') || '',
      utm_medium: queryObject.get('utm_medium') || '',
      utm_campaign: queryObject.get('utm_campaign') || '',
      utm_content: queryObject.get('utm_content') || '',
      utm_term: queryObject.get('utm_term') || '',
      page: window.location.href.split('/').pop().split('?')[0]
    }
    info = Object.assign(source, info)
  }
  try {
    if (info.email) {
      heap.identify(info.email + (isTestEnv ? ' (test)' : ''))
      heap.addUserProperties({ email: info.email })
      if (window) window.localStorage.setItem('userID', info.email)
    }
    const res = await emailSubscribeFun(info)
    return res
  } catch (err) {}
}

const deleteAudiogram = async (audiogramId) => {
  if (!isSignIn()) return void 0
  return await deleteAudiogramFun(audiogramId)
}

const postAudiograms = async (audiogramFile) => {
  if (isSignIn()) {
    try {
      const { url } = userDetails
      const formData = new FormData()
      formData.append('audiogram_file', audiogramFile)
      formData.append('user', url)
      const res = ax.post('audiograms/', formData)
      const audiogram = store.getState()[SLICE_NAME].userInfo[AUDIOGRAM]
      setAudiograms(audiogram.concat(res.data))
      return res
    } catch (err) {
      console.error(err)
    }
  }
}

const refreshAppointment = async () => {
  try {
    const res = await getAppointment()
    setAppointment(res.data.results)
  } catch (e) {
    console.error(e)
  }
}

const patchHearingTest = async () => {
  await ax.patch('')
}

const insiderSubscribe = async (info) => {
  if (window.sessionStorage.getItem('source')) {
    const queryString = window.sessionStorage.getItem('source')
    const queryObject = new URLSearchParams(queryString)
    const source = {
      utm_source: queryObject.get('utm_source') || '',
      utm_medium: queryObject.get('utm_medium') || '',
      utm_campaign: queryObject.get('utm_campaign') || '',
      utm_content: queryObject.get('utm_content') || '',
      utm_term: queryObject.get('utm_term') || '',
      page: window.location.href.split('/').pop().split('?')[0]
    }
    info = Object.assign(source, info)
  }
  try {
    if (info.email) {
      heap.identify(info.email + (isTestEnv ? ' (test)' : ''))
      heap.addUserProperties({ email: info.email })
      if (window) window.localStorage.setItem('userID', info.email)
    }
    const res = await insiderSubscribeFun(info)
    return res
  } catch {
    return 'fail'
  }
}

const checkProductAvailable = async (data) => {
  const res = await checkProductAvailableFun(data)
  return res
}

const checkoutEmail = async (email) => {
  heap.identify(email + (isTestEnv ? ' (test)' : ''))
  heap.addUserProperties({ email: email })
  if (window) window.localStorage.setItem('userID', email)
  const res = await checkoutEmailFun(email)
  return res
}
const getTest = async () => {
  const res = await ax.get('test/mock')
  return res
}

const getUserId = () => {
  const token = localStorage.getItem('token')
  if (!token) return null
  const decodedToken = jwtDecode(token)
  return decodedToken.sub
}

const requestSurveyDataDelete = async (email) => {
  await client.init()
  let response = false
  await client
    .query('user-get_user_by_guest_email', {
      email: email
    })
    .then(async (res) => {
      if (res.exists) {
        await requestSurveyDataDeleteFun(res.id)
        response = true
      } else {
        response = false
      }
    })
  return response
}

const getAllPersonalData = (_userDetails, _orderDetails, hearingCondition) => {
  const userDetail = store.getState()[SLICE_NAME].userInfo[USER_DETAIL]
  const orderDetail = store.getState()[SLICE_NAME].userInfo[ORDER_DETAIL]
  const myuserDetails = _userDetails ? _userDetails : userDetail
  const myorderDetails = _orderDetails ? _orderDetails : orderDetail
  if (myuserDetails && myorderDetails) {
    const personalData = {
      first_name: myuserDetails.first_name,
      last_name: myuserDetails.last_name,
      birth_year: myuserDetails.birth_year,
      email: myuserDetails.username,
      gender: myuserDetails.gender,
      state: myuserDetails.state,
      hearingCondition: hearingCondition,
      shipping_addresses: myorderDetails.results?.map((order) => {
        return {
          city: order.infos?.shipping_address?.city,
          state: order.infos?.shipping_address?.state,
          country: order.infos?.shipping_address?.country,
          address1: order.infos?.shipping_address?.street_1,
          address2: order.infos?.shipping_address?.street_2,
          zip_code: order.infos?.shipping_address?.zip,
          email: order.infos?.shipping_address?.email,
          name:
            order.infos?.shipping_address?.first_name +
            ' ' +
            order.infos?.shipping_address?.last_name,
          phone_number: order.infos?.shipping_address?.phone
        }
      })
    }
    const addressStr = personalData.shipping_addresses.map((d) =>
      [
        d.phone_number,
        d.email,
        d.name,
        d.address1,
        d.address2,
        d.city,
        d.state,
        d.country,
        d.zip_code
      ]
        .filter((d) => d)
        .join(', ')
    )
    personalData.shipping_addresses = personalData.shipping_addresses.filter(
      (d, idx) =>
        addressStr.indexOf(
          [
            d.phone_number,
            d.email,
            d.name,
            d.address1,
            d.address2,
            d.city,
            d.state,
            d.country,
            d.zip_code
          ]
            .filter((d) => d)
            .join(', ')
        ) === idx
    )
    return personalData
  } else {
    return null
  }
}

// Configuration
let maxAttempts = 50 // Maximum number of checks
let intervalTime = 100 // Time in milliseconds between each check

let currentAttempt = 0
let isHeapLoaded = false // Flag to indicate if Heap is loaded
let heapActionQueue = [] // Initialize a queue for Heap actions

// Function to process the queued actions
const processHeapQueue = () => {
  heapActionQueue.forEach((action) => {
    window.heap[action.method](...action.args)
  })
  heapActionQueue = [] // Clear the queue after processing
}

// Function to add actions to the queue or execute them immediately
const enqueueOrExecuteHeapAction = (method, args) => {
  if (isHeapLoaded) {
    window.heap[method](...args)
  } else {
    heapActionQueue.push({ method, args })
    checkHeapInterval()
  }
}

// Modified heapAddUserProperties function
const heapAddUserProperties = (properties) => {
  if (isTestEnv) return
  try {
    enqueueOrExecuteHeapAction('addUserProperties', [properties])
  } catch (error) {
    console.warn(error)
    reportError(error, `Failed on syncing user property to Heap`)
  }
}

// Interval to check if Heap is loaded
const checkHeapInterval = () => {
  // Only set up the interval if it's a browser environment
  if (typeof window !== 'undefined') {
    setInterval(function () {
      if (window.heap) {
        clearInterval(checkHeapInterval)
        isHeapLoaded = true
        processHeapQueue() // Process any queued actions
      } else {
        currentAttempt++
        if (currentAttempt >= maxAttempts) {
          clearInterval(checkHeapInterval)
          console.error(
            'Heap failed to load after ' + maxAttempts + ' attempts.'
          )
          // Handle the failure case, maybe fallback to another analytics solution
        }
      }
    }, intervalTime)
  }
}

const getUTMParameters = () => {
  let utmData = {}
  const storageItems = getLocalStorageItem('utmHistory')
  // Iterate over each item in the storage
  storageItems.forEach((item) => {
    // Iterate over each key in the item
    Object.keys(item).forEach((key) => {
      // Check if the key starts with 'utm_'
      if (key.startsWith('utm_')) {
        // If the utmData already has this key, add the value to the array
        // Otherwise, create a new array with this value
        if (utmData[key]) {
          utmData[key].push(item[key])
        } else {
          utmData[key] = [item[key]]
        }
      }
    })
  })
  return utmData
}

export {
  ax,
  axMulti,
  axSchedule,
  orderLookup,
  getOneDetails,
  postOrderRefunded,
  sendRefundEmail,
  getRefundSupportTime,
  getLatestRefund,
  patchOrderRefund,
  updateOrderStatus,
  asyncUserLogIn,
  asyncUserSignupFirst,
  asyncUserEmailTakenStatus,
  asyncUserEmailTakenStatusAll,
  asyncUserEmailVerifiedStatus,
  asyncUserIsFirebaseUser,
  verifyToken,
  patchUser,
  requestDataExport,
  requestDataDeleteAll,
  confirmDataDeleteAll,
  requestSurveyDataDelete,
  confirmSurveyDataDelete,
  getAudiogram,
  postAudiograms,
  submitReview,
  deleteAudiogram,
  getHearingTestResult,
  patchHearingTestResult,
  deleteHearingTestResult,
  saveHearingTestSurvey,
  getCheckoutUrl,
  getCheckoutUrlWithOutAccount,
  visitedCheckout,
  sendFeedBackInfo,
  sendPrefittingInfo,
  getPrefittingStatus,
  emailSubscribe,
  kycQuestionnaireSubmit,
  getSchedule,
  setCalendar,
  getAvailableTimeslots,
  createAppointment,
  rescheduleAppointment,
  cancelAppointment,
  getAppointment,
  refreshAppointment,
  setTradeinAnswer,
  patchLike,
  postStory,
  patchBlogLike,
  patchHearingTest,
  insiderSubscribe,
  getPromotionCode,
  verifyPromotionCode,
  verifyFeedbackSurveyCode,
  setupIntent,
  placeOrder,
  messageBoardSubscribe,
  checkProductAvailable,
  checkoutEmail,
  getTroubleDetails,
  updateTroubleShootingComment,
  getTest,
  getAllTimeSlots,
  postSchedule,
  putSchedule,
  reSchedule,
  cancelSchedule,
  selectSchedule,
  getUserId,
  getKycUserId,
  uploadAudiogram,
  getAppointmentById,
  getUpcomingAppointmentsById,
  postUTMHistory,
  postCampaignCandidateInfo,
  postFaqFeedback,
  postFaqQuestion,
  heapAddUserProperties,
  processHeapQueue,
  getAllForum,
  submitHearingScreening,
  submitBlackFridayUserInfo,
  submitChristmasUserInfo,
  checkHeapInterval,
  getUTMParameters,
  submitCyberMondayUserInfo,
  getOrderDetails,
  getAllPersonalData,
  getAllOrderDetails,
  patchKYCResult,
  getAllKYCResults,
  getTwoDetails,
  fetchUserAudiogram,
  userSignOut,
  submitExitIntentForm,
  submitUserQuestions,
  submitUserInfo,
  submitPresidentDayUserInfo,
  submitCampaignFormAndGetCoupon,
  submitOutOfStockSubscriptionContactInfo
}
