import { ax, axSchedule } from '../../AxiosFactory'

const postSchedule = async (data) => {
  const res = await axSchedule
    .post('/appointmentSys/appointments', data)
    .then((res) => res.data)
  return res
}

const putSchedule = async (id, data) => {
  const res = await axSchedule
    .patch('/appointmentSys/appointments/' + encodeURIComponent(id), data)
    .then((res) => res.data)
  return res
}

const reSchedule = async (id, data) => {
  const res = await axSchedule
    .post('/appointmentSys/appointments/' + id + ':reschedule/', data)
    .then((res) => res.data)
  return res
}

const cancelSchedule = async (id) => {
  const res = await axSchedule
    .post('/appointmentSys/appointments/' + encodeURIComponent(id) + ':cancel')
    .then((res) => res.data)
  return res
}

const selectSchedule = async (id) => {
  const res = await axSchedule
    .get(`/appointmentSys/appointments/${encodeURIComponent(id)}`)
    .then((res) => res.data)
  return res
}

const getAppointmentById = async (attendee_id) => {
  const res = await axSchedule.get('/appointmentSys/appointments', {
    params: {
      attendee_id: attendee_id
    }
  })
  return res
}

const getUpcomingAppointmentsById = async (attendee_id) => {
  const res = await axSchedule.get('/appointmentSys/appointments', {
    params: {
      attendee_id: attendee_id,
      status: 'scheduled'
    }
  })
  // 过滤出未进行的日程安排
  const upcomingAppointments = res.data.data.filter(
    (appointment) => appointment.status === 'scheduled'
  )
  return upcomingAppointments
}

const getAvailableTimeslots = async (info) => {
  return await ax.get(
    `appointments/available-timeslots/?start_time=${new Date(
      info.now
    ).toISOString()}&end_time=${new Date(info.expiration).toISOString()}`
  )
}

const createAppointment = async (data) => {
  return await ax.post('appointments/', data)
}

const rescheduleAppointment = async (data, id) => {
  return await ax.patch(`appointments/${id}/`, data)
}

const cancelAppointment = async (data, id) => {
  return await ax.patch(`appointments/${id}/`, data)
}

const getAppointment = async () => {
  return await ax.get('appointments/')
}

// schedule
const getAllTimeSlots = async (date) => {
  const res = await axSchedule.get(
    'calendarSys/available-time-slots?from=' +
      date.start +
      '&until=' +
      date.end +
      '&appt_type=' +
      date.type
  )
  return res.data
}

export {
  postSchedule,
  putSchedule,
  reSchedule,
  cancelSchedule,
  selectSchedule,
  getAppointmentById,
  getUpcomingAppointmentsById,
  getAvailableTimeslots,
  createAppointment,
  rescheduleAppointment,
  cancelAppointment,
  getAppointment,
  getAllTimeSlots
}
