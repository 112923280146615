import { marketingService } from '../../AxiosFactory'

const submitHearingScreening = async ({
  email,
  phone,
  name,
  screening_result
}) => {
  const res = await marketingService.post('/campaign/hearing_test/', {
    email,
    phone,
    name,
    screening_result
  })
  return res
}

const submitUserInfo = async ({ email, phone, name, leads }) => {
  return await marketingService.post(`/campaign/${leads}/`, {
    email,
    phone,
    name
  })
}

const submitBlackFridayUserInfo = async (info) => {
  const res = await marketingService.post('/campaign/black_friday/', {
    name: info.name,
    email: info.email,
    phone: info.phone
  })
  return res
}

const submitCyberMondayUserInfo = async (info) => {
  const res = await marketingService.post('/campaign/cyber_day/', {
    name: info.name,
    email: info.email,
    phone: info.phone
  })
  return res
}

const submitChristmasUserInfo = async (info) => {
  const res = await marketingService.post('/campaign/christmas/', {
    name: info.name,
    email: info.email,
    phone: info.phone
  })
  return res
}

const submitExitIntentForm = async (info) => {
  try {
    const res = await marketingService.post('/campaign/springsale/', {
      name: info.name,
      email: info.email,
      phone: info.phone
    })
    return res
  } catch {
    console.error('Error submitting exit-intent user info')
  }
}

const submitPresidentDayUserInfo = async (info) => {
  const res = await marketingService.post('/campaign/president_day/', {
    name: info.name,
    email: info.email,
    phone: info.phone
  })
  return res
}

const submitCampaignFormAndGetCoupon = async (leadSource, info) => {
  const res = await marketingService.post(`/campaign/${leadSource}/`, {
    name: info.name,
    email: info.email,
    phone: info.phone
  })
  return res
}

const submitOutOfStockSubscriptionContactInfo = async (email: string, phone: string) => {
  const date = new Date();
  const year = date.getFullYear().toString().slice(2); // Get the last two digits of the year
  let month = (date.getMonth() + 1).toString(); // Get the month (0-11, so add 1)

  // If the month is a single digit, prepend a '0'
  if (month.length === 1) {
      month = '0' + month;
  }
  const yymm = year + month
  await submitCampaignFormAndGetCoupon(`${yymm}OOS`, { email, phone });
}

export {
  submitHearingScreening,
  submitBlackFridayUserInfo,
  submitCyberMondayUserInfo,
  submitChristmasUserInfo,
  submitExitIntentForm,
  submitPresidentDayUserInfo,
  submitUserInfo,
  submitCampaignFormAndGetCoupon,
  submitOutOfStockSubscriptionContactInfo
}
