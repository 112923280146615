
import { SLICE_NAME } from './state'

const actionSetProductImgIndex = (state, value) => {
    state[SLICE_NAME].productImgIndex = value?.payload
}

const actionSetPayMethod = (state, value) => {
    state[SLICE_NAME].payMethod = value?.payload
}

const actionSetPayOption = (state, value) => {
    state[SLICE_NAME].payOption = value?.payload
}

const actionSetCouponCode = (state, value) => {
    state[SLICE_NAME].couponCode = value?.couponCode
}


export default {
    actionSetProductImgIndex,
    actionSetPayMethod,
    actionSetPayOption,
    actionSetCouponCode,
}