import { SLICE_NAME } from './state'

export default {
  selectCurrentSchedultView: ({ base: state }) =>
    state[SLICE_NAME].currentSchedule,
  selectChooseTimeSlot: ({ base: state }) => state[SLICE_NAME].chooseTimeSlot,
  selectAllTimeSlots: ({ base: state }) => state[SLICE_NAME].allTimeSlots,
  selectChooseTimeZone: ({ base: state }) => state[SLICE_NAME].chooseTimeZone,
  selectScheduleId: ({ base: state }) => state[SLICE_NAME].scheduleId,
  selectScheduleInfo: ({ base: state }) => state[SLICE_NAME].scheduleInfo,
  selectMessage: ({ base: state }) => state[SLICE_NAME].message,
  selectScheduleSuccessMessage: ({ base: state }) =>
    state[SLICE_NAME].scheduleSuccessMessage,
  selectScheduleCancelled: ({ base: state }) => state[SLICE_NAME].scheduleCancelled,
  selectCloseMask: ({ base: state }) => state[SLICE_NAME].closeMask,
  selectAgent: ({ base: state }) => state[SLICE_NAME].agent,
}
