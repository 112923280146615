import { ax, axService } from '../../AxiosFactory'

const getHearingTestResult = async () => {
  return await ax.get('mkt/hearing-test-result/')
}

const deleteHearingTestResult = async () => {
  return await ax.delete('mkt/hearing-test-result/')
}

const patchHearingTestResult = async (results) => {
  return await ax.post('mkt/hearing-test-result/', results)
}

const saveHearingTestSurvey = async (info) => {
  const res = await ax.post('/mkt/hearing-test-survey/', info)
  return res
}

const sendFeedBackInfo = async (info) => {
  const res = await ax.post('/mkt/feedback/', info)
  return res
}

const kycQuestionnaireSubmit = async (data) => {
  const res = await axService.post('survey_sys/survey_results/', data)
  return res
}

const messageBoardSubscribe = async (info) => {
  const res = await ax.post('mkt/subscribe-message-board/', info)
  return res
}

const checkoutEmail = async (email) => {
  const res = ax.post('mkt/checkout-email/', { email: email })
  return res
}

const insiderSubscribe = async (info) => {
  const res = await ax.post('mkt/subscribe-insider-program/', info)
  return res
}

const emailSubscribe = async (info) => {
  const res = await ax.post('mkt/subscribe/', info)
  return res
}

const patchKYCResult = async (kycId, body) => {
  const res = await axService.patch(`survey_sys/survey_results/${kycId}`, body)
  return res
}

const getAllKYCResults = async (userId) => {
  const survey_type = 'website_know_your_customer'
  const res = await axService.get(
    `survey_sys/survey_results/?user_id=${userId}&survey_type=${survey_type}`
  )
  return res
}

export {
  getHearingTestResult,
  deleteHearingTestResult,
  patchHearingTestResult,
  saveHearingTestSurvey,
  sendFeedBackInfo,
  kycQuestionnaireSubmit,
  messageBoardSubscribe,
  checkoutEmail,
  insiderSubscribe,
  emailSubscribe,
  patchKYCResult,
  getAllKYCResults
}
