

const PAYMETHODS = ['buyNow', 'tryNow']
const PAYOPTIONS = ['full', 'month']

const SLICE_NAME = 'product'

const OrkaTwoStore = {
    productImgIndex: 0,
    payMethod: PAYMETHODS[0],
    payOption: PAYOPTIONS[0],
    couponCode: '',
}


export default OrkaTwoStore

export {
    PAYMETHODS,
    PAYOPTIONS,
    SLICE_NAME
}