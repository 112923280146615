declare global {
  interface Window {
    dataLayer: any[] // Specify the type according to your dataLayer structure
  }
}
// Your existing objects with types
const _gtmEventProductImpression: GTMEventProductImpression = {
  ecommerce: {
    impressions: [{ id: 'viqwjbpi', category: 'Hearing Aids' }]
  }
}

const _gtmEventProductDetailsImpression: GTMEventProductDetailsImpression = {
  ecommerce: {
    detail: {
      products: [{ id: 'viqwjbpi' }]
    }
  }
}

const _gtmEventCheckout: GTMEventCheckout = {
  event: 'checkout',
  ecommerce: {
    checkout: {
      products: [
        {
          id: 'viqwjbpi',
          price: '1899.00',
          quantity: 1
        }
        // Add a line for each item in the user's cart
      ]
    }
  }
}

const _gtmEventAddToCart: GTMEventAddToCart = {
  event: 'addToCart',
  ecommerce: {
    add: {
      products: [
        {
          id: 'viqwjbpi',
          price: '1899.00',
          quantity: 1
        }
        // Add a line for each item added to the cart
      ]
    }
  }
}

// Implementing the _gtmEventPurchase function
const gtmEventPurchase: GTMEventPurchaseFunction = (orderId: string) => ({
  ecommerce: {
    purchase: {
      actionField: {
        id: orderId
      },
      products: [
        {
          id: 'viqwjbpi',
          price: '1899.00',
          quantity: 1
        }
        // Add a line for each item in the user's purchase
      ]
    }
  }
})

const gtmEventUserInfo = (info: UserInformation) => ({
  uid: info
})

type GTMDataLayerEvent =
  | GTMEventProductImpression
  | GTMEventProductDetailsImpression
  | GTMEventAddToCart
  | GTMEventCheckout
  | GTMEventInfo

const gtmDataLayerPushEvents: (event: GTMDataLayerEvent) => void = (
  event: GTMDataLayerEvent
) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(event)
  }
}

export {
  gtmDataLayerPushEvents,
  _gtmEventProductImpression,
  _gtmEventProductDetailsImpression,
  _gtmEventAddToCart,
  _gtmEventCheckout,
  gtmEventPurchase,
  gtmEventUserInfo
}
