import moment from 'moment'

// refer to StatusCard.js
const status2Id = {
  ordered: 7,
  shipped: 8,
  out_of_delivery: 99, // new status, not stored in neither v1 nor v2 backend
  delivered: 10
}

export function orderCombination(order1, order2, shipping2) {
  const convertedOrder2 = []

  // add v2 order to the list
  order2.forEach((order2Item) => {
    const orderItem = {
      infos: order2Item.order_data,
      backendVersion: 'v2',
      backend_order_id: order2Item.order_data.id,
      id: order2Item.order_data.id
    }

    // Reassign order data
    let totalDiscountAmt = 0
    orderItem.discounts = order2Item.products[0]?.applied_discounts
    orderItem.discounts?.forEach((discount) => {
      discount.promotion_code = discount.code
      discount.amount_off = discount.amount
      totalDiscountAmt += discount.amount
    })
    orderItem.infos.discount_amount = totalDiscountAmt

    orderItem.infos.bc_order_id = order2Item.order_data.id
    orderItem.infos.products = order2Item.products
    orderItem.infos.total_amount = order2Item.order_data.total_inc_tax
    orderItem.infos.product_amount = order2Item.products[0].total_ex_tax
    orderItem.infos.create_time = order2Item.order_data.date_created
    orderItem.infos.shipping_address = order2Item.shipping_address

    // get shipment status
    const shipmentData = shipping2.find(
      (d) => d.store_order_id == order2Item.order_data.id
    )
    if (shipmentData) {
      orderItem.infos.status_id = status2Id[shipmentData.status]
      orderItem.infos.shipment = shipmentData?.shipments[0]
      orderItem.recipient = shipmentData.recipient
    } else {
      console.warn(
        `Cannot find shipment data for order ${order2Item.order_data.id}`
      )
      orderItem.infos.status_id = 7
    }

    convertedOrder2.push(orderItem)
  })

  // add v1 order to the list
  order1.results.forEach((order1Item) => {
    order1Item.backendVersion = 'v1'
  })

  const combinedList = [...convertedOrder2, ...order1.results]

  combinedList.sort(
    (a, b) =>
      moment(b.infos.create_time).toDate() -
      moment(a.infos.create_time).toDate()
  )

  return {
    count: combinedList.length,
    results: combinedList
  }
}

export default orderCombination
