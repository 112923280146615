import TimeZone from '../Timezone'

const SLICE_NAME = 'schedule'

const SCHEDULE_VIEWS = ['start', 'success', 'cancel']

const SCHEDULE_STATUS = ['unSchedule', 'scheduled', 'canceled']

const MESSAGE_WAIT = 'Please wait a moment, we are processing your request.'

const getCurrentDateTimeSlot = () => {
  const userTimezoneOffset = new Date().getTimezoneOffset()
  const timeDifferenceInMinutes = userTimezoneOffset * -1

  const hoursDifference = Math.floor(timeDifferenceInMinutes / 60)
  const minutesDifference = timeDifferenceInMinutes % 60

  const timeDifference = `${hoursDifference.toString()}:${Math.abs(
    minutesDifference
  )
    .toString()
    .padStart(2, '0')}`

  const UTCTimeDiff =
    'UTC' +
    (timeDifference.includes('-') ? timeDifference : '+' + timeDifference)
  const timeZoneEntry = TimeZone.find((zone) => zone.label === UTCTimeDiff)
  const tzCode = timeZoneEntry ? timeZoneEntry.tzCode : TimeZone[0].tzCode
  return tzCode
}

const ScheduleStore = {
  currentSchedule: '',
  chooseTimeSlot: [],
  allTimeSlots: [],
  chooseTimeZone: getCurrentDateTimeSlot(),
  scheduleState: SCHEDULE_STATUS[0],
  agent: '',
  scheduleId: '',
  scheduleInfo: {
    name: '',
    phone: '',
    email: ''
  },
  message: MESSAGE_WAIT,
  scheduleSuccessMessage: '',
  scheduleCancelled: false,
  closeMask: false,
}

export default ScheduleStore

export { SLICE_NAME, SCHEDULE_VIEWS, SCHEDULE_STATUS, MESSAGE_WAIT }
