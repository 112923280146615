module.exports = {
  OPEN_DRAWER: 'OPEN_DRAWER',
  SHARE_YOUR_STORY_WITH_US: 'SHARE_YOUR_STORY_WITH_US',
  THE_ORKA_COMMUNITY_HOPES_TO_BE: 'THE_ORKA_COMMUNITY_HOPES_TO_BE',
  WHAT_IS_YOUR_STORY: 'WHAT_IS_YOUR_STORY',
  ABOUT_YOU: 'ABOUT_YOU',
  SUBMIT: 'SUBMIT',
  THANKS_FOR_YOUR_SHARING: 'THANKS_FOR_YOUR_SHARING',
  FLEX: 'FLEX',
  COLUMN: 'COLUMN',
  AUTO: 'AUTO',
  PX: 'PX',
  VH: 'VH',
  _: '_',
  PLEASE_FILL_OUT_YOUR_TITLE: 'PLEASE_FILL_OUT_YOUR_TITLE',
  PLEASE_FILL_OUT_YOUR_STORY: 'PLEASE_FILL_OUT_YOUR_STORY',
  PLEASE_FILL_OUT_YOUR_NAME: 'PLEASE_FILL_OUT_YOUR_NAME',
  PLEASE_FILL_OUT_YOUR_EMAIL: 'PLEASE_FILL_OUT_YOUR_EMAIL',
  LET_S_PUT_YOUR_IDEAS_INTO_REAL: 'LET_S_PUT_YOUR_IDEAS_INTO_REAL',
  WE_D_LOVE_TO_HEAR_FROM_YOU_AND: 'WE_D_LOVE_TO_HEAR_FROM_YOU_AND',
  A_LETTER_FROM_OUR_FOUNDER: 'A_LETTER_FROM_OUR_FOUNDER',
  HI_THERE: 'HI_THERE',
  I_M_BEN_SUN_FOUNDER_OF_ORKA: 'I_M_BEN_SUN_FOUNDER_OF_ORKA',
  IN_THE_PAST_TWO_YEARS_AS_ORKA: 'IN_THE_PAST_TWO_YEARS_AS_ORKA',
  I_REMEMBER_THE_TIME_WHEN_ONE_O: 'I_REMEMBER_THE_TIME_WHEN_ONE_O',
  INNOVATION_TAKES_TIME_TO_MATUR: 'INNOVATION_TAKES_TIME_TO_MATUR',
  SCHDULE_A_FREE_ORKA_CONSULTATI: 'SCHDULE_A_FREE_ORKA_CONSULTATI',
  SCHEDULE_NOW: 'SCHEDULE_NOW',
  OUR_COMMUNITY: 'OUR_COMMUNITY',
  LORY_WANG: 'LORY_WANG',
  MY_GRANDFATHER_DOESN_T_WATCH_T: 'MY_GRANDFATHER_DOESN_T_WATCH_T',
  PX1: 'PX1',
  PX2: 'PX2',
  _1: '_1',
  _2: '_2',
  SUBSCRIBE: 'SUBSCRIBE',
  TODAY_AND_GET_OFF_YOUR_NE: 'TODAY_AND_GET_OFF_YOUR_NE',
  YOUR_OFF_CODE: 'YOUR_OFF_CODE',
  LABORDAY: 'LABORDAY',
  FFFFFF: 'FFFFFF',
  PX3: 'PX3',
  POINTER: 'POINTER',
  NONE: 'NONE',
  BLOCK: 'BLOCK',
  CENTER: 'CENTER',
  UPLOAD_AUDIOGRAM: 'UPLOAD_AUDIOGRAM',
  DRAG_DROP: 'DRAG_DROP',
  YOUR_AUDIOGRAM_HERE: 'YOUR_AUDIOGRAM_HERE',
  ONLY_JPG_PNG_AND_PDF: 'ONLY_JPG_PNG_AND_PDF',
  SUBMIT1: 'SUBMIT1',
  MY_AUDIOGRAM: 'MY_AUDIOGRAM',
  UPLOAD_NEW_AUDIOGRAM: 'UPLOAD_NEW_AUDIOGRAM',
  DELETING_AUDIOGRAM: 'DELETING_AUDIOGRAM',
  ARE_YOU_SURE_YOU_WANT_TO_DELET: 'ARE_YOU_SURE_YOU_WANT_TO_DELET',
  CANCEL: 'CANCEL',
  DELETE: 'DELETE',
  PX4: 'PX4',
  PX5: 'PX5',
  RIGHT: 'RIGHT',
  PX6: 'PX6',
  _3: '_3',
  FILE_TYPE_MUST_BE_JPG_JPEG: 'FILE_TYPE_MUST_BE_JPG_JPEG',
  UPLOAD_AUDIOGRAM1: 'UPLOAD_AUDIOGRAM1',
  UPLOAD: 'UPLOAD',
  PX7: 'PX7',
  PX8: 'PX8',
  _4: '_4',
  PX9: 'PX9',
  PX10: 'PX10',
  FILE_TYPE_MUST_BE_IMAGE_PD: 'FILE_TYPE_MUST_BE_IMAGE_PD',
  O: 'O',
  HOW_ARE_YOU: 'HOW_ARE_YOU',
  MY_CONSULTATION: 'MY_CONSULTATION',
  THE_UPCOMING_ONE_INFORMATION: 'THE_UPCOMING_ONE_INFORMATION',
  _5: '_5',
  AT: 'AT',
  MIN: 'MIN',
  ADD_TO_CALENDAR: 'ADD_TO_CALENDAR',
  GOOGLE_ICAL_OUTLOOK: 'GOOGLE_ICAL_OUTLOOK',
  RESCHEDULE: 'RESCHEDULE',
  CANCEL1: 'CANCEL1',
  ARE_YOU_SURE_YOU_WANT_TO_CANCE: 'ARE_YOU_SURE_YOU_WANT_TO_CANCE',
  YOU_MAY_SCHEDULE_A_NEW_APPOINT: 'YOU_MAY_SCHEDULE_A_NEW_APPOINT',
  NO: 'NO',
  YES: 'YES',
  YOU_HAVEN_T_SCHEDULED_ANY_CONS: 'YOU_HAVEN_T_SCHEDULED_ANY_CONS',
  START_NOW: 'START_NOW',
  SCHEDULE_CONSULTATION: 'SCHEDULE_CONSULTATION',
  ROW: 'ROW',
  FLEX_START: 'FLEX_START',
  PX11: 'PX11',
  PX12: 'PX12',
  PX13: 'PX13',
  PX14: 'PX14',
  PX15: 'PX15',
  PX16: 'PX16',
  PX17: 'PX17',
  PX18: 'PX18',
  E_E_E: 'E_E_E',
  _6: '_6',
  ACCOUNT_DETAILS: 'ACCOUNT_DETAILS',
  EDIT: 'EDIT',
  EDIT_BASIC_INFORMATION: 'EDIT_BASIC_INFORMATION',
  YEAR_OF_BIRTH: 'YEAR_OF_BIRTH',
  GENDER: 'GENDER',
  CHANGE_MY_PASSWORD: 'CHANGE_MY_PASSWORD',
  SAVE: 'SAVE',
  FONTWEIGHTBOLD: 'FONTWEIGHTBOLD',
  LEFT: 'LEFT',
  _7: '_7',
  CH: 'CH',
  _8: '_8',
  _9: '_9',
  PX19: 'PX19',
  _10: '_10',
  _11: '_11',
  PX20: 'PX20',
  FLEX_END: 'FLEX_END',
  SPACE_BETWEEN: 'SPACE_BETWEEN',
  MEDIUM: 'MEDIUM',
  _12: '_12',
  CURRENT_PASSWORD_WRONG_CURREN: 'CURRENT_PASSWORD_WRONG_CURREN',
  PASSWORD_MUST_BE_CHARACTERS: 'PASSWORD_MUST_BE_CHARACTERS',
  NAME: 'NAME',
  EMAIL: 'EMAIL',
  PROVINCE: 'PROVINCE',
  PASSWORD: 'PASSWORD',
  NAME1: 'NAME1',
  EMAIL1: 'EMAIL1',
  YEAR: 'YEAR',
  GENDER1: 'GENDER1',
  STATE: 'STATE',
  PASSWORD1: 'PASSWORD1',
  ALBERTA: 'ALBERTA',
  BRITISH_COLUMBIA: 'BRITISH_COLUMBIA',
  MANITOBA: 'MANITOBA',
  NEW_BRUNSWICK: 'NEW_BRUNSWICK',
  NEWFOUNDLAND_AND_LABRADOR: 'NEWFOUNDLAND_AND_LABRADOR',
  NORTHWEST_TERRITORIES: 'NORTHWEST_TERRITORIES',
  NOVA_SCOTIA: 'NOVA_SCOTIA',
  NUNAVUT: 'NUNAVUT',
  ONTARIO: 'ONTARIO',
  PRINCE_EDWARD_ISLAND: 'PRINCE_EDWARD_ISLAND',
  QUEBEC: 'QUEBEC',
  SASKATCHEWAN: 'SASKATCHEWAN',
  YUKON_TERRITORY: 'YUKON_TERRITORY',
  NEW_PASSWORD_MUST_BE_CHARAC: 'NEW_PASSWORD_MUST_BE_CHARAC',
  PASSWORD_CONFIRMATION_THE_PAS: 'PASSWORD_CONFIRMATION_THE_PAS',
  LOG_OUT: 'LOG_OUT',
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHERS: 'OTHERS',
  PX_VW: 'PX_VW',
  PX21: 'PX21',
  FFFFFF1: 'FFFFFF1',
  F_F_F: 'F_F_F',
  D_D_D: 'D_D_D',
  PX22: 'PX22',
  _13: '_13',
  CH1: 'CH1',
  CH2: 'CH2',
  CH3: 'CH3',
  A_A_A: 'A_A_A',
  _14: '_14',
  F_AA_E: 'F_AA_E',
  _15: '_15',
  PX_SOLID_A_A_A: 'PX_SOLID_A_A_A',
  PX_SOLID: 'PX_SOLID',
  PX_SOLID_FFBF_D: 'PX_SOLID_FFBF_D',
  PX23: 'PX23',
  SMALL: 'SMALL',
  _16: '_16',
  _17: '_17',
  _18: '_18',
  PX24: 'PX24',
  ANYWHERE: 'ANYWHERE',
  CURRENT_PASSWORD_MUST_BE_CH: 'CURRENT_PASSWORD_MUST_BE_CH',
  BIRTH: 'BIRTH',
  IN_RETURN_PROCESS: 'IN_RETURN_PROCESS',
  ORDER_NUMBER: 'ORDER_NUMBER',
  DAY_FREE_HOME_TRY_ON: 'DAY_FREE_HOME_TRY_ON',
  DAY_FREE_HOME_TRY_ON_45: 'DAY_FREE_HOME_TRY_ON_45',
  ORDER_TOTAL: 'ORDER_TOTAL',
  FREE: 'FREE',
  _19: '_19',
  RETURN_DETAILS: 'RETURN_DETAILS',
  ORDER_SUMMARY: 'ORDER_SUMMARY',
  ESTIMATED_DELIVERED_DATE: 'ESTIMATED_DELIVERED_DATE',
  _20: '_20',
  _21: '_21',
  YOUR_LABEL_IS_READY_TO_PRINT: 'YOUR_LABEL_IS_READY_TO_PRINT',
  JUST_PRINT_AND_ATTACH_THE_RETU: 'JUST_PRINT_AND_ATTACH_THE_RETU',
  PRINT_LABEL: 'PRINT_LABEL',
  FIND_A_DROP_OFF_LOCATION: 'FIND_A_DROP_OFF_LOCATION',
  MY_ORDERS: 'MY_ORDERS',
  PX25: 'PX25',
  PX26: 'PX26',
  PX27: 'PX27',
  INLINE: 'INLINE',
  PX28: 'PX28',
  PX29: 'PX29',
  PX30: 'PX30',
  PX_PX: 'PX_PX',
  PX_PX_PX_PX: 'PX_PX_PX_PX',
  PX_PX1: 'PX_PX1',
  _22: '_22',
  F_B_B: 'F_B_B',
  BLACK: 'BLACK',
  FFBF_D: 'FFBF_D',
  _23: '_23',
  _24: '_24',
  PX31: 'PX31',
  PX32: 'PX32',
  F_F_F1: 'F_F_F1',
  F_CB_A: 'F_CB_A',
  SUPPORT_TIME: 'SUPPORT_TIME',
  JAN: 'JAN',
  FEB: 'FEB',
  MAR: 'MAR',
  APR: 'APR',
  MAY: 'MAY',
  JUN: 'JUN',
  JUL: 'JUL',
  AUG: 'AUG',
  SEP: 'SEP',
  OCT: 'OCT',
  NOV: 'NOV',
  DEC: 'DEC',
  YOUR_LABLE_IS_READY_TO_PRINT: 'YOUR_LABLE_IS_READY_TO_PRINT',
  JUST_PRINT_AND_ATTACH_THE_RETU1: 'JUST_PRINT_AND_ATTACH_THE_RETU1',
  ORDER_NO: 'ORDER_NO',
  ESTIMATED_DELIVERY: 'ESTIMATED_DELIVERY',
  PX33: 'PX33',
  F_B: 'F_B',
  F_AA_D: 'F_AA_D',
  _25: '_25',
  B_B_B: 'B_B_B',
  EAEAEA: 'EAEAEA',
  F_F_F2: 'F_F_F2',
  PX_PX2: 'PX_PX2',
  PX_PX3: 'PX_PX3',
  _26: '_26',
  PX_PX4: 'PX_PX4',
  DETAIL: 'DETAIL',
  ACCOUNT_DETAILS1: 'ACCOUNT_DETAILS1',
  ORDER: 'ORDER',
  SIGNOUT: 'SIGNOUT',
  SIGN_OUT: 'SIGN_OUT',
  NEED_HELP: 'NEED_HELP',
  CANCEL_THE_ORDER: 'CANCEL_THE_ORDER',
  TRY_ORKA_ONE_TRIAL_PERIOD: 'TRY_ORKA_ONE_TRIAL_PERIOD',
  CANCEL_SUMMARY: 'CANCEL_SUMMARY',
  ORKA_ONE_PRE_ORDER_VOUCHER: 'ORKA_ONE_PRE_ORDER_VOUCHER',
  ORDER_NUMBER1: 'ORDER_NUMBER1',
  REFUND: 'REFUND',
  WHY_DO_YOU_CANCEL_YOUR_ORDER: 'WHY_DO_YOU_CANCEL_YOUR_ORDER',
  PICK_A_REASON_FOR_YOUR_RETURN: 'PICK_A_REASON_FOR_YOUR_RETURN',
  PLEASE_MAKE_IT_MANDATORY_TO_AN: 'PLEASE_MAKE_IT_MANDATORY_TO_AN',
  BOUGHT_IT_SOMEWHERE_ELSE: 'BOUGHT_IT_SOMEWHERE_ELSE',
  WAIT_TIME_IS_TOO_LONG: 'WAIT_TIME_IS_TOO_LONG',
  THE_VOUCHER_OR_ORDER_TOTAL_WAS: 'THE_VOUCHER_OR_ORDER_TOTAL_WAS',
  DIDN_T_RECEIVE_ENOUGH_CUSTOMER: 'DIDN_T_RECEIVE_ENOUGH_CUSTOMER',
  FILLED_IN_THE_WRONG_INFORMATIO: 'FILLED_IN_THE_WRONG_INFORMATIO',
  OTHER: 'OTHER',
  ADDITIONAL_NOTES: 'ADDITIONAL_NOTES',
  BACK: 'BACK',
  PX_PX5: 'PX_PX5',
  PRE_ORDER_DEPOSIT_CANCELED: 'PRE_ORDER_DEPOSIT_CANCELED',
  YOUR_PRE_ORDER_DEPOSIT_IS_SUCC: 'YOUR_PRE_ORDER_DEPOSIT_IS_SUCC',
  CANCELLATION_DETAILS: 'CANCELLATION_DETAILS',
  PRE_ORDER_DEPOSIT: 'PRE_ORDER_DEPOSIT',
  ORDER_CANCELED: 'ORDER_CANCELED',
  WE_WILL_AUTOMATICALLY_REFUND_Y: 'WE_WILL_AUTOMATICALLY_REFUND_Y',
  BACK_TO_ORDERS: 'BACK_TO_ORDERS',
  IF_YOU_NEED_FURTHER_ASSISTANT: 'IF_YOU_NEED_FURTHER_ASSISTANT',
  PLEASE_CHAT_WITH_US_ONLINE: 'PLEASE_CHAT_WITH_US_ONLINE',
  ORKA_ONE_PREORDER_VOUCHER: 'ORKA_ONE_PREORDER_VOUCHER',
  ORDER_NUMBER2: 'ORDER_NUMBER2',
  QUANTITY: 'QUANTITY',
  ORDER_TOTAL1: 'ORDER_TOTAL1',
  BACK_TO_ORDERS1: 'BACK_TO_ORDERS1',
  CHAT_WITH_US_ONLINE: 'CHAT_WITH_US_ONLINE',
  PX34: 'PX34',
  YOU_HAVEN_T_PLACED_ANY_ORDERS: 'YOU_HAVEN_T_PLACED_ANY_ORDERS',
  GO_SHOPPING: 'GO_SHOPPING',
  PRODUCT: 'PRODUCT',
  CLOSE: 'CLOSE',
  SHIPPING_ADDRESS_EDIT: 'SHIPPING_ADDRESS_EDIT',
  COUNTRY: 'COUNTRY',
  UNITED_STATES: 'UNITED_STATES',
  STATE1: 'STATE1',
  BILLING_ADDRESS_IS_SAME_AS_THE: 'BILLING_ADDRESS_IS_SAME_AS_THE',
  BILLING_ADDRESS_EDIT: 'BILLING_ADDRESS_EDIT',
  ORDER_SUMMARY1: 'ORDER_SUMMARY1',
  ORDER_DATE: 'ORDER_DATE',
  PRINT_INOVICE: 'PRINT_INOVICE',
  TRACK_MY_PACKAGE: 'TRACK_MY_PACKAGE',
  SHIPPED_WITH_FEDEX_TRACKING: 'SHIPPED_WITH_FEDEX_TRACKING',
  HTTPS_WWW_FEDEX_COM_FEDEXTRA: 'HTTPS_WWW_FEDEX_COM_FEDEXTRA',
  STANDARD_DELIVERY_BUSINESS: 'STANDARD_DELIVERY_BUSINESS',
  DELIVERED: 'DELIVERED',
  ORDERED: 'ORDERED',
  SHIPPED: 'SHIPPED',
  BACK1: 'BACK1',
  HOW_TO_VIDEO_QUICK_START_GUI: 'HOW_TO_VIDEO_QUICK_START_GUI',
  HOW_TO_VIDEO: 'HOW_TO_VIDEO',
  ON_AND_OFF: 'ON_AND_OFF',
  YOUR_BROWSER_DOES_NOT_SUPPORT: 'YOUR_BROWSER_DOES_NOT_SUPPORT',
  YES1: 'YES1',
  NO1: 'NO1',
  HOW_TO_WEAR: 'HOW_TO_WEAR',
  CHARGING: 'CHARGING',
  BLUETOOTH_CONNECTION: 'BLUETOOTH_CONNECTION',
  DOUBLE_TAP: 'DOUBLE_TAP',
  QUICK_START_GUIDE: 'QUICK_START_GUIDE',
  ORKA_ONE_HEARING_AIDS_QUICK_ST: 'ORKA_ONE_HEARING_AIDS_QUICK_ST',
  ORDER_DETAILS: 'ORDER_DETAILS',
  H: 'H',
  ORKA_INSIDER_PROGRAM: 'ORKA_INSIDER_PROGRAM',
  ORKA_AIMS: 'ORKA_AIMS',
  WhAT_IS_INSIDER: 'WhAT_IS_INSIDER',
  ORKA_ONE_AI_HEARING_AIDS: 'ORKA_ONE_AI_HEARING_AIDS',
  YOUR_TOTAL: 'YOUR_TOTAL',
  SUBTOTAL: 'SUBTOTAL',
  DISCOUNT: 'DISCOUNT',
  _27: '_27',
  SHIPPING: 'SHIPPING',
  TAX: 'TAX',
  _28: '_28',
  TOTAL: 'TOTAL',
  DELIVERY: 'DELIVERY',
  DELIVERS_TO: 'DELIVERS_TO',
  CONTACT_INFORMATION: 'CONTACT_INFORMATION',
  PAYMENT_INFOMATION: 'PAYMENT_INFOMATION',
  PAY_MONTHLY_WITH: 'PAY_MONTHLY_WITH',
  _29: '_29',
  BILLING_ADDRESS: 'BILLING_ADDRESS',
  PRINT_INVOICE: 'PRINT_INVOICE',
  DOWNLOAD_INVOICE: 'DOWNLOAD_INVOICE',
  HOW_TO_RETURN_MY_HEARING_AIDS: 'HOW_TO_RETURN_MY_HEARING_AIDS',
  HOW_TO_RETURN_MY_HEARING_AIDS1: 'HOW_TO_RETURN_MY_HEARING_AIDS1',
  ONCE_YOUR_DAYS_ARE_UP_PLAC: 'ONCE_YOUR_DAYS_ARE_UP_PLAC',
  ONCE_YOUR_21_DAYS_ARE_UP_PLAC: 'ONCE_YOUR_21_DAYS_ARE_UP_PLAC',
  HOW_TO_GET_THE_PREPAID_RETURN: 'HOW_TO_GET_THE_PREPAID_RETURN',
  NO_WORRIES_RETURNS_ARE_ON_US: 'NO_WORRIES_RETURNS_ARE_ON_US',
  WRITE_A_REVIEW: 'WRITE_A_REVIEW',
  ORKA_IS_DEDICATED_TO_MAKING_HE: 'ORKA_IS_DEDICATED_TO_MAKING_HE',
  WHAT_S_YOUR_STORY: 'WHAT_S_YOUR_STORY',
  REVIEW_TITLE: 'REVIEW_TITLE',
  TITLE_OF_YOUR_STORY: 'TITLE_OF_YOUR_STORY',
  TITLE: 'TITLE',
  REVIEW_STORY: 'REVIEW_STORY',
  YOUR_STORY_HERE: 'YOUR_STORY_HERE',
  STORY: 'STORY',
  REVIEW: 'REVIEW',
  TRUE: 'TRUE',
  VIEW_ORDER_DETAILS: 'VIEW_ORDER_DETAILS',
  THANK_YOU_FOR_CHOOSING_ORKA_TO: 'THANK_YOU_FOR_CHOOSING_ORKA_TO',
  IF_YOU_VE_ENJOYED_ORKA_HEARING: 'IF_YOU_VE_ENJOYED_ORKA_HEARING',
  PAY_IN_FULL: 'PAY_IN_FULL',
  CONTAINED: 'CONTAINED',
  INSIDERCHECKOUT_ORDERNUM: 'INSIDERCHECKOUT_ORDERNUM',
  VIEW_MORE_PRINT_INOVICE: 'VIEW_MORE_PRINT_INOVICE',
  VIEW_MORE_PRINT_INVOICE: 'VIEW_MORE_PRINT_INVOICE',
  VIEW_MORE_DOWNLOAD_INVOICE: 'VIEW_MORE_DOWNLOAD_INVOICE',
  UNSET: 'UNSET',
  _30: '_30',
  _31: '_31',
  CALC_PX: 'CALC_PX',
  PX35: 'PX35',
  CALC_PX1: 'CALC_PX1',
  FIT_CONTENT: 'FIT_CONTENT',
  PX36: 'PX36',
  PX37: 'PX37',
  _32: '_32',
  PX38: 'PX38',
  PX39: 'PX39',
  PX40: 'PX40',
  PX41: 'PX41',
  PX42: 'PX42',
  PX43: 'PX43',
  PX44: 'PX44',
  _33: '_33',
  F_F_F3: 'F_F_F3',
  F_F: 'F_F',
  WHITE: 'WHITE',
  RGBA: 'RGBA',
  FFBF_D1: 'FFBF_D1',
  INLINE_BLOCK: 'INLINE_BLOCK',
  START: 'START',
  PX45: 'PX45',
  PX46: 'PX46',
  PX47: 'PX47',
  COLUMN1: 'COLUMN1',
  PX48: 'PX48',
  PX49: 'PX49',
  PX50: 'PX50',
  PX51: 'PX51',
  PX52: 'PX52',
  PX53: 'PX53',
  _34: '_34',
  _35: '_35',
  PX54: 'PX54',
  _36: '_36',
  UNDERLINE: 'UNDERLINE',
  CAPITALIZE: 'CAPITALIZE',
  PX55: 'PX55',
  YOU_WILL_SEE_YOUR_TRACKING_NUM: 'YOU_WILL_SEE_YOUR_TRACKING_NUM',
  DUE_TO_THE_COVID_PANDEMIC: 'DUE_TO_THE_COVID_PANDEMIC',
  YOUR_PACKAGE_WAS_SUCCESSFULLY: 'YOUR_PACKAGE_WAS_SUCCESSFULLY',
  QTY: 'QTY',
  SHIPPING_INFORMATION: 'SHIPPING_INFORMATION',
  SHIPPING_METHOD: 'SHIPPING_METHOD',
  STANDARD_DELIVERY_FREE: 'STANDARD_DELIVERY_FREE',
  SHIPPING_ADDRESS: 'SHIPPING_ADDRESS',
  PAYMENT_INFORMATION: 'PAYMENT_INFORMATION',
  BILLING_ADDRESS1: 'BILLING_ADDRESS1',
  ORDER_TOTAL2: 'ORDER_TOTAL2',
  SUBTOTAL1: 'SUBTOTAL1',
  DISCOUNT1: 'DISCOUNT1',
  TAX1: 'TAX1',
  _37: '_37',
  SHIPPING1: 'SHIPPING1',
  FREE1: 'FREE1',
  APPLY_FOR_AFTER_SALE_SERVICE: 'APPLY_FOR_AFTER_SALE_SERVICE',
  PLEASE_LOG_IN_USING_THE_EMAIL: 'PLEASE_LOG_IN_USING_THE_EMAIL',
  APPLY_FOR_RETURN: 'APPLY_FOR_RETURN',
  BLUR_PX: 'BLUR_PX',
  ALERT_DIALOG_SLIDE_TITLE: 'ALERT_DIALOG_SLIDE_TITLE',
  ALERT_DIALOG_SLIDE_DESCRIPTION: 'ALERT_DIALOG_SLIDE_DESCRIPTION',
  ARE_YOU_SURE_TO_APPLY_FOR_A_RE: 'ARE_YOU_SURE_TO_APPLY_FOR_A_RE',
  NO2: 'NO2',
  BACK_TO_TOP: 'BACK_TO_TOP',
  PX_SOLID_BLACK: 'PX_SOLID_BLACK',
  PX_SOLID_BLACK1: 'PX_SOLID_BLACK1',
  PAYMENT_SUCCESSFUL: 'PAYMENT_SUCCESSFUL',
  THANK_YOU_FOR_CHOOSING_ORKA_HE: 'THANK_YOU_FOR_CHOOSING_ORKA_HE',
  MY_ORDERS1: 'MY_ORDERS1',
  PAYMENT_SUCCESSFUL1: 'PAYMENT_SUCCESSFUL1',
  YOUR_ORDER_HAS_BEEN_PLACED_SUC: 'YOUR_ORDER_HAS_BEEN_PLACED_SUC',
  THE_FOLLOWING_ITEM_HAS_BEEN_OR: 'THE_FOLLOWING_ITEM_HAS_BEEN_OR',
  ORKA_ONE: 'ORKA_ONE',
  AI_HEARING_AIDS: 'AI_HEARING_AIDS',
  DEPOSIT_PAIED: 'DEPOSIT_PAIED',
  REMAINING_BALANCE: 'REMAINING_BALANCE',
  VIEW_MY_ORDER: 'VIEW_MY_ORDER',
  IF_YOU_HAVE_ANY_QUESTIONS: 'IF_YOU_HAVE_ANY_QUESTIONS',
  REFUND_SUMMARY: 'REFUND_SUMMARY',
  THE_FOLLOWING_ITEM_FOR_RETURN: 'THE_FOLLOWING_ITEM_FOR_RETURN',
  ORDER_NUMBER3: 'ORDER_NUMBER3',
  QUANTITY1: 'QUANTITY1',
  REFUND1: 'REFUND1',
  ITEM_RECEIVED: 'ITEM_RECEIVED',
  WE_HAVE_RECEIVED_YOUR_RETURN: 'WE_HAVE_RECEIVED_YOUR_RETURN',
  BACK_TO_ORDER: 'BACK_TO_ORDER',
  IF_YOU_NEED_FURTHER_ASSISTANT1: 'IF_YOU_NEED_FURTHER_ASSISTANT1',
  INTER: 'INTER',
  _38: '_38',
  _39: '_39',
  WAIT_HERE_IS_A_DAY_FREE_TR: 'WAIT_HERE_IS_A_DAY_FREE_TR',
  UPLOAD_IMAGE_MAX_PHOTOS: 'UPLOAD_IMAGE_MAX_PHOTOS',
  REFUND_REASON: 'REFUND_REASON',
  YOUR_FEEDBACK_IS_VALUABLE_TO_U: 'YOUR_FEEDBACK_IS_VALUABLE_TO_U',
  SELECT_A_REASON_FOR_REFUND: 'SELECT_A_REASON_FOR_REFUND',
  BOUGHT_HEARING_AIDS_FROM_OTHER: 'BOUGHT_HEARING_AIDS_FROM_OTHER',
  LATE_DELIVERY_OF_THE_PRODUCT: 'LATE_DELIVERY_OF_THE_PRODUCT',
  PRODUCT_DID_NOT_MEET_MY_EXPECT: 'PRODUCT_DID_NOT_MEET_MY_EXPECT',
  UNSATISFACTORY_CUSTOMER_SERVIC: 'UNSATISFACTORY_CUSTOMER_SERVIC',
  THE_PRODUCT_WAS_DAMAGED_UPON_A: 'THE_PRODUCT_WAS_DAMAGED_UPON_A',
  PRODUCT_DOES_NOT_MATCH_DESCRIP: 'PRODUCT_DOES_NOT_MATCH_DESCRIP',
  QUALITY_PROBLEM: 'QUALITY_PROBLEM',
  PROBLEM_WITH_ACCESSORIES: 'PROBLEM_WITH_ACCESSORIES',
  ADDITIONAL_NOTES_OPTIONAL: 'ADDITIONAL_NOTES_OPTIONAL',
  ADDITIONAL_IMAGES_OPTIONAL: 'ADDITIONAL_IMAGES_OPTIONAL',
  THE_CONTENT_OF_THE_POPOVER: 'THE_CONTENT_OF_THE_POPOVER',
  OUT_OF_RANGE: 'OUT_OF_RANGE',
  CONTINUE: 'CONTINUE',
  EASY_REFUNDS: 'EASY_REFUNDS',
  RED: 'RED',
  REQUEST_AUTHORIZED: 'REQUEST_AUTHORIZED',
  WE_HOPE_THAT_YOU_RE_THRILLED_W: 'WE_HOPE_THAT_YOU_RE_THRILLED_W',
  IF_YOU_NEED_FURTHER_ASSISTANT2: 'IF_YOU_NEED_FURTHER_ASSISTANT2',
  CHAT_WITH_US_ONLINE1: 'CHAT_WITH_US_ONLINE1',
  PX_PX6: 'PX_PX6',
  NORMAL: 'NORMAL',
  THE_FOLLOWING_ITEM_HAS_BEEN_SU: 'THE_FOLLOWING_ITEM_HAS_BEEN_SU',
  ORDER_TOTAL3: 'ORDER_TOTAL3',
  QTY1: 'QTY1',
  CHAT_WITH_US_ONLINE2: 'CHAT_WITH_US_ONLINE2',
  _40: '_40',
  D_D_D1: 'D_D_D1',
  REFUND_CLOSED: 'REFUND_CLOSED',
  SORRY_YOU_HAVEN_T_DELIVERED_Y: 'SORRY_YOU_HAVEN_T_DELIVERED_Y',
  GREY: 'GREY',
  PX_PX7: 'PX_PX7',
  SORRY_YOUR_ITEM_IS_NOT_IN_THE: 'SORRY_YOUR_ITEM_IS_NOT_IN_THE',
  APPLY_FOR_A_REFUND: 'APPLY_FOR_A_REFUND',
  SORRY_YOUR_ITEM_IS_NOT_IN_THE1: 'SORRY_YOUR_ITEM_IS_NOT_IN_THE1',
  PX_PX_PX: 'PX_PX_PX',
  ITEM_DELIVERED: 'ITEM_DELIVERED',
  WE_HAVE_RECEIVED_THE_ITEM_YOU: 'WE_HAVE_RECEIVED_THE_ITEM_YOU',
  PHONE_NUMBER: 'PHONE_NUMBER',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  WHEN_ARE_YOU_AVAILABLE: 'WHEN_ARE_YOU_AVAILABLE',
  PICK_A_DATE: 'PICK_A_DATE',
  PICK_A_TIME_SELECT_ALL_THAT_A: 'PICK_A_TIME_SELECT_ALL_THAT_A',
  CANCEL_REQUEST: 'CANCEL_REQUEST',
  BEFORE_SUBMITTING_THE_RETURN_R: 'BEFORE_SUBMITTING_THE_RETURN_R',
  RETURN_POLICY: 'RETURN_POLICY',
  CONFIRM_YOUR_INFORMATION: 'CONFIRM_YOUR_INFORMATION',
  OUR_SUPPORT_TEAM_WILL_CONTACT: 'OUR_SUPPORT_TEAM_WILL_CONTACT',
  B_B_B1: 'B_B_B1',
  PRIMARY: 'PRIMARY',
  PX_PX_PX_PX1: 'PX_PX_PX_PX1',
  PX_PX_PX1: 'PX_PX_PX1',
  PX56: 'PX56',
  PX57: 'PX57',
  PHONE_NUMBER1: 'PHONE_NUMBER1',
  REFUND_ISSUED: 'REFUND_ISSUED',
  YOUR_REFUND_HAS_BEEN_APPLIED_T: 'YOUR_REFUND_HAS_BEEN_APPLIED_T',
  YOUR_REFUND_HAS_BEEN_ISSUED_T: 'YOUR_REFUND_HAS_BEEN_ISSUED_T',
  WANT_TO_REFUND_NO_PROBLEM: 'WANT_TO_REFUND_NO_PROBLEM',
  WAIT_WE_OFFER_A_DAY_FREE_T: 'WAIT_WE_OFFER_A_DAY_FREE_T',
  REFUND_SUMMARY1: 'REFUND_SUMMARY1',
  RETURN_REASON: 'RETURN_REASON',
  WE_TAKE_YOUR_FEEDBACK_TO_HEART: 'WE_TAKE_YOUR_FEEDBACK_TO_HEART',
  PICK_A_REASON_FOR_YOUR_CANCEL: 'PICK_A_REASON_FOR_YOUR_CANCEL',
  FOUND_IT_CHEAPER_SOMEWHERE_ELS: 'FOUND_IT_CHEAPER_SOMEWHERE_ELS',
  I_DON_T_WANT_IT: 'I_DON_T_WANT_IT',
  I_FILLED_IN_THE_WRONG_SHIPPING: 'I_FILLED_IN_THE_WRONG_SHIPPING',
  OTHER1: 'OTHER1',
  ADDITIONAL_NOTES_OPTIONAL1: 'ADDITIONAL_NOTES_OPTIONAL1',
  IS_THERE_ANYTHING_WE_COULD_HAV: 'IS_THERE_ANYTHING_WE_COULD_HAV',
  ADDITIONAL_PHOTOS_FOR_THE_RETU: 'ADDITIONAL_PHOTOS_FOR_THE_RETU',
  CONFIRM_YOUR_PERSONAL_INFORMAT: 'CONFIRM_YOUR_PERSONAL_INFORMAT',
  OUR_SUPPORT_STAFF_WILL_CONTACT: 'OUR_SUPPORT_STAFF_WILL_CONTACT',
  WHEN_ARE_YOU_AVAILABLE1: 'WHEN_ARE_YOU_AVAILABLE1',
  PICK_A_TIME: 'PICK_A_TIME',
  CANCEL_REQUEST1: 'CANCEL_REQUEST1',
  PX_PX8: 'PX_PX8',
  PX_PX9: 'PX_PX9',
  BY_SUBMITTING_THE_RETURN_REQUE: 'BY_SUBMITTING_THE_RETURN_REQUE',
  INITIAL: 'INITIAL',
  OUR_SUPPORT_TEAM_WILL_CONTACT1: 'OUR_SUPPORT_TEAM_WILL_CONTACT1',
  REQUEST_SUBMITTED: 'REQUEST_SUBMITTED',
  YOUR_RETURN_REQUEST_IS_SUCCESS: 'YOUR_RETURN_REQUEST_IS_SUCCESS',
  IF_YOU_NEED_FURTHER_HELP_PLEA: 'IF_YOU_NEED_FURTHER_HELP_PLEA',
  CHAT_WITH_OUR_ONLINE_ASSISTANT: 'CHAT_WITH_OUR_ONLINE_ASSISTANT',
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
  PX_PX_PX2: 'PX_PX_PX2',
  OUR_SUPPORT_TEAM_WILL_CONTACT2: 'OUR_SUPPORT_TEAM_WILL_CONTACT2',
  REQUEST_SUBMITED: 'REQUEST_SUBMITED',
  YOU_HAVE_SUBMITTED_THE_RETURN: 'YOU_HAVE_SUBMITTED_THE_RETURN',
  ORKA_ONE_HEARING_AIDS: 'ORKA_ONE_HEARING_AIDS',
  ORDER_NUMBER4: 'ORDER_NUMBER4',
  QUANTITY2: 'QUANTITY2',
  REFUND_TOTAL: 'REFUND_TOTAL',
  IF_YOU_NEED_FURTHER_ASSISTANT3: 'IF_YOU_NEED_FURTHER_ASSISTANT3',
  REQUEST_UNAUTHORIZED: 'REQUEST_UNAUTHORIZED',
  PLEASE_RESUBMIT_YOUR_RETURN_RE: 'PLEASE_RESUBMIT_YOUR_RETURN_RE',
  APPLY_FOR_REFUND: 'APPLY_FOR_REFUND',
  REFUND_UNAUTHORIZED: 'REFUND_UNAUTHORIZED',
  ORDER_NUMBER5: 'ORDER_NUMBER5',
  ORDER_CANCELED1: 'ORDER_CANCELED1',
  YOUR_ORDER_IS_IN_RETURN_PROCES: 'YOUR_ORDER_IS_IN_RETURN_PROCES',
  ORDER_DETAILS1: 'ORDER_DETAILS1',
  AM_I_A_CANDIDATE: 'AM_I_A_CANDIDATE',
  TAKE_THE_QUIZ: 'TAKE_THE_QUIZ',
  CIRCLE: 'CIRCLE',
  BOTTOM: 'BOTTOM',
  DOT: 'DOT',
  SABESAN_SATHANANTHAN: 'SABESAN_SATHANANTHAN',
  A: 'A',
  ROUNDED: 'ROUNDED',
  BLANK: 'BLANK',
  PX58: 'PX58',
  REM: 'REM',
  D: 'D',
  B_F: 'B_F',
  B: 'B',
  _41: '_41',
  FCFCFD: 'FCFCFD',
  DB_C: 'DB_C',
  F_D: 'F_D',
  D_D_CD: 'D_D_CD',
  D_D_CD1: 'D_D_CD1',
  INHERIT: 'INHERIT',
  ERROR: 'ERROR',
  TRANSLATE_PX_PX_SCALE: 'TRANSLATE_PX_PX_SCALE',
  TRANSLATEY_PX: 'TRANSLATEY_PX',
  TRANSLATEY_PX1: 'TRANSLATEY_PX1',
  F: 'F',
  PX_SOLID_F: 'PX_SOLID_F',
  PX_PX_PX3: 'PX_PX_PX3',
  PX_PX10: 'PX_PX10',
  PX_FCEBB: 'PX_FCEBB',
  FDEBEB: 'FDEBEB',
  S: 'S',
  BACKGROUND_COLOR_COLOR: 'BACKGROUND_COLOR_COLOR',
  SCHEDULE_YOUR_PHONE_CONSULTATI: 'SCHEDULE_YOUR_PHONE_CONSULTATI',
  MEETING_DURATION_MIN: 'MEETING_DURATION_MIN',
  NO_SLOTS_AVAILABLE: 'NO_SLOTS_AVAILABLE',
  PLEASE_CHOOSE_ANOTHER_DATE: 'PLEASE_CHOOSE_ANOTHER_DATE',
  SLOT: 'SLOT',
  ORKA_TEAM_WILL_CALL_YOUR_DURIN: 'ORKA_TEAM_WILL_CALL_YOUR_DURIN',
  _42: '_42',
  CONTINUE1: 'CONTINUE1',
  F_F_FB: 'F_F_FB',
  F_F_F4: 'F_F_F4',
  SUN: 'SUN',
  MON: 'MON',
  TUE: 'TUE',
  WED: 'WED',
  THU: 'THU',
  FRI: 'FRI',
  SAT: 'SAT',
  JAN1: 'JAN1',
  FEB1: 'FEB1',
  MAR1: 'MAR1',
  APR1: 'APR1',
  MAY1: 'MAY1',
  JUN1: 'JUN1',
  JUL1: 'JUL1',
  AUG1: 'AUG1',
  SEP1: 'SEP1',
  OCT1: 'OCT1',
  NOV1: 'NOV1',
  DEC1: 'DEC1',
  YYYY_MM_DD: 'YYYY_MM_DD',
  NO_SLOTS: 'NO_SLOTS',
  AVAILABLE: 'AVAILABLE',
  MEETING_DURATION: 'MEETING_DURATION',
  ORKA_TEAM_WILL_CALL_YOU_DURING: 'ORKA_TEAM_WILL_CALL_YOU_DURING',
  TRADEIN: 'TRADEIN',
  PX59: 'PX59',
  PX60: 'PX60',
  PX61: 'PX61',
  PX62: 'PX62',
  PX63: 'PX63',
  A_FF: 'A_FF',
  F_F_F5: 'F_F_F5',
  F_F_F6: 'F_F_F6',
  HERE_S_YOUR_FIRST_STEP_TO_A_SM: 'HERE_S_YOUR_FIRST_STEP_TO_A_SM',
  GET_ON_A_MIN_CONSULTATION_C: 'GET_ON_A_MIN_CONSULTATION_C',
  HAVE_A_MINUTE_CALL_AND_GET: 'HAVE_A_MINUTE_CALL_AND_GET',
  VOICE_FROM_ORKA_USERS: 'VOICE_FROM_ORKA_USERS',
  EVERY_USER_S_OPINION_MATTERS: 'EVERY_USER_S_OPINION_MATTERS',
  ALL: 'ALL',
  HEARING_FORUMS: 'HEARING_FORUMS',
  USER_REVIEWS: 'USER_REVIEWS',
  ORKA_TEAM: 'ORKA_TEAM',
  H1: 'H1',
  PLEASE_CONFIRM_YOUR_AUDIOGRAM: 'PLEASE_CONFIRM_YOUR_AUDIOGRAM',
  YOU_CAN_UPDATE_YOUR_AUDIOGRAM: 'YOU_CAN_UPDATE_YOUR_AUDIOGRAM',
  CONFIRM: 'CONFIRM',
  UPLOAD_A_NEW_ONE: 'UPLOAD_A_NEW_ONE',
  PX64: 'PX64',
  PX65: 'PX65',
  PLEASE_PROVIDE_YOUR_CONTACT_IN: 'PLEASE_PROVIDE_YOUR_CONTACT_IN',
  BEFORE_CONTINUING_PLEASE_ENSU: 'BEFORE_CONTINUING_PLEASE_ENSU',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  AND: 'AND',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  ORKA_RESPECTS_YOUR_PRIVACY: 'ORKA_RESPECTS_YOUR_PRIVACY',
  SCHEDULE_NOW1: 'SCHEDULE_NOW1',
  PHONE_NUMBER2: 'PHONE_NUMBER2',
  YES_SIGN_ME_UP_FOR_OCCASIONAL: 'YES_SIGN_ME_UP_FOR_OCCASIONAL',
  BEFORE_CONTINUING_PLEASE_ENSU1: 'BEFORE_CONTINUING_PLEASE_ENSU1',
  ORKA_RESPECTS_YOUR_PRIVACY1: 'ORKA_RESPECTS_YOUR_PRIVACY1',
  PX66: 'PX66',
  _43: '_43',
  PX67: 'PX67',
  PX68: 'PX68',
  PX69: 'PX69',
  REM1: 'REM1',
  REM2: 'REM2',
  LEFT1: 'LEFT1',
  PLEASE_SELECT_YOUR_HEARING_CON: 'PLEASE_SELECT_YOUR_HEARING_CON',
  FIRST_VISIT_MIN: 'FIRST_VISIT_MIN',
  RETURNING_CUSTOMER_MIN: 'RETURNING_CUSTOMER_MIN',
  VH1: 'VH1',
  VH2: 'VH2',
  BODY: 'BODY',
  VW: 'VW',
  MD: 'MD',
  SUCCESS_DIALOG: 'SUCCESS_DIALOG',
  DIALOG_POPCARD: 'DIALOG_POPCARD',
  RGBA1: 'RGBA1',
  CARD: 'CARD',
  SCHEDULE_AN_APPOINTMENT: 'SCHEDULE_AN_APPOINTMENT',
  BLK: 'BLK',
  TIMES: 'TIMES',
  SCHEDULE: 'SCHEDULE',
  VW1: 'VW1',
  VW2: 'VW2',
  PX70: 'PX70',
  PX71: 'PX71',
  PX72: 'PX72',
  PX73: 'PX73',
  PX74: 'PX74',
  PX75: 'PX75',
  VH3: 'VH3',
  F_DB: 'F_DB',
  F_AA_E1: 'F_AA_E1',
  PX76: 'PX76',
  ABSOLUTE: 'ABSOLUTE',
  YOUR_HEARING_CONSULTATION_IS_S: 'YOUR_HEARING_CONSULTATION_IS_S',
  WHICH_STATE_DO_YOU_LIVE_IN: 'WHICH_STATE_DO_YOU_LIVE_IN',
  AS_WE_NEED_TO_KNOW_THE_EXACT_T: 'AS_WE_NEED_TO_KNOW_THE_EXACT_T',
  I_AGREE_TO_THE_TERMS_OF_SERVIC: 'I_AGREE_TO_THE_TERMS_OF_SERVIC',
  AGREEMENT: 'AGREEMENT',
  TIMESHEET: 'TIMESHEET',
  YOU_DID_NOT_AGREE_TO_OUR_PRIVA: 'YOU_DID_NOT_AGREE_TO_OUR_PRIVA',
  ALABAMA: 'ALABAMA',
  ALASKA: 'ALASKA',
  ARIZONA: 'ARIZONA',
  ARKANSAS: 'ARKANSAS',
  CALIFORNIA: 'CALIFORNIA',
  COLORADO: 'COLORADO',
  CONNECTICUT: 'CONNECTICUT',
  DELAWARE: 'DELAWARE',
  FLORIDA: 'FLORIDA',
  GEORGIA: 'GEORGIA',
  HAWAII: 'HAWAII',
  IDAHO: 'IDAHO',
  ILLINOIS: 'ILLINOIS',
  INDIANA: 'INDIANA',
  IOWA: 'IOWA',
  KANSAS: 'KANSAS',
  KENTUCKY: 'KENTUCKY',
  LOUISIANA: 'LOUISIANA',
  MAINE: 'MAINE',
  MARYLAND: 'MARYLAND',
  MASSACHUSETTS: 'MASSACHUSETTS',
  MICHIGAN: 'MICHIGAN',
  MINNESOTA: 'MINNESOTA',
  MISSISSIPPI: 'MISSISSIPPI',
  MISSOURI: 'MISSOURI',
  MONTANA: 'MONTANA',
  NEBRASKA: 'NEBRASKA',
  NEVADA: 'NEVADA',
  NEW_HAMPSHIRE: 'NEW_HAMPSHIRE',
  NEW_JERSEY: 'NEW_JERSEY',
  NEW_MEXICO: 'NEW_MEXICO',
  NEW_YORK: 'NEW_YORK',
  NORTH_CAROLINA: 'NORTH_CAROLINA',
  NORTH_DAKOTA: 'NORTH_DAKOTA',
  OHIO: 'OHIO',
  OKLAHOMA: 'OKLAHOMA',
  OREGON: 'OREGON',
  PENNSYLVANIA: 'PENNSYLVANIA',
  RHODE_ISLAND: 'RHODE_ISLAND',
  SOUTH_CAROLINA: 'SOUTH_CAROLINA',
  SOUTH_DAKOTA: 'SOUTH_DAKOTA',
  TENNESSEE: 'TENNESSEE',
  TEXAS: 'TEXAS',
  UTAH: 'UTAH',
  VERMONT: 'VERMONT',
  VIRGINIA: 'VIRGINIA',
  WASHINGTON: 'WASHINGTON',
  WEST_VIRGINIA: 'WEST_VIRGINIA',
  WISCONSIN: 'WISCONSIN',
  WYOMING: 'WYOMING',
  SCHEDULE_YOUR_HEARING_CONSULTA: 'SCHEDULE_YOUR_HEARING_CONSULTA',
  PICK_A_DATE1: 'PICK_A_DATE1',
  MONTH: 'MONTH',
  DAYS: 'DAYS',
  YOUR_HEARING_CONSULTATION_WILL: 'YOUR_HEARING_CONSULTATION_WILL',
  OUR_HEARING_AIDS_AND_HOW_WE_CA: 'OUR_HEARING_AIDS_AND_HOW_WE_CA',
  PICK_A_SLOT: 'PICK_A_SLOT',
  YYYY_MM_DD_HH_MM_A: 'YYYY_MM_DD_HH_MM_A',
  PX77: 'PX77',
  PX78: 'PX78',
  PX79: 'PX79',
  PX80: 'PX80',
  PLEASE_UPLOAD_YOUR_AUDIOGRAM_H: 'PLEASE_UPLOAD_YOUR_AUDIOGRAM_H',
  YOUR_AUDIOLOGIST_NEED_TO_KNOW: 'YOUR_AUDIOLOGIST_NEED_TO_KNOW',
  ADVICE_ON_NEXT_STEPS_PLEASE_U: 'ADVICE_ON_NEXT_STEPS_PLEASE_U',
  UPLOAD_AUDIOGRAM2: 'UPLOAD_AUDIOGRAM2',
  UPDATE_LATER: 'UPDATE_LATER',
  BOOK_A_CALL: 'BOOK_A_CALL',
  TRANSPARENT: 'TRANSPARENT',
  HOVER: 'HOVER',
  DONE: 'DONE',
  WANNA_FREE_SUPPLIES_LEAVE_YOU: 'WANNA_FREE_SUPPLIES_LEAVE_YOU',
  FREE_SUPPLY_GIFTS: 'FREE_SUPPLY_GIFTS',
  FREE_TIPS_WAX_GUARDS_AND_BRUS: 'FREE_TIPS_WAX_GUARDS_AND_BRUS',
  LEAVE_YOUR_SHIPPING_ADDRESS_HE: 'LEAVE_YOUR_SHIPPING_ADDRESS_HE',
  _44: '_44',
  _45: '_45',
  _46: '_46',
  PX81: 'PX81',
  PX82: 'PX82',
  PX83: 'PX83',
  PX_PX_PX_PX2: 'PX_PX_PX_PX2',
  LINEAR_GRADIENT_TO_RIGHT_F_A: 'LINEAR_GRADIENT_TO_RIGHT_F_A',
  APT_SUIT_REQUIRED_FIELD: 'APT_SUIT_REQUIRED_FIELD',
  STREET_ADDRESS_REQUIRED_FIELD: 'STREET_ADDRESS_REQUIRED_FIELD',
  CITY_REQUIRED_FIELD: 'CITY_REQUIRED_FIELD',
  PROVINCE_REQUIRED_FIELD: 'PROVINCE_REQUIRED_FIELD',
  POSTAL_CODE_REQUIRED_FIELD: 'POSTAL_CODE_REQUIRED_FIELD',
  POSTAL_CODE_INVALID_POSTAL_CO: 'POSTAL_CODE_INVALID_POSTAL_CO',
  PHONE_REQUIRED_FIELD: 'PHONE_REQUIRED_FIELD',
  PHONE_PLEASE_PROVIDE_A_VALID: 'PHONE_PLEASE_PROVIDE_A_VALID',
  NEXT: 'NEXT',
  PX84: 'PX84',
  HEART: 'HEART',
  THANK_YOU: 'THANK_YOU',
  THANKS: 'THANKS',
  PX85: 'PX85',
  VW3: 'VW3',
  PX86: 'PX86',
  YOU_HAVE_NOT_COMPLET: 'YOU_HAVE_NOT_COMPLET',
  REM3: 'REM3',
  F1: 'F1',
  FALSE: 'FALSE',
  NOT_AT_ALL_LIKELY: 'NOT_AT_ALL_LIKELY',
  EXTREMELY_LIKELY: 'EXTREMELY_LIKELY',
  YOU_HAVE_NOT_COMPL: 'YOU_HAVE_NOT_COMPL',
  PX87: 'PX87',
  PX88: 'PX88',
  WE_LL_SEND_THE_SUPPLIES_TO_YOU: 'WE_LL_SEND_THE_SUPPLIES_TO_YOU',
  USAGE_GIFT: 'USAGE_GIFT',
  USAGE_RECEIVED: 'USAGE_RECEIVED',
  YOUR_COUPON_CODE_IS_READY_AND: 'YOUR_COUPON_CODE_IS_READY_AND',
  IT_S_A_GIFT_FORWARD_THIS_SURV: 'IT_S_A_GIFT_FORWARD_THIS_SURV',
  ASK_THEM_TO_COMPLETE_THE_SURVE: 'ASK_THEM_TO_COMPLETE_THE_SURVE',
  YOUR_FREE_SUPPLIES_ARE_ON_THE: 'YOUR_FREE_SUPPLIES_ARE_ON_THE',
  THE_FEEDBACK_ON_HEARING_AID_US: 'THE_FEEDBACK_ON_HEARING_AID_US',
  SHARE_VIA: 'SHARE_VIA',
  SRC_IMGS_FEEDBACK: 'SRC_IMGS_FEEDBACK',
  PNG: 'PNG',
  OR_SHARE_THE_LINK_NOW: 'OR_SHARE_THE_LINK_NOW',
  HTTPS_WWW_HIORKA_CA_FEEDBACK: 'HTTPS_WWW_HIORKA_CA_FEEDBACK',
  LINK_COPIED: 'LINK_COPIED',
  SALES_AND_REFUND_POLICY: 'SALES_AND_REFUND_POLICY',
  COPYRIGHT_ORKA_LABS_INC: 'COPYRIGHT_ORKA_LABS_INC',
  _47: '_47',
  HTTPS_WWW_FACEBOOK_COM_LOGIN: 'HTTPS_WWW_FACEBOOK_COM_LOGIN',
  HTTPS_TWITTER_COM_ORKAHEARIN: 'HTTPS_TWITTER_COM_ORKAHEARIN',
  HTTPS_WWW_LINKEDIN_COM_COMPA: 'HTTPS_WWW_LINKEDIN_COM_COMPA',
  A_LETTER_FROM_OUR_FOUNDER1: 'A_LETTER_FROM_OUR_FOUNDER1',
  INVITATION_TO_JOIN_US_AT_GROUN: 'INVITATION_TO_JOIN_US_AT_GROUN',
  CHECK_IT_NOW: 'CHECK_IT_NOW',
  HOW_ORKA_INSIDER_PROGRAM_WORKS: 'HOW_ORKA_INSIDER_PROGRAM_WORKS',
  VW4: 'VW4',
  CALC_PX2: 'CALC_PX2',
  PX89: 'PX89',
  _48: '_48',
  BLCAK: 'BLCAK',
  F_F_F7: 'F_F_F7',
  HTTPS_CA_HIORKA_STORAGE_S_C: 'HTTPS_CA_HIORKA_STORAGE_S_C',
  VIDEO_MP_CODECS_AVC_E_E: 'VIDEO_MP_CODECS_AVC_E_E',
  SIMPLE_HEARING_SOLUTION_YOU_CA: 'SIMPLE_HEARING_SOLUTION_YOU_CA',
  PX90: 'PX90',
  _49: '_49',
  A_A_A1: 'A_A_A1',
  LINEAR_GRADIENT_TO_BOTTOM_RGBA: 'LINEAR_GRADIENT_TO_BOTTOM_RGBA',
  _50: '_50',
  EXCEPTIONAL_SPEECH_PNG: 'EXCEPTIONAL_SPEECH_PNG',
  BLUETOOTH_PNG: 'BLUETOOTH_PNG',
  FITTING_ONLINE_PNG: 'FITTING_ONLINE_PNG',
  AFFORDABLE_PRICE_PNG: 'AFFORDABLE_PRICE_PNG',
  BETTER_HEARING_FOR_YOUR_WAY_OF: 'BETTER_HEARING_FOR_YOUR_WAY_OF',
  GET_STARTED: 'GET_STARTED',
  I_AGREE_TO_THE_ORKA_S: 'I_AGREE_TO_THE_ORKA_S',
  TERMS_OF_SERVICE1: 'TERMS_OF_SERVICE1',
  THINK_YOU_HAVE_HEARING_LOSS: 'THINK_YOU_HAVE_HEARING_LOSS',
  TAKE_A_QUICK_SURVEY_TO_SEE_IF: 'TAKE_A_QUICK_SURVEY_TO_SEE_IF',
  TAKE_A_QUIZ: 'TAKE_A_QUIZ',
  WHITE_IMPORTANT: 'WHITE_IMPORTANT',
  PLEASE_AGREE_TO_OUR_TERMS_OF_S: 'PLEASE_AGREE_TO_OUR_TERMS_OF_S',
  INVALID_EMAIL_ADDRESS: 'INVALID_EMAIL_ADDRESS',
  EXPERIENCE_HOW_ORKA_S_WILL_HEL: 'EXPERIENCE_HOW_ORKA_S_WILL_HEL',
  SURVEYPAGE: 'SURVEYPAGE',
  HTTPS_CA_HIORKA_STORAGE_S_C1: 'HTTPS_CA_HIORKA_STORAGE_S_C1',
  _51: '_51',
  MEET_ORKA_ONE: 'MEET_ORKA_ONE',
  SHOP_LIST: 'SHOP_LIST',
  PURCHASE_ORKA_ONE: 'PURCHASE_ORKA_ONE',
  FREE_HOME_TRY_ON: 'FREE_HOME_TRY_ON',
  CONTACT: 'CONTACT',
  HOVER1: 'HOVER1',
  EMAIL2: 'EMAIL2',
  CA_SUPPORT_HIORKA_COM: 'CA_SUPPORT_HIORKA_COM',
  PHONE: 'PHONE',
  _52: '_52',
  ORKA_LABS_INC: 'ORKA_LABS_INC',
  S_WACKER_DRIVE_SUITE: 'S_WACKER_DRIVE_SUITE',
  CHICAGO_IL: 'CHICAGO_IL',
  ACCOUNT: 'ACCOUNT',
  LOG_IN: 'LOG_IN',
  TRY_FREE_HOME: 'TRY_FREE_HOME',
  FREE_TRY_ON: 'FREE_TRY_ON',
  ORKA_LOGO: 'ORKA_LOGO',
  PX91: 'PX91',
  CONTACT_IMG: 'CONTACT_IMG',
  _53: '_53',
  _54: '_54',
  _55: '_55',
  _56: '_56',
  _57: '_57',
  START_TEST: 'START_TEST',
  H2: 'H2',
  BEFORE_YOU_BEGIN_YOUR_HEARING: 'BEFORE_YOU_BEGIN_YOUR_HEARING',
  OUTLINED: 'OUTLINED',
  PRESURVEY: 'PRESURVEY',
  LET_S_START: 'LET_S_START',
  PLEASE_SET_YOUR_VOLUME_TO_A_CO: 'PLEASE_SET_YOUR_VOLUME_TO_A_CO',
  SUBTITLE: 'SUBTITLE',
  TO_GET_THE_BEST_RESULTS: 'TO_GET_THE_BEST_RESULTS',
  IF_YOU_USE_APPLE_AIRPODS_OR_E: 'IF_YOU_USE_APPLE_AIRPODS_OR_E',
  MAXIMUM: 'MAXIMUM',
  IF_YOU_USE_SPEAKERS_OR_HEADPH: 'IF_YOU_USE_SPEAKERS_OR_HEADPH',
  A_MAXIMUM_COMFORTABLE_LEVEL: 'A_MAXIMUM_COMFORTABLE_LEVEL',
  IMAGE: 'IMAGE',
  CONTAIN: 'CONTAIN',
  IMAGE1: 'IMAGE1',
  DONE1: 'DONE1',
  NEXT1: 'NEXT1',
  START1: 'START1',
  PRESS: 'PRESS',
  VIEW_RESULTS: 'VIEW_RESULTS',
  PX92: 'PX92',
  PX93: 'PX93',
  _58: '_58',
  VH4: 'VH4',
  PX94: 'PX94',
  PX95: 'PX95',
  VH5: 'VH5',
  K: 'K',
  K1: 'K1',
  K2: 'K2',
  K3: 'K3',
  _59: '_59',
  _60: '_60',
  UP_TO: 'UP_TO',
  _61: '_61',
  _62: '_62',
  _63: '_63',
  REM4: 'REM4',
  REM5: 'REM5',
  REM6: 'REM6',
  REM7: 'REM7',
  REM8: 'REM8',
  REM9: 'REM9',
  REM10: 'REM10',
  REM11: 'REM11',
  REM12: 'REM12',
  REM13: 'REM13',
  REM14: 'REM14',
  REM15: 'REM15',
  REM16: 'REM16',
  REM17: 'REM17',
  REM18: 'REM18',
  FFF: 'FFF',
  _64: '_64',
  HOURS: 'HOURS',
  OF_USING_TIME_WITH_MULTIPLE_CH: 'OF_USING_TIME_WITH_MULTIPLE_CH',
  ORKA_ONE_ALL_IN_ONE: 'ORKA_ONE_ALL_IN_ONE',
  THE_WHOLE_WORLD_IN_YOUR_EARS_A: 'THE_WHOLE_WORLD_IN_YOUR_EARS_A',
  CUTTING_EDGE_AI_DENOISE_TECHNO: 'CUTTING_EDGE_AI_DENOISE_TECHNO',
  ORKA_ONE_IS_EQUIPPED_WITH_ADVA: 'ORKA_ONE_IS_EQUIPPED_WITH_ADVA',
  INSTANT_NOISE_REDUCTION_ANYTI: 'INSTANT_NOISE_REDUCTION_ANYTI',
  ORKA_ONE_FILTERS_OUT_BACKGROUN: 'ORKA_ONE_FILTERS_OUT_BACKGROUN',
  TUNE_INTO_THE_WORLD: 'TUNE_INTO_THE_WORLD',
  HEAR_WHAT_MATTERS_AND_BE_AT_E: 'HEAR_WHAT_MATTERS_AND_BE_AT_E',
  SPRINKLE_SOME_CREATIVITY_INTO: 'SPRINKLE_SOME_CREATIVITY_INTO',
  THE_LIGHTWEIGHT_YET_COMPACT_DE: 'THE_LIGHTWEIGHT_YET_COMPACT_DE',
  BLUETOOTH: 'BLUETOOTH',
  CONNECT_WIRELESSLY_TO_YOUR_IOS: 'CONNECT_WIRELESSLY_TO_YOUR_IOS',
  EFFORTLESS_HEARING_EXPERIENCE: 'EFFORTLESS_HEARING_EXPERIENCE',
  WHEN_A_PHONE_CALL_COMES_IN_OR: 'WHEN_A_PHONE_CALL_COMES_IN_OR',
  HANDS_FREE_CALLING: 'HANDS_FREE_CALLING',
  GET_A_PHONE_CALL_SIMPLY_DOUBL: 'GET_A_PHONE_CALL_SIMPLY_DOUBL',
  ORKA_HEALTH_APP: 'ORKA_HEALTH_APP',
  CONTROL_THE_DEVICES_REMOTELY: 'CONTROL_THE_DEVICES_REMOTELY',
  EASY_HEARING_ALL_DAY_LONG: 'EASY_HEARING_ALL_DAY_LONG',
  HASSLE_FREE_WITH_RECHARGEABLE: 'HASSLE_FREE_WITH_RECHARGEABLE',
  _65: '_65',
  MINS: 'MINS',
  OF_AI_DENOISE_HEARING: 'OF_AI_DENOISE_HEARING',
  ON_A_SINGLE_CHARGE: 'ON_A_SINGLE_CHARGE',
  OF_CONTINUOUS_HEARING: 'OF_CONTINUOUS_HEARING',
  WITH_CHARGING_CASE: 'WITH_CHARGING_CASE',
  FAST_CHARGING: 'FAST_CHARGING',
  KEEP_SCROLLING: 'KEEP_SCROLLING',
  CUTTING_EDGE_AI_DENOISE_TECHNO1: 'CUTTING_EDGE_AI_DENOISE_TECHNO1',
  ORKA_ONE_IS_EQUIPPED_WITH: 'ORKA_ONE_IS_EQUIPPED_WITH',
  ADVANCED_MACHINE_LEARNING_ALGO: 'ADVANCED_MACHINE_LEARNING_ALGO',
  AND_REFINED_SOUND_PROCESSING_S: 'AND_REFINED_SOUND_PROCESSING_S',
  MELDIOUS_SOUNDS: 'MELDIOUS_SOUNDS',
  INTO_YOUR_EARS: 'INTO_YOUR_EARS',
  ORKA_ONE_FILTERS_OUT_BACKGROUN1: 'ORKA_ONE_FILTERS_OUT_BACKGROUN1',
  SPRINGKLE_SOME_CREATIVITY_INTO: 'SPRINGKLE_SOME_CREATIVITY_INTO',
  WHEN_A_PHONE_CALL_COMES_IN_OR1: 'WHEN_A_PHONE_CALL_COMES_IN_OR1',
  GET_A_PHONE_CALL_SIMPLY_DOUBL1: 'GET_A_PHONE_CALL_SIMPLY_DOUBL1',
  CONTROL_THE_DEVICES_REMOTELY1: 'CONTROL_THE_DEVICES_REMOTELY1',
  DOWNLOAD_THE_FREE: 'DOWNLOAD_THE_FREE',
  ORKA_HEALTH_APP1: 'ORKA_HEALTH_APP1',
  NOW: 'NOW',
  CURRENT: 'CURRENT',
  PRE: 'PRE',
  POST: 'POST',
  BUY: 'BUY',
  AI_DENOISE: 'AI_DENOISE',
  DESIGN: 'DESIGN',
  CHARGING_CASE: 'CHARGING_CASE',
  _66: '_66',
  _67: '_67',
  HEARING_AIDS: 'HEARING_AIDS',
  RGBA2: 'RGBA2',
  RGBA3: 'RGBA3',
  REM19: 'REM19',
  REM20: 'REM20',
  REM21: 'REM21',
  PX_PX_PX_PX3: 'PX_PX_PX_PX3',
  PX_PX_PX_PX4: 'PX_PX_PX_PX4',
  FAST_CHARGING1: 'FAST_CHARGING1',
  PX96: 'PX96',
  FOOT: 'FOOT',
  LETTERDIV: 'LETTERDIV',
  RECHARGE: 'RECHARGE',
  _68: '_68',
  PX97: 'PX97',
  ROW_REVERSE: 'ROW_REVERSE',
  VH6: 'VH6',
  CALC_VH_PX: 'CALC_VH_PX',
  VH7: 'VH7',
  PX98: 'PX98',
  TRANSLATEY: 'TRANSLATEY',
  TRANSLATEY1: 'TRANSLATEY1',
  RELATIVE: 'RELATIVE',
  PX99: 'PX99',
  PX100: 'PX100',
  PX101: 'PX101',
  PX_PX11: 'PX_PX11',
  PX_PX_PX_PX5: 'PX_PX_PX_PX5',
  _69: '_69',
  ORKA_MESSAGE_BOARD: 'ORKA_MESSAGE_BOARD',
  CLICK_TO_SEE_IF_OTHER_PEOPLE_S: 'CLICK_TO_SEE_IF_OTHER_PEOPLE_S',
  CHOOSING_THE_RIGHT_HEARING_AID: 'CHOOSING_THE_RIGHT_HEARING_AID',
  JOIN_THE_CONVERSATION: 'JOIN_THE_CONVERSATION',
  PX_PX12: 'PX_PX12',
  VH_VW: 'VH_VW',
  _70: '_70',
  ALL_IN_ONE_HEARING_SOLUTION: 'ALL_IN_ONE_HEARING_SOLUTION',
  MORE_FUNCTIONS_MORE_FUN: 'MORE_FUNCTIONS_MORE_FUN',
  BURDEN_FREE_PURCHASE: 'BURDEN_FREE_PURCHASE',
  REMOTE_AUDIOLOGY_CARE: 'REMOTE_AUDIOLOGY_CARE',
  POWERED_BY_AI_DENOISE_TECHNOLO: 'POWERED_BY_AI_DENOISE_TECHNOLO',
  DAY_MONEY_BACK_GUARANTEE: 'DAY_MONEY_BACK_GUARANTEE',
  ALL_IN_ONE_HEARING_SOLUTION_FO: 'ALL_IN_ONE_HEARING_SOLUTION_FO',
  PLAY_MORE_FUNCTION_ON_ORKA_AND: 'PLAY_MORE_FUNCTION_ON_ORKA_AND',
  MONEY_BACK_GUARANTEE_IN_DAY: 'MONEY_BACK_GUARANTEE_IN_DAY',
  THE_HEARING_AIDS_FOR_YOUR_EVER: 'THE_HEARING_AIDS_FOR_YOUR_EVER',
  GET_YOUR_AWARD_WINNING_HEARING: 'GET_YOUR_AWARD_WINNING_HEARING',
  TRY_NOW_PLAY_LATER: 'TRY_NOW_PLAY_LATER',
  FREE_TRY_ON_SUB_TITLE: 'FREE_TRY_ON_SUB_TITLE',
  PRE_ORDER: 'PRE_ORDER',
  _71: '_71',
  _72: '_72',
  NOW_LOADING: 'NOW_LOADING',
  PX102: 'PX102',
  FIXED: 'FIXED',
  _73: '_73',
  _74: '_74',
  PX103: 'PX103',
  PX104: 'PX104',
  PX105: 'PX105',
  _75: '_75',
  _76: '_76',
  PX106: 'PX106',
  PX107: 'PX107',
  PX108: 'PX108',
  _77: '_77',
  PUT_DOWN: 'PUT_DOWN',
  VOUCHER_RECEIVED: 'VOUCHER_RECEIVED',
  FINAL_PAYMENT: 'FINAL_PAYMENT',
  ESTIMATED_MAR: 'ESTIMATED_MAR',
  PRODUCT_SHIPPED: 'PRODUCT_SHIPPED',
  START_YOUR_ORKA_JOURNEY: 'START_YOUR_ORKA_JOURNEY',
  HOW_IT_WORKS: 'HOW_IT_WORKS',
  COMING_IN_MARCH: 'COMING_IN_MARCH',
  PUT_DOWN_NOW: 'PUT_DOWN_NOW',
  _78: '_78',
  COMMON_QUESTIONS: 'COMMON_QUESTIONS',
  SEE_MORE: 'SEE_MORE',
  _79: '_79',
  _80: '_80',
  _81: '_81',
  CAN_I_CANCEL_MY_PRE_ORDER: 'CAN_I_CANCEL_MY_PRE_ORDER',
  HOW_DO_I_APPLY_THE_VOU: 'HOW_DO_I_APPLY_THE_VOU',
  HOW_DOES_THE_DAY_MONEY_BACK: 'HOW_DOES_THE_DAY_MONEY_BACK',
  HOW_MUCH_IS_ORKA_ONE_IF_I_JOIN: 'HOW_MUCH_IS_ORKA_ONE_IF_I_JOIN',
  ARE_ORKA_ONE_HEARING_AIDS_FDA: 'ARE_ORKA_ONE_HEARING_AIDS_FDA',
  IS_ORKA_ONE_THE_RIGHT_PRODUCT: 'IS_ORKA_ONE_THE_RIGHT_PRODUCT',
  YES_THE_PRE_ORDER_DEPO: 'YES_THE_PRE_ORDER_DEPO',
  THE_VOUCHER_WILL_AUTOM: 'THE_VOUCHER_WILL_AUTOM',
  OUR_GOAL_IS_TO_MAKE_SURE_THAT: 'OUR_GOAL_IS_TO_MAKE_SURE_THAT',
  THE_FULL_PAYMENT_OF_ORKA_ONE_I: 'THE_FULL_PAYMENT_OF_ORKA_ONE_I',
  YES_ORKA_ONE_HEARING_AIDS_ARE: 'YES_ORKA_ONE_HEARING_AIDS_ARE',
  ORKA_ONE_IS_DESIGNED_TO_SERVE: 'ORKA_ONE_IS_DESIGNED_TO_SERVE',
  FINAL_PAYMENT_ESTIMATED_MAR: 'FINAL_PAYMENT_ESTIMATED_MAR',
  PRODUCT_SHIPPED_ESTIMATED_MAR: 'PRODUCT_SHIPPED_ESTIMATED_MAR',
  COMING_IN: 'COMING_IN',
  MARCH1: 'MARCH1',
  _82: '_82',
  PX_PX_PX_PX6: 'PX_PX_PX_PX6',
  DISABLE: 'DISABLE',
  _83: '_83',
  WHAT_S_NEXT: 'WHAT_S_NEXT',
  DAY_HOME_TRY_ON: 'DAY_HOME_TRY_ON',
  ORKA_ONE_JOURNEY: 'ORKA_ONE_JOURNEY',
  WE_RE_PREPPING_YOUR_HEARING_AI: 'WE_RE_PREPPING_YOUR_HEARING_AI',
  DAY_FREE_TRIAL: 'DAY_FREE_TRIAL',
  WILL_START_THE_DAY_A: 'WILL_START_THE_DAY_A',
  YOUR_OPINION_MATTERS_PLEASE_S: 'YOUR_OPINION_MATTERS_PLEASE_S',
  AS_A_THANK_YOU_GIFT_WE_WILL_S: 'AS_A_THANK_YOU_GIFT_WE_WILL_S',
  _84: '_84',
  _85: '_85',
  GUESTCHECKOUT: 'GUESTCHECKOUT',
  ALREADY_HAVE_AN_ACCOUNT: 'ALREADY_HAVE_AN_ACCOUNT',
  LOG_IN1: 'LOG_IN1',
  HIDDEN: 'HIDDEN',
  WHAT_S_YOUR_SHIPPING_ADDRESS: 'WHAT_S_YOUR_SHIPPING_ADDRESS',
  NEXT_CARD_VALIDATION: 'NEXT_CARD_VALIDATION',
  CONTINUE_TO_PAYMENT: 'CONTINUE_TO_PAYMENT',
  FIRSTNAME_REQUIRED_FIELD: 'FIRSTNAME_REQUIRED_FIELD',
  LASTNAME_REQUIRED_FIELD: 'LASTNAME_REQUIRED_FIELD',
  GUEST_CHECKOUT: 'GUEST_CHECKOUT',
  WE_NEED_YOUR_EMAIL_ADDRESS_TO: 'WE_NEED_YOUR_EMAIL_ADDRESS_TO',
  CONTINUE_TO_DELIVERY: 'CONTINUE_TO_DELIVERY',
  THIS_EMAIL_IS_ALREADY_TAKEN: 'THIS_EMAIL_IS_ALREADY_TAKEN',
  PLEASE_LOG_IN_OR_USE_THIS_EMAI: 'PLEASE_LOG_IN_OR_USE_THIS_EMAI',
  CONTINUE_AS_A_GUEST: 'CONTINUE_AS_A_GUEST',
  SIGN_IN: 'SIGN_IN',
  VW5: 'VW5',
  PX109: 'PX109',
  _86: '_86',
  SIGN_IN_TO_ORKA: 'SIGN_IN_TO_ORKA',
  TEXT: 'TEXT',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CHECK_OUT_AS_A_GUEST: 'CHECK_OUT_AS_A_GUEST',
  PX110: 'PX110',
  EMPTY_EMAIL_ADDRESS_REQUIRED: 'EMPTY_EMAIL_ADDRESS_REQUIRED',
  INVALID_EMAIL_ADDRESS1: 'INVALID_EMAIL_ADDRESS1',
  MUST_BE_CHARACTERS_OR_MORE: 'MUST_BE_CHARACTERS_OR_MORE',
  YOU_VE_LOGGED_IN: 'YOU_VE_LOGGED_IN',
  READY_TO_PLACE_YOUR_ORDER: 'READY_TO_PLACE_YOUR_ORDER',
  LET_S_MAKE_SURE_EVERYTHING_S_R: 'LET_S_MAKE_SURE_EVERYTHING_S_R',
  PER_PAIR: 'PER_PAIR',
  _87: '_87',
  DAY_MONEY_BACK_GUARANTEE1: 'DAY_MONEY_BACK_GUARANTEE1',
  MONTH_LIMITED_WARRANTY: 'MONTH_LIMITED_WARRANTY',
  VISA_CARD: 'VISA_CARD',
  _88: '_88',
  GRAND_TOTAL: 'GRAND_TOTAL',
  _89: '_89',
  PLACE_YOUR_FREE_ORDER: 'PLACE_YOUR_FREE_ORDER',
  PLACE_YOUR_ORDER: 'PLACE_YOUR_ORDER',
  BY_PLACING_YOUR_ORDER_YOU_AGR: 'BY_PLACING_YOUR_ORDER_YOU_AGR',
  PRIVACY_POLICY1: 'PRIVACY_POLICY1',
  PX111: 'PX111',
  PX112: 'PX112',
  PX113: 'PX113',
  PX_SOLID_F1: 'PX_SOLID_F1',
  WE_RE_SORRY_WE_COULDN_T_COMPL: 'WE_RE_SORRY_WE_COULDN_T_COMPL',
  THE_EXPECTED_TIME_OF_DEPARTURE: 'THE_EXPECTED_TIME_OF_DEPARTURE',
  AND_THE: 'AND_THE',
  ORKA_ONE_DAY_FREE_HOME_TRY: 'ORKA_ONE_DAY_FREE_HOME_TRY',
  APPLY: 'APPLY',
  NEXT_STEP: 'NEXT_STEP',
  NEXT_STEP_IT_S_FREE: 'NEXT_STEP_IT_S_FREE',
  PX114: 'PX114',
  PX115: 'PX115',
  PX116: 'PX116',
  PX117: 'PX117',
  MIDDLE: 'MIDDLE',
  ADD_CARD_INFO_TO_CONFIRM_YOUR: 'ADD_CARD_INFO_TO_CONFIRM_YOUR',
  WE_REQUIRE_A_VALID_CREDIT_OR_D: 'WE_REQUIRE_A_VALID_CREDIT_OR_D',
  PX118: 'PX118',
  YOUR_HOME_TRY_ON_IS_IN_PROCESS: 'YOUR_HOME_TRY_ON_IS_IN_PROCESS',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  PURCHASE_SUCCESSFUL: 'PURCHASE_SUCCESSFUL',
  YOUR_ORDER_CONFIRMATION_HAS_BE: 'YOUR_ORDER_CONFIRMATION_HAS_BE',
  NOTE: 'NOTE',
  WE_RE_PREPPING_YOUR_HEARING_AI1: 'WE_RE_PREPPING_YOUR_HEARING_AI1',
  THANK_YOU_FOR_COMPLETING_THE_O: 'THANK_YOU_FOR_COMPLETING_THE_O',
  PLEASE_REMEMBER_THAT_ORKA_SUPP: 'PLEASE_REMEMBER_THAT_ORKA_SUPP',
  VIEW_MY_ORDER_WITH_ACCOUNT: 'VIEW_MY_ORDER_WITH_ACCOUNT',
  H3: 'H3',
  DON_T_HAVE_AN_ACCOUNT_WITH_US: 'DON_T_HAVE_AN_ACCOUNT_WITH_US',
  THIS_ACCOUNT_WILL_BE_USED_TO_A: 'THIS_ACCOUNT_WILL_BE_USED_TO_A',
  SET_YOUR_PASSWORD_BELOW: 'SET_YOUR_PASSWORD_BELOW',
  END: 'END',
  TOGGLE_PASSWORD_VISIBILITY: 'TOGGLE_PASSWORD_VISIBILITY',
  LARGE: 'LARGE',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  CONFIRMPASSWORD: 'CONFIRMPASSWORD',
  CONFIRM_PASSWORD_MUST_BE_CH: 'CONFIRM_PASSWORD_MUST_BE_CH',
  TOGGLE_CONFIRM_PASSWORD_VISIBI: 'TOGGLE_CONFIRM_PASSWORD_VISIBI',
  CONFIRM_PASSWORD1: 'CONFIRM_PASSWORD1',
  YOUR_ACCOUNT_WILL_BE_CREATED_W: 'YOUR_ACCOUNT_WILL_BE_CREATED_W',
  TERMS_CONDITIONS: 'TERMS_CONDITIONS',
  BY_CREATING_AN_ACCOUNT_I_AGRE: 'BY_CREATING_AN_ACCOUNT_I_AGRE',
  NOOPENER_NOREFERRER: 'NOOPENER_NOREFERRER',
  OVERLINE: 'OVERLINE',
  ORKA_S_TERMS_OF_SERVICE: 'ORKA_S_TERMS_OF_SERVICE',
  WARNING_CIRCLE: 'WARNING_CIRCLE',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  CREATE_ACCOUNT1: 'CREATE_ACCOUNT1',
  PX_PX13: 'PX_PX13',
  PX_PX14: 'PX_PX14',
  CONFIRM_PASSWORD_REQUIRED_FIE: 'CONFIRM_PASSWORD_REQUIRED_FIE',
  PLEASE_AGREE_TO_OUR_TERMS_OF_S1: 'PLEASE_AGREE_TO_OUR_TERMS_OF_S1',
  THE_PASSWORDS_DO_NOT_MATCH_PL: 'THE_PASSWORDS_DO_NOT_MATCH_PL',
  MY_BILLING_ADDRESS_IS_THE_SAME: 'MY_BILLING_ADDRESS_IS_THE_SAME',
  SUBTITLE1: 'SUBTITLE1',
  CARD_INFORMATION: 'CARD_INFORMATION',
  BODY1: 'BODY1',
  CARD1: 'CARD1',
  YOU_WILL_NOT_BECHARGED_UNTIL_Y: 'YOU_WILL_NOT_BECHARGED_UNTIL_Y',
  CAPTION: 'CAPTION',
  PX119: 'PX119',
  PX_PX15: 'PX_PX15',
  _90: '_90',
  AAB_C: 'AAB_C',
  E: 'E',
  ACTIVE: 'ACTIVE',
  WAS_THIS_ARTICLE_HELPFUL: 'WAS_THIS_ARTICLE_HELPFUL',
  OUT_OF_FOUND_THIS_HELP: 'OUT_OF_FOUND_THIS_HELP',
  YES2: 'YES2',
  HAVE_MORE_QUESTIONS_CHAT_WITH: 'HAVE_MORE_QUESTIONS_CHAT_WITH',
  PRE_ORDER1: 'PRE_ORDER1',
  ORKA_ONE1: 'ORKA_ONE1',
  NOW_FOR_SHIPPING_IN_FEBRUARY: 'NOW_FOR_SHIPPING_IN_FEBRUARY',
  EARLY_BIRDS_DISCOUNT_AVAILABLE: 'EARLY_BIRDS_DISCOUNT_AVAILABLE',
  PRE_ORDER_NOW: 'PRE_ORDER_NOW',
  PX120: 'PX120',
  CES_INNOVATION_AWARDS: 'CES_INNOVATION_AWARDS',
  CONTENT: 'CONTENT',
  HEADER: 'HEADER',
  COMING_SOON: 'COMING_SOON',
  INSIDERCHECKOUT: 'INSIDERCHECKOUT',
  TECHSPECS: 'TECHSPECS',
  WHO_SHOULD_USE_ORKA_ONE_HEARIN: 'WHO_SHOULD_USE_ORKA_ONE_HEARIN',
  CAN_I_APPLY_FOR_A_REFUND: 'CAN_I_APPLY_FOR_A_REFUND',
  STAY_TUNED: 'STAY_TUNED',
  FOR_MORE_UPDATES: 'FOR_MORE_UPDATES',
  SUBSCRIBE_TO_STAY_UPDATED_WITH: 'SUBSCRIBE_TO_STAY_UPDATED_WITH',
  SIGN_UP: 'SIGN_UP',
  BEFORE_CONTINUING_PLEASE_ENSU2: 'BEFORE_CONTINUING_PLEASE_ENSU2',
  TERMS_OF_SERVICE2: 'TERMS_OF_SERVICE2',
  ORKA_RESPECTS_YOUR_PRIVACY2: 'ORKA_RESPECTS_YOUR_PRIVACY2',
  YOU_HAVE_SUCCESSFULLY_SUBSCRIB: 'YOU_HAVE_SUCCESSFULLY_SUBSCRIB',
  SOON: 'SOON',
  FOLLOW_US_ON_SOCIAL_MEDIA: 'FOLLOW_US_ON_SOCIAL_MEDIA',
  REM22: 'REM22',
  S1: 'S1',
  REM23: 'REM23',
  HTTPS_WWW_FACEBOOK_COM_ORKA: 'HTTPS_WWW_FACEBOOK_COM_ORKA',
  HTTPS_ORKA_MEDIUM_COM: 'HTTPS_ORKA_MEDIUM_COM',
  HTTPS_TWITTER_COM_ORKAHEALTH: 'HTTPS_TWITTER_COM_ORKAHEALTH',
  SUBSCRIBE_TO_STAY_UPDATED_WITH1: 'SUBSCRIBE_TO_STAY_UPDATED_WITH1',
  YOU_HAVE_SUCCESSFULLY_SUBSCRIB1: 'YOU_HAVE_SUCCESSFULLY_SUBSCRIB1',
  REM24: 'REM24',
  PUT_DOWN_NOW1: 'PUT_DOWN_NOW1',
  GET_OFF_YOUR_FINAL_PAY: 'GET_OFF_YOUR_FINAL_PAY',
  NOTIFY_ME: 'NOTIFY_ME',
  IMAGE_JPEG: 'IMAGE_JPEG',
  ORKA_ONE_SLEEK_DESIGN_HEARING: 'ORKA_ONE_SLEEK_DESIGN_HEARING',
  MINS_FAST_CHARGING_HEARING: 'MINS_FAST_CHARGING_HEARING',
  UP_TO_HOURS_CONTINUOUS_HEAR: 'UP_TO_HOURS_CONTINUOUS_HEAR',
  WATERPROOF_CASE_DESIGN_HEARING: 'WATERPROOF_CASE_DESIGN_HEARING',
  SRC_IMGS_PRODUCT_ORKA_ONE: 'SRC_IMGS_PRODUCT_ORKA_ONE',
  PRODUCT_PICTURE: 'PRODUCT_PICTURE',
  REM25: 'REM25',
  PX_SOLID1: 'PX_SOLID1',
  BUY_NOW: 'BUY_NOW',
  FREE_HOME_TRY_ON1: 'FREE_HOME_TRY_ON1',
  CHAT_WITH_US_ONLINE3: 'CHAT_WITH_US_ONLINE3',
  SUPPORTED_PAYMENT_TYPES: 'SUPPORTED_PAYMENT_TYPES',
  PX121: 'PX121',
  REM_REM: 'REM_REM',
  PX_PX_PX4: 'PX_PX_PX4',
  PX_PX16: 'PX_PX16',
  PX122: 'PX122',
  PX_PX_PX5: 'PX_PX_PX5',
  PX123: 'PX123',
  VH8: 'VH8',
  PX124: 'PX124',
  _91: '_91',
  FFE_A: 'FFE_A',
  BOLD: 'BOLD',
  REM26: 'REM26',
  PX125: 'PX125',
  DAY_MONEY_BACK_GUARANTEE2: 'DAY_MONEY_BACK_GUARANTEE2',
  REPLACEMENT_GUARANTEE_UNDER_WA: 'REPLACEMENT_GUARANTEE_UNDER_WA',
  REMOTE_AUDIOLOGY_SERVICES: 'REMOTE_AUDIOLOGY_SERVICES',
  AI_HEARING_AIDS1: 'AI_HEARING_AIDS1',
  PER_PAIR1: 'PER_PAIR1',
  PER_PAIR2: 'PER_PAIR2',
  THE_ACTUAL_HUMAN_CENTERED_HEAR: 'THE_ACTUAL_HUMAN_CENTERED_HEAR',
  WIRELESS_CONNECTION: 'WIRELESS_CONNECTION',
  AI_DENOISE_TECHNOLOGY: 'AI_DENOISE_TECHNOLOGY',
  FDA_REGISTERED_CLASS_II_MEDICA: 'FDA_REGISTERED_CLASS_II_MEDICA',
  INSIDERCHECKOUT_INSIDER: 'INSIDERCHECKOUT_INSIDER',
  MAKE_THE_MOST_OF_ORKA_JOURNEY: 'MAKE_THE_MOST_OF_ORKA_JOURNEY',
  DAY_MONEY_BACK_GUARANTEE3: 'DAY_MONEY_BACK_GUARANTEE3',
  MONTH_LIMITED_WARRANTY1: 'MONTH_LIMITED_WARRANTY1',
  FREE_SHIPPING_FREE_RETURNS: 'FREE_SHIPPING_FREE_RETURNS',
  GET_FULL_REFUND_BACK_ANYTIME_W: 'GET_FULL_REFUND_BACK_ANYTIME_W',
  YOU_WILL_RECEIVE_A_BRAND_NEW_P: 'YOU_WILL_RECEIVE_A_BRAND_NEW_P',
  MAKE_THE_MOST_OF_THE_PRODUCT_B: 'MAKE_THE_MOST_OF_THE_PRODUCT_B',
  ORKA_DAYS_TRIAL: 'ORKA_DAYS_TRIAL',
  ORKA_WARRANTY: 'ORKA_WARRANTY',
  ORKA_REFUND: 'ORKA_REFUND',
  WOMAN_WEARING_ORKA_WITH_BLUETO: 'WOMAN_WEARING_ORKA_WITH_BLUETO',
  CONVERSATIONS_ARE_ABOUT_TO_GET: 'CONVERSATIONS_ARE_ABOUT_TO_GET',
  BUY_NOW1: 'BUY_NOW1',
  TECH_SPECS: 'TECH_SPECS',
  SEE_ALL_FAQS: 'SEE_ALL_FAQS',
  IN_THE_BOX: 'IN_THE_BOX',
  ORKA_ONE_CHARGING_CASE: 'ORKA_ONE_CHARGING_CASE',
  SILICONE_EAR_TIPS_WITH_SIZE: 'SILICONE_EAR_TIPS_WITH_SIZE',
  TYPE_C_CONNECTOR: 'TYPE_C_CONNECTOR',
  USER_QUICK_START_GUIDE: 'USER_QUICK_START_GUIDE',
  COMPATIBILITY: 'COMPATIBILITY',
  COMPATIBLE_WITH_ANY_BLUETOOTH: 'COMPATIBLE_WITH_ANY_BLUETOOTH',
  PX126: 'PX126',
  SIZE_AND_WEIGHT: 'SIZE_AND_WEIGHT',
  OPERATING_SYSTEM: 'OPERATING_SYSTEM',
  AUDIO_TECHNOLOGY: 'AUDIO_TECHNOLOGY',
  SENSORS: 'SENSORS',
  CONTROLS: 'CONTROLS',
  SWEAT_AND_WATER_RESISTANT: 'SWEAT_AND_WATER_RESISTANT',
  BATTERY: 'BATTERY',
  CHARGING_CASE1: 'CHARGING_CASE1',
  CONNECTIVITY: 'CONNECTIVITY',
  MENTIONS_REVIEWS: 'MENTIONS_REVIEWS',
  HEARING_AIDS_ARE_NOT_NEW_TECH: 'HEARING_AIDS_ARE_NOT_NEW_TECH',
  MAKEUSEOF: 'MAKEUSEOF',
  PLACEHOLDER: 'PLACEHOLDER',
  CES_INNOVATION_AWARD_HON: 'CES_INNOVATION_AWARD_HON',
  ORKA_ONE_IS_THE_FIRST_HEARING: 'ORKA_ONE_IS_THE_FIRST_HEARING',
  ORKA_TEAM_IS_COMMITTED_TO_PRO: 'ORKA_TEAM_IS_COMMITTED_TO_PRO',
  THE_ORKA_AWARDS: 'THE_ORKA_AWARDS',
  CUTTING_EDGE_TECHNOLOGIES_MEET: 'CUTTING_EDGE_TECHNOLOGIES_MEET',
  CES: 'CES',
  INNOVATION_AWARDS: 'INNOVATION_AWARDS',
  HONOREE: 'HONOREE',
  PX127: 'PX127',
  PX_PX17: 'PX_PX17',
  MEET_OUR_ORKA_ONE: 'MEET_OUR_ORKA_ONE',
  PANEL: 'PANEL',
  AN_OVER_THE_AIR_SERVICE_ON_DEM: 'AN_OVER_THE_AIR_SERVICE_ON_DEM',
  EXPERIENCE_FULL_REMOTECARE_CYC: 'EXPERIENCE_FULL_REMOTECARE_CYC',
  PANEL1: 'PANEL1',
  JUST_AN_APP_AND_NO_MORE_TOOLS: 'JUST_AN_APP_AND_NO_MORE_TOOLS',
  ORKA_MAKES_OVER_THE_AIR_FITTIN: 'ORKA_MAKES_OVER_THE_AIR_FITTIN',
  PANEL2: 'PANEL2',
  MINIMAL_DELAY_IN_REAL_TIME_TUN: 'MINIMAL_DELAY_IN_REAL_TIME_TUN',
  OUR_INNOVATIVE_AND_DATA_DRIVEN: 'OUR_INNOVATIVE_AND_DATA_DRIVEN',
  PANEL3: 'PANEL3',
  UNLIMITED_FOLLOW_UPS: 'UNLIMITED_FOLLOW_UPS',
  NEED_HELP_WHEN_CONSIDERING_NEW: 'NEED_HELP_WHEN_CONSIDERING_NEW',
  PANEL4: 'PANEL4',
  TRUSTED_HEARING_AID_PROFESSION: 'TRUSTED_HEARING_AID_PROFESSION',
  FOR_OVER_YEARS_LLOYDS_HAS: 'FOR_OVER_YEARS_LLOYDS_HAS',
  DISCOVERING_THE_BENEFITS: 'DISCOVERING_THE_BENEFITS',
  START_THE_BENEFITS_FROM_TODAY: 'START_THE_BENEFITS_FROM_TODAY',
  SCHEDULE_FREE_PHONE_CONSULTATI: 'SCHEDULE_FREE_PHONE_CONSULTATI',
  COMMON_QUESTIONS1: 'COMMON_QUESTIONS1',
  REMOTE_CARE: 'REMOTE_CARE',
  CAN_I_BOOK_ORKA_S_FREE_CONSULT: 'CAN_I_BOOK_ORKA_S_FREE_CONSULT',
  WHAT_HAPPENS_IF_I_NEED_TO_RESC: 'WHAT_HAPPENS_IF_I_NEED_TO_RESC',
  WHAT_DO_YOU_MEAN_BY_UNLIMITED: 'WHAT_DO_YOU_MEAN_BY_UNLIMITED',
  DO_I_NEED_A_CLINIC_VISIT_BEFOR: 'DO_I_NEED_A_CLINIC_VISIT_BEFOR',
  NO_HIDDEN_FEES: 'NO_HIDDEN_FEES',
  UNLIMITED_CONVERSATIONS: 'UNLIMITED_CONVERSATIONS',
  ANY_TIME_ANY_MATTER: 'ANY_TIME_ANY_MATTER',
  SCHEDULE1: 'SCHEDULE1',
  PX_PX_PX_RGBA: 'PX_PX_PX_RGBA',
  PX128: 'PX128',
  _92: '_92',
  JUMPING: 'JUMPING',
  VISIBLE: 'VISIBLE',
  NEW_TO_ORKA: 'NEW_TO_ORKA',
  CREATE_ACCOUNT2: 'CREATE_ACCOUNT2',
  PX129: 'PX129',
  WRONG_PASSWORD_TRY_AGAIN_OR_C: 'WRONG_PASSWORD_TRY_AGAIN_OR_C',
  COULDN_T_FIND_YOUR_ORKA_ACCOUN: 'COULDN_T_FIND_YOUR_ORKA_ACCOUN',
  LET_US_KNOW_MORE_ABOUT_YOU: 'LET_US_KNOW_MORE_ABOUT_YOU',
  REFERENCE_INFORMATION_REQUIRED: 'REFERENCE_INFORMATION_REQUIRED',
  SUBSCRIBE_TO_STAY_UPDATE_WITH: 'SUBSCRIBE_TO_STAY_UPDATE_WITH',
  LATER_TO_EDIT: 'LATER_TO_EDIT',
  FIRST_NAME_REQUIRED_FIELD: 'FIRST_NAME_REQUIRED_FIELD',
  LAST_NAME_REQUIRED_FIELD: 'LAST_NAME_REQUIRED_FIELD',
  DATE_INFORMATION_REQUIRED_FIE: 'DATE_INFORMATION_REQUIRED_FIE',
  GENDER_INFORMATION_REQUIRED_F: 'GENDER_INFORMATION_REQUIRED_F',
  RESIDENCY_INFORMATION_REQUIRE: 'RESIDENCY_INFORMATION_REQUIRE',
  PLEASE: 'PLEASE',
  SET: 'SET',
  RESET: 'RESET',
  YOUR_PASSWORD: 'YOUR_PASSWORD',
  BACK_TO: 'BACK_TO',
  SIGN_IN1: 'SIGN_IN1',
  CONFIRM1: 'CONFIRM1',
  _93: '_93',
  RESET_YOUR_PASSWORD: 'RESET_YOUR_PASSWORD',
  WE_LL_SEND_YOU_AN_EMAIL_WITH_I: 'WE_LL_SEND_YOU_AN_EMAIL_WITH_I',
  SEND: 'SEND',
  CREATE_AN_ACCOUNT: 'CREATE_AN_ACCOUNT',
  TERMS_OF_SERVICE3: 'TERMS_OF_SERVICE3',
  PX130: 'PX130',
  EMAIL_ADDRESS_ALREADY_EXISTS: 'EMAIL_ADDRESS_ALREADY_EXISTS',
  ACCOUNT_CREATED_SUCCESSFULLY: 'ACCOUNT_CREATED_SUCCESSFULLY',
  FIREBASE: 'FIREBASE',
  WE_HAVE_UPDATED_LOGIN_SYSTEM: 'WE_HAVE_UPDATED_LOGIN_SYSTEM',
  ACCOUNT_NEEDED_TO_BE_VERIFIED: 'ACCOUNT_NEEDED_TO_BE_VERIFIED',
  PLEASE_CHECK_YOUR_EMAIL_TO: 'PLEASE_CHECK_YOUR_EMAIL_TO',
  RESET_YOUR_ORKA_PASSWORD: 'RESET_YOUR_ORKA_PASSWORD',
  ACTIVATE_YOUR_ORKA_ACCOUNT: 'ACTIVATE_YOUR_ORKA_ACCOUNT',
  WE_JUST_SENT_A_MAGIC_LINK_TO: 'WE_JUST_SENT_A_MAGIC_LINK_TO',
  DIDN_T_RECEIVE_AN_EMAIL: 'DIDN_T_RECEIVE_AN_EMAIL',
  _94: '_94',
  EMAIL_SENT: 'EMAIL_SENT',
  FOOTER_SIGNUP: 'FOOTER_SIGNUP',
  SUBMIT2: 'SUBMIT2',
  THANK_YOU_FOR_JOINING_US: 'THANK_YOU_FOR_JOINING_US',
  CHAT_NOW: 'CHAT_NOW',
  EMAIL_US: 'EMAIL_US',
  MAILTO_CA_SUPPORT_HIORKA_COM: 'MAILTO_CA_SUPPORT_HIORKA_COM',
  FOLLOW_US: 'FOLLOW_US',
  _95: '_95',
  PRODUCT_SERVICE: 'PRODUCT_SERVICE',
  ABOUT: 'ABOUT',
  ABOUT_US: 'ABOUT_US',
  HEARING_BLOG: 'HEARING_BLOG',
  SUPPORT: 'SUPPORT',
  FAQ: 'FAQ',
  ORDER_STATUS: 'ORDER_STATUS',
  ABOUT_US1: 'ABOUT_US1',
  LABOR_DAY_SPECIAL_OFFER: 'LABOR_DAY_SPECIAL_OFFER',
  SIGN_UP_TO_ORKA_S_EMAIL_LIST_A: 'SIGN_UP_TO_ORKA_S_EMAIL_LIST_A',
  ENJOY_ON_US: 'ENJOY_ON_US',
  DON_T_MISS_OUT_YOUR_BEST_HEARI: 'DON_T_MISS_OUT_YOUR_BEST_HEARI',
  I_WANT_IT: 'I_WANT_IT',
  NO_THANKS: 'NO_THANKS',
  AWARD_WINNING: 'AWARD_WINNING',
  NO_OBLIGATION: 'NO_OBLIGATION',
  FREE_TRY_ON1: 'FREE_TRY_ON1',
  WE_ARE_WORKING_ON_SOME_EXCITIN: 'WE_ARE_WORKING_ON_SOME_EXCITIN',
  ONE_LAST_STEP_TO_YOUR_FREE_TRY: 'ONE_LAST_STEP_TO_YOUR_FREE_TRY',
  ORKA_HEARING_AIDS_ARE_TAILORED: 'ORKA_HEARING_AIDS_ARE_TAILORED',
  LEAVE_US_YOUR_EMAIL_TO_START: 'LEAVE_US_YOUR_EMAIL_TO_START',
  I_WANT_TO_EXPLORE_ORKA_ONE_FIR: 'I_WANT_TO_EXPLORE_ORKA_ONE_FIR',
  PX131: 'PX131',
  _96: '_96',
  HOME_FIT_HEARING_TEST_WITH_ORK: 'HOME_FIT_HEARING_TEST_WITH_ORK',
  TEST_YOUR_HEARING_AND_SYNC_YOU: 'TEST_YOUR_HEARING_AND_SYNC_YOU',
  HOW_DOES_IT_WORK: 'HOW_DOES_IT_WORK',
  IN_SITU_AUDIOMETRY_SIMPLY_AND: 'IN_SITU_AUDIOMETRY_SIMPLY_AND',
  TEST_AT_HOME: 'TEST_AT_HOME',
  NO_MORE_LONG_DRIVES_TO_CLINICS: 'NO_MORE_LONG_DRIVES_TO_CLINICS',
  TAILORED_HEARING_PROFILE: 'TAILORED_HEARING_PROFILE',
  OUR_MEDICAL_GRADE_HEARING_TEST: 'OUR_MEDICAL_GRADE_HEARING_TEST',
  NO_MORE_LONG_DRIVES_TO_CLINICS1: 'NO_MORE_LONG_DRIVES_TO_CLINICS1',
  OUR_MEDICAL_GRADE_HEARING_TEST1: 'OUR_MEDICAL_GRADE_HEARING_TEST1',
  WHEN_DO_I_NEED_AN_HOME_FIT_HEA: 'WHEN_DO_I_NEED_AN_HOME_FIT_HEA',
  YOU_DON_T_HAVE_AN_AUDIOGRAM: 'YOU_DON_T_HAVE_AN_AUDIOGRAM',
  YOUR_CURRENT_HEARING_AIDS_N: 'YOUR_CURRENT_HEARING_AIDS_N',
  A_CLINICAL_HEARING_TEST_IS: 'A_CLINICAL_HEARING_TEST_IS',
  ACCURATE_WITH_DB: 'ACCURATE_WITH_DB',
  OUR_MEDICAL_GRADE_HEARING_TEST2: 'OUR_MEDICAL_GRADE_HEARING_TEST2',
  DB_DECIBELS: 'DB_DECIBELS',
  A_BROAD_RANGE_OF_FREQUENCIES: 'A_BROAD_RANGE_OF_FREQUENCIES',
  WE_WILL_TEST_YOUR_ABILITY_TO_H: 'WE_WILL_TEST_YOUR_ABILITY_TO_H',
  HZ_AND_HZ: 'HZ_AND_HZ',
  TO_CREATE_A_GRAPHICAL_HEARING: 'TO_CREATE_A_GRAPHICAL_HEARING',
  BACK_TO_INSIDER_COUPON_CODE: 'BACK_TO_INSIDER_COUPON_CODE',
  PX_PX18: 'PX_PX18',
  PX132: 'PX132',
  PX133: 'PX133',
  PX134: 'PX134',
  PX135: 'PX135',
  COPY_TO_CLIPBORAD: 'COPY_TO_CLIPBORAD',
  COPIED: 'COPIED',
  COPY_THE_INSIDER_CODE: 'COPY_THE_INSIDER_CODE',
  COPIED1: 'COPIED1',
  COPY_THE_CODE_ABOVE_AND_PASTE: 'COPY_THE_CODE_ABOVE_AND_PASTE',
  CHECK_OUT_NOW: 'CHECK_OUT_NOW',
  CONGRATS: 'CONGRATS',
  CONGRATS_YOU_RE_QUALIFIED_GE: 'CONGRATS_YOU_RE_QUALIFIED_GE',
  Begin_Your_Free_Try_On: 'Begin_Your_Free_Try',
  DON_T_WORRY_IF_YOU_DON_T_HAVE: 'DON_T_WORRY_IF_YOU_DON_T_HAVE',
  GET_CODE_NOW: 'GET_CODE_NOW',
  LEARN_MORE_ABOUT_ORKA_HEALTH: 'LEARN_MORE_ABOUT_ORKA_HEALTH',
  DISCOVER_ORKA_ONE_FIRST: 'DISCOVER_ORKA_ONE_FIRST',
  PX_PX_PX6: 'PX_PX_PX6',
  _97: '_97',
  PX136: 'PX136',
  PX137: 'PX137',
  _98: '_98',
  PX138: 'PX138',
  PX139: 'PX139',
  PX140: 'PX140',
  PX141: 'PX141',
  PX142: 'PX142',
  PX143: 'PX143',
  PX144: 'PX144',
  PX145: 'PX145',
  GET_ME_STARTED: 'GET_ME_STARTED',
  I_WANT_TO_EXPLORE_MORE_ABOUT_O: 'I_WANT_TO_EXPLORE_MORE_ABOUT_O',
  SCHEDULE_NOW2: 'SCHEDULE_NOW2',
  YOU_ARE_ALL_SET: 'YOU_ARE_ALL_SET',
  THANK_YOU_FOR_SIGNING_UP: 'THANK_YOU_FOR_SIGNING_UP',
  STRING: 'STRING',
  VH9: 'VH9',
  VH10: 'VH10',
  STAY_TUNED_FOR_MORE_UPDATES: 'STAY_TUNED_FOR_MORE_UPDATES',
  BALL: 'BALL',
  YOUR_EMAIL: 'YOUR_EMAIL',
  I_AGREE_TO_THE_ORKA_S1: 'I_AGREE_TO_THE_ORKA_S1',
  SIGN_UP1: 'SIGN_UP1',
  CHAT_WITH_US: 'CHAT_WITH_US',
  BUNDLE_CONFIRMED: 'BUNDLE_CONFIRMED',
  MEET_THE_ORKA_BUNDLE: 'MEET_THE_ORKA_BUNDLE',
  A_PAIR_OF_HEARING_AIDS_PLUS_ON: 'A_PAIR_OF_HEARING_AIDS_PLUS_ON',
  AI_DENOISE_HEARING_AIDS: 'AI_DENOISE_HEARING_AIDS',
  THE_ACTUAL_HUMAN_CENTERED_HEAR1: 'THE_ACTUAL_HUMAN_CENTERED_HEAR1',
  EXPLORE_MORE: 'EXPLORE_MORE',
  RECEIVE_REAL_TIME_ADJUSTMENTS: 'RECEIVE_REAL_TIME_ADJUSTMENTS',
  COLOUMN: 'COLOUMN',
  PX146: 'PX146',
  CENTER1: 'CENTER1',
  PX_PX19: 'PX_PX19',
  PX_PX20: 'PX_PX20',
  PX147: 'PX147',
  PX148: 'PX148',
  PX149: 'PX149',
  PX150: 'PX150',
  PX151: 'PX151',
  PX152: 'PX152',
  _99: '_99',
  I_CARE_ABOUT_HOW_WELL_I_CAN: 'I_CARE_ABOUT_HOW_WELL_I_CAN',
  I_CARE_IF_THIS_HEARING_SOLUT: 'I_CARE_IF_THIS_HEARING_SOLUT',
  I_CARE_ABOUT_HOW_WELL_A_HEAR: 'I_CARE_ABOUT_HOW_WELL_A_HEAR',
  SPEECH_IN_NOISE: 'SPEECH_IN_NOISE',
  I_CARE_ABOUT_HOW_WELL_I_CAN_UN: 'I_CARE_ABOUT_HOW_WELL_I_CAN_UN',
  LAYOUT: 'LAYOUT',
  CONTENT1: 'CONTENT1',
  _100: '_100',
  IMG: 'IMG',
  I_CARE_IF_THIS_HEARING_SOLUTIO: 'I_CARE_IF_THIS_HEARING_SOLUTIO',
  _101: '_101',
  I_CARE_ABOUT_HOW_WELL_A_HEARIN: 'I_CARE_ABOUT_HOW_WELL_A_HEARIN',
  _102: '_102',
  PX153: 'PX153',
  I_M_NOT_SURE: 'I_M_NOT_SURE',
  BOX: 'BOX',
  PX154: 'PX154',
  SPACE_AROUND: 'SPACE_AROUND',
  SUBMIT_CONSULTATION_SUCCESSFUL: 'SUBMIT_CONSULTATION_SUCCESSFUL',
  TRY_ORKA_ONE_WITH_DAY: 'TRY_ORKA_ONE_WITH_DAY',
  SHOP_NOW: 'SHOP_NOW',
  YOUR_HEARING_CONSULTATION_IS_S1: 'YOUR_HEARING_CONSULTATION_IS_S1',
  AN_ORKA_HEARING_SPECIALIST_WIL: 'AN_ORKA_HEARING_SPECIALIST_WIL',
  SURVEY: 'SURVEY',
  F_AA_A: 'F_AA_A',
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  MOBILE_CALENDAR: 'MOBILE_CALENDAR',
  INPUT: 'INPUT',
  RESULT_YOUNGER: 'RESULT_YOUNGER',
  NOTUSA: 'NOTUSA',
  SUCCESSFUL: 'SUCCESSFUL',
  RESULT_ADULT: 'RESULT_ADULT',
  NOHISTORY: 'NOHISTORY',
  SLIDEBACK_APPEAR: 'SLIDEBACK_APPEAR',
  SLIDEBACK_APPEAR_ACTIVE: 'SLIDEBACK_APPEAR_ACTIVE',
  SLIDEBACK_ENTER: 'SLIDEBACK_ENTER',
  SLIDEBACK_ENTER_ACTIVE: 'SLIDEBACK_ENTER_ACTIVE',
  SLIDEBACK_ENTER_DONE: 'SLIDEBACK_ENTER_DONE',
  SLIDEBACK_EXIT: 'SLIDEBACK_EXIT',
  SLIDEBACK_EXIT_ACTIVE: 'SLIDEBACK_EXIT_ACTIVE',
  SLIDEBACK_EXIT_DONE: 'SLIDEBACK_EXIT_DONE',
  SLIDE_APPEAR: 'SLIDE_APPEAR',
  SLIDE_APPEAR_ACTIVE: 'SLIDE_APPEAR_ACTIVE',
  SLIDE_ENTER: 'SLIDE_ENTER',
  SLIDE_ENTER_ACTIVE: 'SLIDE_ENTER_ACTIVE',
  SLIDE_ENTER_DONE: 'SLIDE_ENTER_DONE',
  SLIDE_EXIT: 'SLIDE_EXIT',
  SLIDE_EXIT_ACTIVE: 'SLIDE_EXIT_ACTIVE',
  SLIDE_EXIT_DONE: 'SLIDE_EXIT_DONE',
  GRID: 'GRID',
  PX155: 'PX155',
  PX156: 'PX156',
  SCHEDULE_YOUR_FREE_CONSULTATIO: 'SCHEDULE_YOUR_FREE_CONSULTATIO',
  CARDBACK_APPEAR: 'CARDBACK_APPEAR',
  CARDBACK_APPEAR_ACTIVE: 'CARDBACK_APPEAR_ACTIVE',
  CARDBACK_ENTER: 'CARDBACK_ENTER',
  CARDBACK_ENTER_ACTIVE: 'CARDBACK_ENTER_ACTIVE',
  CARDBACK_ENTER_DONE: 'CARDBACK_ENTER_DONE',
  CARDBACK_EXIT: 'CARDBACK_EXIT',
  CARDBACK_EXIT_ACTIVE: 'CARDBACK_EXIT_ACTIVE',
  CARDBACK_EXIT_DONE: 'CARDBACK_EXIT_DONE',
  CARD_APPEAR: 'CARD_APPEAR',
  CARD_APPEAR_ACTIVE: 'CARD_APPEAR_ACTIVE',
  CARD_ENTER: 'CARD_ENTER',
  CARD_ENTER_ACTIVE: 'CARD_ENTER_ACTIVE',
  CARD_ENTER_DONE: 'CARD_ENTER_DONE',
  CARD_EXIT: 'CARD_EXIT',
  CARD_EXIT_ACTIVE: 'CARD_EXIT_ACTIVE',
  CARD_EXIT_DONE: 'CARD_EXIT_DONE',
  SEE_LESS: 'SEE_LESS',
  SEE_ALL: 'SEE_ALL',
  VW6: 'VW6',
  RGBA4: 'RGBA4',
  FIND_OUT_HOW_MUCH_YOUR_HEARING: 'FIND_OUT_HOW_MUCH_YOUR_HEARING',
  HIDDENBRAND: 'HIDDENBRAND',
  BRAND: 'BRAND',
  ENTER_BRAND: 'ENTER_BRAND',
  GET_ESTIMATE: 'GET_ESTIMATE',
  BY_SUBMITTING_THE_FORM_I_AGRE: 'BY_SUBMITTING_THE_FORM_I_AGRE',
  YOUR_HEARING_AIDS_ARE_WORTH_BE: 'YOUR_HEARING_AIDS_ARE_WORTH_BE',
  GET_YOUR_VOUCHER: 'GET_YOUR_VOUCHER',
  ESTIMATE_MY_VALUE_AGAIN: 'ESTIMATE_MY_VALUE_AGAIN',
  PX_PX21: 'PX_PX21',
  PX_PX22: 'PX_PX22',
  PX157: 'PX157',
  PX_PX23: 'PX_PX23',
  PX_PX24: 'PX_PX24',
  PX158: 'PX158',
  PHONAK: 'PHONAK',
  OTICON: 'OTICON',
  WIDEX: 'WIDEX',
  SIGNIA: 'SIGNIA',
  RESOUND: 'RESOUND',
  STARKEY: 'STARKEY',
  EARGO: 'EARGO',
  LIVELY: 'LIVELY',
  KIRKLAND_COSTCO: 'KIRKLAND_COSTCO',
  PLEASE_ENTER_YOUR_HEARING_AIDS: 'PLEASE_ENTER_YOUR_HEARING_AIDS',
  PLEASE_ENTER_A_VALID_YEAR_OF_P: 'PLEASE_ENTER_A_VALID_YEAR_OF_P',
  PLEASE_ENTER_YOUR_FIRST_AND_LA: 'PLEASE_ENTER_YOUR_FIRST_AND_LA',
  PLEASE_ENTER_YOUR_EMAIL: 'PLEASE_ENTER_YOUR_EMAIL',
  PLEASE_PROVIDE_A_VALID_EMAIL: 'PLEASE_PROVIDE_A_VALID_EMAIL',
  PLEASE_ENTER_YOUR_PHONE: 'PLEASE_ENTER_YOUR_PHONE',
  PLEASE_PROVIDE_A_VALID_PHONE_N: 'PLEASE_PROVIDE_A_VALID_PHONE_N',
  ORKA_ONE_REVIEWS: 'ORKA_ONE_REVIEWS',
  READ_OUR_REVIEWS: 'READ_OUR_REVIEWS',
  _103: '_103',
  FIRST: 'FIRST',
  LAST: 'LAST',
  THANK_YOU_FOR_SUBMITTING: 'THANK_YOU_FOR_SUBMITTING',
  OUR_HEARING_SPECIALIST_WILL_RE: 'OUR_HEARING_SPECIALIST_WILL_RE',
  CALL: 'CALL',
  EMAIL_US1: 'EMAIL_US1',
  MEET_OUR_AUDIOLOGISTS_TO_APPLY: 'MEET_OUR_AUDIOLOGISTS_TO_APPLY',
  COMMON: 'COMMON',
  QUESTIONS: 'QUESTIONS',
  FIND_OUT_HOW_MUCH_YOU_CAN_GET: 'FIND_OUT_HOW_MUCH_YOU_CAN_GET',
  PX159: 'PX159',
  PX160: 'PX160',
  INTELLIGENT_HEARING_SOLUTIONS: 'INTELLIGENT_HEARING_SOLUTIONS',
  EXPLORE_ORKA_ONE: 'EXPLORE_ORKA_ONE',
  CONTAINER: 'CONTAINER',
  SRC_IMGS_LANDING_SUBSCRIBE_POS: 'SRC_IMGS_LANDING_SUBSCRIBE_POS',
  STATIC: 'STATIC',
  PX161: 'PX161',
  _104: '_104',
  AUDIO: 'AUDIO',
  _105: '_105',
  HAVE_DIFFICULTIES_DURING_USING: 'HAVE_DIFFICULTIES_DURING_USING',
  YOU_HAVE_THE_ORKA_ONE_DAY_T: 'YOU_HAVE_THE_ORKA_ONE_DAY_T',
  RETURN_SUMMARY: 'RETURN_SUMMARY',
  ORDER_NUMBER6: 'ORDER_NUMBER6',
  REFUND_XXXXX: 'REFUND_XXXXX',
  QUANTITY3: 'QUANTITY3',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  WHY_DID_THIS_ITEM_NOT_MEET_YOU: 'WHY_DID_THIS_ITEM_NOT_MEET_YOU',
  UPLOAD_PICTURES_FOR_THE_RETURN: 'UPLOAD_PICTURES_FOR_THE_RETURN',
  ORDER_NUMBER_XXX: 'ORDER_NUMBER_XXX',
  CANCELED_JAN: 'CANCELED_JAN',
  YOU_CANCELED_THIS_ORDR_ON_J: 'YOU_CANCELED_THIS_ORDR_ON_J',
  HERE_S_YOUR_REFUND_PROCESS: 'HERE_S_YOUR_REFUND_PROCESS',
  APPLY_SUCCESSFULLY: 'APPLY_SUCCESSFULLY',
  APPLY_FOR_RETURN_SUCCESSFULLY: 'APPLY_FOR_RETURN_SUCCESSFULLY',
  THE_FOLLOWING_ITEM_HAS_BEEN_AP: 'THE_FOLLOWING_ITEM_HAS_BEEN_AP',
  YOU_DON_T_HAVE_TO_PAY_FOR_THIS: 'YOU_DON_T_HAVE_TO_PAY_FOR_THIS',
  PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
  NAME_DI_GAO: 'NAME_DI_GAO',
  PHONE_NUMBER3: 'PHONE_NUMBER3',
  EMAIL_ADDRESS_EHUDHW_GMAIL_CO: 'EMAIL_ADDRESS_EHUDHW_GMAIL_CO',
  AVAILABLE_TIME_WEEKDAYS: 'AVAILABLE_TIME_WEEKDAYS',
  PRINT_RETURN_LABLE: 'PRINT_RETURN_LABLE',
  CANCEL_THE_ORDER_WHY_NOT_TRY: 'CANCEL_THE_ORDER_WHY_NOT_TRY',
  TRY_ORKA_ONE_WITH_DAY_TRIAL: 'TRY_ORKA_ONE_WITH_DAY_TRIAL',
  _106: '_106',
  REFUND2: 'REFUND2',
  XXXXX: 'XXXXX',
  I_DON_T_WANT_IT1: 'I_DON_T_WANT_IT1',
  SUMBIT: 'SUMBIT',
  CANCELED: 'CANCELED',
  CANCELLED_SUCCESSFULLY_THE_RE: 'CANCELLED_SUCCESSFULLY_THE_RE',
  THE_FOLLOWING_ITEM_HAS_BEEN_CA: 'THE_FOLLOWING_ITEM_HAS_BEEN_CA',
  CANCELED_SUCCESSFULLY: 'CANCELED_SUCCESSFULLY',
  IF_YOU_HAVE_ANY_QUESTIONS_YOU: 'IF_YOU_HAVE_ANY_QUESTIONS_YOU',
  WHERE_DO_I_FIND_MY_ORDER_NUM: 'WHERE_DO_I_FIND_MY_ORDER_NUM',
  DO_NOT_HAVE_ACCOUNT: 'DO_NOT_HAVE_ACCOUNT',
  FOR: 'FOR',
  ORDER_NUM_CAN_BE_FOUND_FROM: 'ORDER_NUM_CAN_BE_FOUND_FROM',
  ORDER_CONFIRMATION_EMAIL: 'ORDER_CONFIRMATION_EMAIL',
  ORKA_HAS_SENT_PREVIOUSLY_UPON_PURCHASE: 'ORKA_HAS_SENT_PREVIOUSLY_UPON_PURCHASE',
  ALREADY_HAVE_AN_ORKA_ACCOUNT: 'ALREADY_HAVE_AN_ORKA_ACCOUNT',
  YOU_CAN_ALSO_LOG_INTO_YOUR: 'YOU_CAN_ALSO_LOG_INTO_YOUR',
  ORKA_ACCOUNT: 'ORKA_ACCOUNT',
  GO_TO_THE_ACCOUNT_MENU_AND_SELECT: 'GO_TO_THE_ACCOUNT_MENU_AND_SELECT',
  AND_VIEW_DETAILS_OF_ALL_YOUR_ORDERS: 'AND_VIEW_DETAILS_OF_ALL_YOUR_ORDERS',
  FIND_MY_ORDER: 'FIND_MY_ORDER',
  HOW_DO_I_FIND_MY_ORDER_NUMBER: 'HOW_DO_I_FIND_MY_ORDER_NUMBER',
  OR_LOG_IN_TO_SEE_YOUR_ORDER_HISTORY: 'OR_LOG_IN_TO_SEE_YOUR_ORDER_HISTORY',
  UPLOAD_YOUR_AUDIOGRAM: 'UPLOAD_YOUR_AUDIOGRAM',
  UPLOAD_YOUR_AUDIOGRAM_CONTENT: 'UPLOAD_YOUR_AUDIOGRAM_CONTENT',
  GO_TO_UPLOAD: 'GO_TO_UPLOAD',
}
