import '@/public/fonts/inter.css'
import '../styles/_globals.scss'
import '@/src/i18n/init.js'
import '@evocolabs/orkaui/lib/orkaui.scss'
import 'core-js/proposals/string-replace-all'

import * as Sentry from '@sentry/react'

import { Component, useEffect } from 'react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import SwiperCore, { Autoplay } from 'swiper'
import { checkHeapInterval, postUTMHistory } from '@/src/providers/RemotePoint'

import BackendProvider from '@/src/providers/BackendProvider'
import { CookiesProvider } from 'react-cookie'
import CssBaseline from '@mui/material/CssBaseline'
import Head from 'next/head'
import { NextSeo } from 'next-seo'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import StylesProvider from '@mui/styles/StylesProvider'
import dynamic from 'next/dynamic'
import { getLocalStorageItem } from '@/src/utils/localStorage'
import { reportReactError } from 'src/utils/frontendLogger'
import store from '@/src/redux/store'
import theme from '@/src/theme'
import { useRouter } from 'next/router'

const ScrollingHeatmap = dynamic(
  () => import('@/components/Landing/ScrollingHeatmap'),
  {
    ssr: false
  }
)

// Function to increment pageview count
const incrementPageViewCount = () => {
  let count = localStorage.getItem('pageView') || 0
  count++
  localStorage.setItem('pageView', count)
}

let isInitialLoad = true
function MyAppChild(props) {
  const { Component, pageProps, err } = props
  const router = useRouter()

  // auto swiper
  SwiperCore.use([Autoplay])

  // heap
  useEffect(() => {
    checkHeapInterval()
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      if (!isInitialLoad) {
        incrementPageViewCount()
      }
    }
    if (isInitialLoad) {
      incrementPageViewCount()
      isInitialLoad = false
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [isInitialLoad, router.events])

  // check os:
  // const mTheme = useTheme()

  // check online
  // const current = new Date()
  // const temp = current.toLocaleString('en-US', {
  //   timeZone: 'America/Los_Angeles',
  //   hour: '2-digit',
  //   hour12: false
  // })

  const storeUTMs = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const currentUTMParameters = {
      utm_source: urlParams.get('utm_source') || null,
      utm_campaign: urlParams.get('utm_campaign') || null,
      utm_medium: urlParams.get('utm_medium') || null,
      utm_content: urlParams.get('utm_content') || null,
      utm_term: urlParams.get('utm_term') || null,
      device_type: /Mobi/.test(navigator.userAgent) ? 'mobile' : 'desktop',
      timestamp: new Date().toISOString()
    }

    // Check if the essential UTM fields (source, campaign, medium, content) are all null
    const hasEssentialUTMInfo = [
      'utm_source',
      'utm_campaign',
      'utm_medium',
      'utm_content',
      'utm_term'
    ].some((key) => currentUTMParameters[key])

    // Proceed only if at least one UTM parameter is not empty
    if (hasEssentialUTMInfo) {
      const utmHistory = JSON.parse(localStorage.getItem('utmHistory')) || []
      const email = getLocalStorageItem('insider-email')

      // Check if the current UTM parameters are the same as the last entry
      const lastEntry = utmHistory[utmHistory.length - 1]
      const isNewClick =
        !lastEntry ||
        Object.keys(currentUTMParameters).some((key) => {
          return (
            key !== 'timestamp' && currentUTMParameters[key] !== lastEntry[key]
          )
        })

      // If it's a new click or there is no last entry, push the new UTM data to history
      if (isNewClick) {
        utmHistory.push(currentUTMParameters)
        localStorage.setItem('utmHistory', JSON.stringify(utmHistory))
      }

      // If an email exists in local storage, call PostUTM
      if (email) {
        postUTMHistory(email)
      }
    }
  }

  useEffect(() => {
    Sentry.setUser({
      email: localStorage.getItem('userID')
    })
    storeUTMs()
    //labor day
  }, [])

  return (
    // <AnimatePresence exitBeforeEnter>
    <>
      <Head>
        <title>Hearing Aids | Orka - Hear what matters</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width,user-scalable=no"
        />
        <meta
          name="facebook-domain-verification"
          content="82m3rj3ra5b9glru6kq25smw89o2lh"
        />
        <meta
          name="google-site-verification"
          content="Dh15xQNs8cHXURD1KfLSGvzqppL2fNL9jC2eqpPL2ZA"
        />
        <meta
          name="facebook-domain-verification"
          content="82m3rj3ra5b9glru6kq25smw89o2lh"
        />
        {/* <meta name='robots' content='noodp,nodir' /> */}
        {/* customized linkedin share */}
        <NextSeo
          title="Orka Hearing Aids | Hear What Matters | Buy Online"
          description="Enjoy AI-powered hearing aids with top-notch noise reduction and authentic Bluetooth functionality. FDA-registered, Remote Care service offered by trusted professionals. Premium and award-winning hearing aids sold directly to customers."
          canonical="https://www.hiorka.com"
          openGraph={{
            type: 'website',
            url: 'https://hiorka.com',
            title: 'Orka Hearing Aids | Hear What Matters | Buy Online',
            description:
              'Enjoy AI-powered hearing aids with top-notch noise reduction and authentic Bluetooth functionality. FDA-registered, Remote Care service offered by trusted professionals. Premium and award-winning hearing aids sold directly to customers.',
            images: [
              {
                url: '/imgs/productone.png',
                width: 2560,
                height: 1440,
                alt: 'Og Image Alt'
              },
              {
                url: '/imgs/productone2.png',
                width: 1080,
                height: 1080,
                alt: 'Og Image Alt'
              },
              {
                url: '/imgs/productone3.png',
                width: 2004,
                height: 1129,
                alt: 'Og Image Alt'
              }
            ]
          }}
        />
      </Head>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <CssBaseline />
            <Provider store={store}>
              <BackendProvider>
                <CookiesProvider>
                  <Component {...pageProps} err={err} />
                  <ScrollingHeatmap />
                </CookiesProvider>
              </BackendProvider>
            </Provider>
          </StylesProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}

export default class MyApp extends Component {
  render() {
    return <MyAppChild {...this.props} err={this.err} />
  }

  componentDidCatch(error, errorInfo) {
    reportReactError(error, errorInfo)
  }
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
}
