import { ax } from '../../AxiosFactory'

const getTroubleDetails = async (id) => {
  const res = await ax.get('troubleshooting-article/' + id + '/')
  return res
}
const updateTroubleShootingComment = async (id, cancel, comment) => {
  const res = await ax.post('troubleshooting-article/update-comment/', {
    id: id,
    cancel: cancel,
    comment: comment
  })
  return res
}

export { getTroubleDetails, updateTroubleShootingComment }
