import axios from 'axios'
import { verifyToken } from './RemotePoint'
import { getEnvVar } from '@/env'
import {
  gtmDataLayerPushEvents,
  gtmEventUserInfo
} from '@/utils/gtmDataLayerUtils'
import { hashMD5, hashSHA256 } from '@/utils/crypto'
import { setLocalStorageItem } from '@/src/utils/localStorage'
import { sendLog } from '@/src/utils/frontendLogger'
const EMAIL_KEY = 'insider-email'

const commonBeforeRequestHandler = (config) => {
  // Parse the JSON request body
  const requestBody = config?.data || '{}'
  // Check if 'email' key exists
  if (requestBody.email) {
    const userEmail = requestBody.email
    setLocalStorageItem(EMAIL_KEY, userEmail)
    // Use the Heap API to identify the user
    // Assuming you've initialized Heap somewhere in your app
    try {
      gtmDataLayerPushEvents(
        gtmEventUserInfo({
          email: userEmail,
          email_md5: hashMD5(userEmail),
          email_sha256: hashSHA256(userEmail)
        })
      )
      heap.identify(userEmail)
      heap.addEventProperties({ 'Become Lead': 'true' })
      postUTMHistory(userEmail)
    } catch (e) {
      console.error('Error with heap sync or post utm history')
      sendLog('500', 'Error with heap sync or post utm history', e, userEmail)
    }
  }
  return config
}

const createAxiosInstance = (baseURL, headers, options = {}) => {
  const instance = axios.create({
    baseURL: baseURL,
    headers: headers,
    ...options
  })
  instance.interceptors.request.use(commonBeforeRequestHandler)
  return instance
}
const jsonHeaders = { 'Content-Type': 'application/json' }
const formHeaders = { 'Content-Type': 'multipart/form-data' }

const ax = createAxiosInstance(getEnvVar('NEXT_PUBLIC_BASEURL'), jsonHeaders)
const axMulti = createAxiosInstance(
  getEnvVar('NEXT_PUBLIC_BASEURL'),
  formHeaders
)
const axSchedule = createAxiosInstance(
  getEnvVar('NEXT_PUBLIC_HEALTH_URL'),
  jsonHeaders
)
const axScheduleV3 = createAxiosInstance(
  getEnvVar('NEXT_PUBLIC_HEALTH_URL_V3'),
  jsonHeaders
)
const axHubspotWithoutInterceptor = axios.create({
  baseURL: getEnvVar('NEXT_PUBLIC_HUBSPOT_URL'),
  headers: jsonHeaders
})
const axService = createAxiosInstance(
  getEnvVar('NEXT_PUBLIC_SERVICE_BASEURL'),
  jsonHeaders
)

const axOrder = createAxiosInstance(
  getEnvVar('NEXT_PUBLIC_ORDER_URL'),
  jsonHeaders
)

const axFullfillment = createAxiosInstance(
  getEnvVar('NEXT_PUBLIC_FULFILLMENT_URL'),
  jsonHeaders
)

const marketingService = createAxiosInstance(
  getEnvVar('NEXT_PUBLIC_MARKETING_ROOT_URL'),
  jsonHeaders
)

const axAudiogram = axios.create({
  baseURL: getEnvVar('NEXT_PUBLIC_HEALTH_URL'),
  headers: formHeaders
})

const needInterceptorRequestAxios = [
  ax,
  axMulti,
  axService,
  axOrder,
  axFullfillment,
  axScheduleV3,
  axAudiogram
]
const extractSafariTokenAxios = [axFullfillment, axScheduleV3, axAudiogram]
const needInterceptorResponseAxios = [
  ax,
  axMulti,
  axService,
  axOrder,
  axFullfillment,
  axScheduleV3
]

needInterceptorRequestAxios.forEach((ax) => {
  ax.interceptors.request.use(async (config) => {
    // Verify token before ax request is sent
    const verifiedToken = await verifyToken()
    if (verifiedToken) {
      config.headers.Authorization = verifiedToken
      if (!extractSafariTokenAxios.includes(ax)) {
        config.headers['Safari-Token'] = verifiedToken
      }
    }
    return config
  })
})

needInterceptorResponseAxios.forEach((ax) => {
  ax.interceptors.response.use(
    (res) => res,
    function (error) {
      // if request is forbidden, remove tokens and lead user to sign in page
      if (error.response.status === 403) {
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('refreshToken')

        window.location.href = '/SignIn'
      }
      return Promise.reject(error.response)
    }
  )
})

const validateEmail = (email: string) => {
  const regex = /^[^"'@\s]+@[^"'@\s]+\.[^"'@\s]+$/
  // Remove all double quotes from the email
  const cleanedEmail = email.replace(/["']/g, '')

  // Validate the cleaned email
  if (regex.test(cleanedEmail)) {
    // If the email is valid, return the cleaned email
    return cleanedEmail
  }
  // If the email is not valid, return null or throw an error
  return null // or throw new Error('Invalid email');
}

const postUTMHistory = async (email: string) => {
  const utmHistoryString = localStorage.getItem('utmHistory')
  const utmHistory = utmHistoryString ? JSON.parse(utmHistoryString) : null
  if (!utmHistory || utmHistory.length === 0) {
    console.error('No UTM history found to post.')
    return
  }
  const validatedEmail = validateEmail(email)
  if (!validatedEmail) {
    console.error('Invalid email:', email)
    return
  }
  const body = {
    email: validatedEmail,
    utm_info: utmHistory
  }
  try {
    await axHubspotWithoutInterceptor.post('hubspot_sys/contact/utm', body)
  } catch (error) {
    console.error('Error posting UTM history:', error)
  }
}

export {
  ax,
  axMulti,
  axSchedule,
  axScheduleV3,
  axHubspotWithoutInterceptor,
  axService,
  marketingService,
  axOrder,
  axFullfillment,
  axAudiogram,
  postUTMHistory
}
