const timeZone = [
  {
    label: 'UTC-12:00',
    tzCode: 'Etc/GMT+12',
    name: '(UTC-12:00) Etc/GMT+12',
    utc: '-12:00'
  },
  {
    label: 'UTC-11:00',
    tzCode: 'Pacific/Pago_Pago',
    name: '(UTC-11:00) Pacific/Pago_Pago',
    utc: '-11:00'
  },
  {
    label: 'UTC-10:00',
    tzCode: 'Pacific/Honolulu',
    name: '(UTC-10:00) Pacific/Honolulu',
    utc: '-10:00'
  },
  {
    label: 'UTC-9:00',
    tzCode: 'America/Anchorage',
    name: '(UTC-09:00) America/Anchorage',
    utc: '-09:00'
  },
  {
    label: 'UTC-8:00',
    tzCode: 'America/Los_Angeles',
    name: '(UTC-08:00) America/Los_Angeles',
    utc: '-08:00'
  },
  {
    label: 'UTC-7:00',
    tzCode: 'America/Denver',
    name: '(UTC-07:00) America/Denver',
    utc: '-07:00'
  },
  {
    label: 'UTC-6:00',
    tzCode: 'America/Chicago',
    name: '(UTC-06:00) America/Chicago',
    utc: '-06:00'
  },
  {
    label: 'UTC-5:00',
    tzCode: 'America/New_York',
    name: '(UTC-05:00) America/New_York',
    utc: '-05:00'
  },
  {
    label: 'UTC-4:00',
    tzCode: 'America/Caracas',
    name: '(UTC-04:00) America/Caracas',
    utc: '-04:00'
  },
  {
    label: 'UTC-3:00',
    tzCode: 'America/Buenos_Aires',
    name: '(UTC-03:00) America/Buenos_Aires',
    utc: '-03:00'
  },
  {
    label: 'UTC-2:00',
    tzCode: 'Atlantic/South_Georgia',
    name: '(UTC-02:00) Atlantic/South_Georgia',
    utc: '-02:00'
  },
  {
    label: 'UTC-1:00',
    tzCode: 'Atlantic/Cape_Verde',
    name: '(UTC-01:00) Atlantic/Cape_Verde',
    utc: '-01:00'
  },
  {
    label: 'UTC±0:00',
    tzCode: 'Etc/UTC',
    name: '(UTC±00:00) Coordinated Universal Time',
    utc: '+00:00'
  },
  {
    label: 'UTC+1:00',
    tzCode: 'Europe/London',
    name: '(UTC+01:00) Europe/London',
    utc: '+01:00'
  },
  {
    label: 'UTC+2:00',
    tzCode: 'Europe/Paris',
    name: '(UTC+02:00) Europe/Paris',
    utc: '+02:00'
  },
  {
    label: 'UTC+3:00',
    tzCode: 'Europe/Moscow',
    name: '(UTC+03:00) Europe/Moscow',
    utc: '+03:00'
  },
  {
    label: 'UTC+4:00',
    tzCode: 'Asia/Dubai',
    name: '(UTC+04:00) Asia/Dubai',
    utc: '+04:00'
  },
  {
    label: 'UTC+5:00',
    tzCode: 'Asia/Karachi',
    name: '(UTC+05:00) Asia/Karachi',
    utc: '+05:00'
  },
  {
    label: 'UTC+6:00',
    tzCode: 'Asia/Dhaka',
    name: '(UTC+06:00) Asia/Dhaka',
    utc: '+06:00'
  },
  {
    label: 'UTC+7:00',
    tzCode: 'Asia/Bangkok',
    name: '(UTC+07:00) Asia/Bangkok',
    utc: '+07:00'
  },
  {
    label: 'UTC+8:00',
    tzCode: 'Asia/Shanghai',
    name: '(UTC+08:00) Asia/Shanghai',
    utc: '+08:00'
  },
  {
    label: 'UTC+9:00',
    tzCode: 'Asia/Tokyo',
    name: '(UTC+09:00) Asia/Tokyo',
    utc: '+09:00'
  },
  {
    label: 'UTC+10:00',
    tzCode: 'Australia/Sydney',
    name: '(UTC+10:00) Australia/Sydney',
    utc: '+10:00'
  },
  {
    label: 'UTC+11:00',
    tzCode: 'Pacific/Noumea',
    name: '(UTC+11:00) Pacific/Noumea',
    utc: '+11:00'
  },
  {
    label: 'UTC+12:00',
    tzCode: 'Pacific/Fiji',
    name: '(UTC+12:00) Pacific/Fiji',
    utc: '+12:00'
  }
]

export default timeZone
