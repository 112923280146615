import React, { createContext, useContext, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { command } from '../redux/store'
import { ax } from './AxiosFactory'
import {
  orderLookup,
  getOneDetails,
  postOrderRefunded,
  sendRefundEmail,
  getRefundSupportTime,
  getLatestRefund,
  patchOrderRefund,
  updateOrderStatus,
  userSignOut,
  confirmSurveyDataDelete,
  getAllOrderDetails,
  verifyToken,
  patchUser,
  postAudiograms,
  submitReview,
  deleteAudiogram,
  getHearingTestResult,
  deleteHearingTestResult,
  patchHearingTestResult,
  saveHearingTestSurvey,
  getCheckoutUrl,
  getCheckoutUrlWithOutAccount,
  visitedCheckout,
  sendFeedBackInfo,
  sendPrefittingInfo,
  getPrefittingStatus,
  emailSubscribe,
  kycQuestionnaireSubmit,
  getAllKYCResults,
  patchKYCResult,
  getSchedule,
  getUserId,
  setCalendar,
  getAvailableTimeslots,
  createAppointment,
  rescheduleAppointment,
  cancelAppointment,
  getAppointment,
  refreshAppointment,
  setTradeinAnswer,
  patchLike,
  postStory,
  patchBlogLike,
  insiderSubscribe,
  getPromotionCode,
  verifyPromotionCode,
  verifyFeedbackSurveyCode,
  setupIntent,
  placeOrder,
  messageBoardSubscribe,
  checkProductAvailable,
  checkoutEmail,
  updateTroubleShootingComment,
  getTest,
  requestDataExport,
  requestDataDeleteAll,
  confirmDataDeleteAll,
  requestSurveyDataDelete,
  asyncUserIsFirebaseUser,
  setToken,
  setAudiograms,
  getTwoDetails,
  getAllPersonalData,
  fetchUserAudiogram,
  getOrderDetails,
  setAppointment,
  setLoading,
  setUserDetails,
  asyncUserEmailTakenStatus,
  asyncUserEmailTakenStatusAll,
  asyncUserEmailVerifiedStatus,
  asyncUserSignupFirst,
  asyncUserLogIn
} from './RemotePoint'
import {
  postUserUpdatePassword,
  postVerifyUser,
  sendVerifyEmail,
  sendResetPasswordEmail,
  userReAuth,
  decryptUser
} from './auth'
import { selector } from '@/src/redux/store'

import {
  USER_DETAIL,
  AUDIOGRAM,
  LOADING,
  ORDER_DETAIL,
  TOKEN,
  APPOINTMENT,
  UUID,
  EMAIL,
  IS_LOGIN
} from '@/src/redux/state'

const AuthContext = createContext()

export default function BackendProvider(props) {
  const userInfo = useSelector(selector.selectUserInfo)
  const dispatch = useDispatch()

  const userDetails = userInfo?.[USER_DETAIL]
  const loading = userInfo?.[LOADING] || false
  const audiograms = userInfo?.[AUDIOGRAM] || []
  const orderDetails = userInfo?.[ORDER_DETAIL] || []
  const token = userInfo?.[TOKEN] || ''
  const appointment = userInfo?.[APPOINTMENT] || null
  const signedin = typeof token !== 'undefined'

  const getLatestHearingCondition = async (userId) => {
    const latestResult = await getAllKYCResults(userId)
    const KYCDate = latestResult.data.survey_result_items
    const resultId = latestResult.data.id
    const latestHearingCondition = KYCDate.find(
      (q) =>
        q.metadata.hubspot_tag === 'kyc_HACondition' ||
        q.metadata.hubspot_tag === 'kyc_HearingCondition'
    )
    if (latestHearingCondition) {
      return {
        hearingCondition: latestHearingCondition.answer,
        kycId: resultId,
        kycQuestionData: latestHearingCondition
      }
    }
    return {
      hearingCondition: null,
      kycId: null
    }
  }

  useEffect(() => {
    const token = window.localStorage.getItem('token') || ''
    dispatch(command.actionSetUserInfo({ [TOKEN]: token }))
  }, [])

  useEffect(() => {
    const userId = getUserId()
    if (userId) {
      fetchUserAudiogram()
    }
    const getUser = async () => {
      try {
        if (token) {
          setLoading(true)
          const res = ax.get('users/', {}).then((res) => {
            dispatch(
              command.actionSetUserInfo({
                [EMAIL]: res.data.results[0].username
              })
            )
            setUserDetails(res.data.results[0])
          })
          await Promise.all([
            getOrderDetails(),
            res,
            getAppointment().then((res) => setAppointment(res?.data.results))
          ])
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
      }
    }

    try {
      if (window.localStorage.getItem('token')) {
        // User is signed in.
        const uuid = localStorage.getItem('uuid')
        dispatch(command.actionSetUserInfo({ [UUID]: uuid }))
        // check for anonymosity
      } else {
        // No user is signed in.
        setToken()
        setUserDetails()
      }
      dispatch(
        command.actionSetUserInfo({
          [IS_LOGIN]: !!window.localStorage.getItem('token')
        })
      )
    } catch (e) {
      console.error(e)
    }
    getUser()
    const updateAppointment = async () => {
      if (token) {
        const res = await getAppointment()
        setAppointment(res?.data.results)
      }
    }
    updateAppointment()
  }, [userInfo[TOKEN]])

  return (
    <AuthContext.Provider
      value={{
        // Auth
        userReAuth,
        userUpdatePassword: postUserUpdatePassword,
        asyncUserEmailTakenStatus,
        asyncUserEmailTakenStatusAll,
        asyncUserEmailVerifiedStatus,
        asyncUserIsFirebaseUser,
        asyncUserSignupFirst,
        asyncUserValidate: postVerifyUser,
        decryptUser,
        sendVerifyEmail,
        sendResetPasswordEmail,
        asyncUserLogIn,
        verifyToken,
        userSignOut,
        setToken: setToken,
        // Audiogram
        patchUser: patchUser,
        postAudiograms: postAudiograms,
        deleteAudiogram: deleteAudiogram,
        // Hearing test
        getHearingTestResult: getHearingTestResult,
        deleteHearingTestResult: deleteHearingTestResult,
        patchHearingTestResult: patchHearingTestResult,
        saveHearingTestSurvey: saveHearingTestSurvey,
        // Order && refund
        checkProductAvailable: checkProductAvailable,
        getOneDetails: getOneDetails,
        getTwoDetails: getTwoDetails,
        getAllOrderDetails: getAllOrderDetails,
        orderDetails: orderDetails,
        orderLookup: orderLookup,
        updateOrderStatus: updateOrderStatus,
        submitReview: submitReview,
        postOrderRefunded: postOrderRefunded,
        patchOrderRefund: patchOrderRefund,
        getLatestRefund: getLatestRefund,
        getRefundSupportTime: getRefundSupportTime,
        sendRefundEmail: sendRefundEmail,
        // checkout url
        getCheckoutUrl: getCheckoutUrl,
        getCheckoutUrlWithOutAccount: getCheckoutUrlWithOutAccount,
        visitedCheckout: visitedCheckout,
        placeOrder,
        // Schedule
        getSchedule: getSchedule,
        setCalendar: setCalendar,
        getAvailableTimeslots: getAvailableTimeslots,
        createAppointment: createAppointment,
        rescheduleAppointment: rescheduleAppointment,
        cancelAppointment: cancelAppointment,
        getAppointment: getAppointment,
        refreshAppointment: refreshAppointment,
        kycQuestionnaireSubmit,
        getAllKYCResults,
        getLatestHearingCondition,
        patchKYCResult,
        setTradeinAnswer: setTradeinAnswer,
        // Forum & subscribe
        postStory: postStory,
        patchLike: patchLike,
        patchBlogLike: patchBlogLike,
        emailSubscribe: emailSubscribe,
        insiderSubscribe: insiderSubscribe,
        // Insider program
        getPromotionCode: getPromotionCode,
        verifyPromotionCode: verifyPromotionCode,
        verifyFeedbackSurveyCode: verifyFeedbackSurveyCode,
        setupIntent: setupIntent,
        messageBoardSubscribe: messageBoardSubscribe,
        sendFeedBackInfo: sendFeedBackInfo,
        sendPrefittingInfo: sendPrefittingInfo,
        getPrefittingStatus: getPrefittingStatus,
        checkoutEmail: checkoutEmail,
        // trouble shooting
        updateTroubleShootingComment,
        // Page States
        userDetails: userDetails,
        audiograms: audiograms,
        setAudiograms,
        signedin: signedin,
        loading: loading,
        appointment: appointment,
        getTest: getTest,
        // Data Legal Compliance
        requestDataExport,
        requestDataDeleteAll,
        confirmDataDeleteAll,
        requestSurveyDataDelete,
        getAllPersonalData,
        confirmSurveyDataDelete
      }}
      {...props}
    />
  )
}

const useBackend = () => useContext(AuthContext)

export { BackendProvider, useBackend }
