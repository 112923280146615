import { axAudiogram, axScheduleV3, axSchedule } from '../../AxiosFactory'

const uploadAudiogram = async (audiogram, userId, source) => {
  try {
    const formData = new FormData()
    formData.append('audiogram_image', audiogram)
    formData.append('user_id', userId)
    formData.append('source', source) // 添加 'source' 字段
    const res = await axAudiogram.post(
      '/healthDataSys/audiogramImages',
      formData
    )
    return res
  } catch (e) {
    console.error(e)
  }
}

const getAudiogram = async (userId) => {
  try {
    const res = await axScheduleV3.get(
      'healthDataSys/audiogramImages?user_id=' + userId
    )
    return res
  } catch (e) {
    return e
  }
}

const deleteAudiogram = async (audiogramId) => {
  return await axAudiogram.delete(
    `healthDataSys/audiogramImages/${audiogramId}`
  )
}

export { uploadAudiogram, getAudiogram, deleteAudiogram }
