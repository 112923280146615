import { axService } from '../../AxiosFactory'
import { reportError } from '@/src/utils/frontendLogger'

const postCampaignCandidateInfo = async (info) => {
  const body = {
    email: info.email,
    phone: info.phone,
    name: info.name
  }
  try {
    await axService.post('surveys/labor_day', body)
  } catch (error) {
    console.error('Error posting candidate info:', error)
  }
}

const postFaqFeedback = async (isSatisfied, question) => {
  try {
    await axService.post('surveys/faq/satisfaction', {
      is_satisfied: isSatisfied,
      faq_question: question
    })
  } catch (error) {
    console.warn(error)
    reportError(error, 'send feedback message from faq/satisfaction error')
  }
}

const postFaqQuestion = async (data, callback) => {
  try {
    await axService.post('surveys/faq/question', data)
    callback()
  } catch (error) {
    console.warn(error)
    reportError(error, 'Send feedback from faq/question failed')
  }
}

const sendPrefittingInfo = async (info) => {
  const res = await axService.post('/survey_sys/survey_results/', info)
  return res
}

const getPrefittingStatus = async (id: string) => {
  const res = await axService.get('/survey_sys/survey_results', {
    params: { user_id: id, survey_type: 'website_pre_fitting' }
  })
  return res
}

export {
  postCampaignCandidateInfo,
  postFaqFeedback,
  postFaqQuestion,
  sendPrefittingInfo,
  getPrefittingStatus
}
