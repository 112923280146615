import { ax, axOrder } from '../../AxiosFactory'
import { getUserId, getOrderDetails } from '../../RemotePoint'

/* Get Orka Two order details */
const getTwoDetails = async () => {
  const userId = getUserId()
  const res = await axOrder.get('/orders/orders/', {
    params: {
      user_id: userId,
      'types[0]': 'normal'
    }
  })
  return res.data
}

const placeOrder = async (obj) => {
  const res = await ax.post('checkout/order/', obj)
  return res
}

const getCheckoutUrl = async (lineItems) => {
  const res = await ax.post('orders/get-redirect-url/', lineItems)
  return res
}

const getCheckoutUrlWithOutAccount = async (lineItems) => {
  const res = await ax.post(
    'orders/get-redirect-url-without-account/',
    lineItems
  )
  return res
}

const checkProductAvailable = async (data) => {
  const res = await ax.post('orders/is-available/', data)
  return res
}

const orderLookup = async (id, email) => {
  const res = await ax.get('orders/order-lookup/', { params: { id, email } })
  return res.data.result
}

const getOneDetails = async () => {
  const res = await ax.get('orders/')
  return res.data
}

const postOrderRefunded = async (refundReason, id) => {
  const res = await ax.post(`orders/${id}/refund/`, refundReason)
  await getOrderDetails()
  return res
}

const sendRefundEmail = (refundSupportTime) => {
  const res = ax.post('orders/send-refund-email/', refundSupportTime)
  return res
}

const getLatestRefund = async (id) => {
  const res = await ax.get(`orders/${id}/refund-status`)
  return res
}

const updateOrderStatus = async (status, id) => {
  const res = await ax.post('orders/update-bc-status/', {
    status: status,
    id: id
  })
  return res
}

export {
  getTwoDetails,
  placeOrder,
  getCheckoutUrl,
  getCheckoutUrlWithOutAccount,
  checkProductAvailable,
  orderLookup,
  getOneDetails,
  postOrderRefunded,
  sendRefundEmail,
  getLatestRefund,
  updateOrderStatus
}
