import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface HeaderState {
  height: number
}

const initialState: HeaderState = { height: 0 }
export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeaderHeight: (state, action: PayloadAction<number>) => {
      state.height = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setHeaderHeight } = headerSlice.actions

export default headerSlice.reducer
