import { createTheme } from '@mui/material/styles'
import orkauiTheme from '@evocolabs/orkaui/lib/theme.cjs'

const palette = orkauiTheme.palette

const fontFamily = 'Open Sans'

const theme = createTheme({
  palette: palette,
  fontFamily: fontFamily,
  spacing: (value) => value * 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, // Phone-Max
      md: 905, // Tablet-Max
      dl: 1024, // Desktop-Max
      lg: 1538, // PC-Max
      xl: 1920
    }
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: palette.grey[800],
          fontSize: '12px'
        },

        colorError: {
          color: 'red'
        }
      }
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          '&:hover': {
            color: palette.primary.main
          }
        }
      }
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.2)'
        }
      }
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        },

        elevation1: {
          boxShadow: 'none',
          '&:before': {
            display: 'none'
          }
        }
      }
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: '#FFFFFF',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '18px',
          letterSpacing: '-0.15px',
          borderRadius: '10px'
        }
      }
    },

    MuiExpansionPanel: {
      styleOverrides: {
        root: {
          marginBottom: 16
        }
      }
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: 16
        }
      }
    },

    MuiStepper: {
      styleOverrides: {
        root: {
          padding: '24px 0'
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    }
  }
})

theme.components.MuiTypography.styleOverrides = {
  root: {
    padding: 0
  },

  h1: {
    fontWeight: 700,
    fontSize: '3.5rem', // 56px
    color: palette.grey[800],
    letterSpacing: '-1.5px',
    lineHeight: 1.25,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem' // 40px
    }
  },

  h2: {
    fontSize: '2.5rem', // 40px
    fontWeight: 700,
    color: palette.grey[800],
    letterSpacing: '-1.5px',
    lineHeight: 1.25,
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem', // 32px
      letterSpacing: '-1px'
    }
  },

  h3: {
    fontSize: '1.5rem', // 24px
    fontWeight: 600,
    color: palette.grey[800],
    letterSpacing: '-0.4px',
    lineHeight: 1.5
  },

  h4: {
    fontSize: '1.25rem', // 20px
    letterSpacing: '-0.4px',
    color: palette.grey[800],
    fontWeight: 600,
    lineHeight: 1.25
  },

  body1: {
    fontWeight: 400,
    fontSize: '1.125rem', // 18px
    color: palette.grey[800],
    lineHeight: 1.5,
    wordBreak: 'break-word'
  },

  body2: {
    fontWeight: 400,
    fontSize: '1rem', // 16px
    lineHeight: 1.5,
    letterSpacing: '-0.2px',
    color: palette.grey[800]
  },

  caption: {
    fontSize: '0.875rem', // 14px
    fontWeight: 400,
    lineHeight: 1.2,
    letterSpacing: 0,
    color: palette.grey[600]
  },

  subh1: {
    fontSize: '1.125rem', // 18px
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.5px',
    color: palette.grey[800]
  },

  subh2: {
    fontFamily: fontFamily,
    fontWeight: 500,
    fontSize: '1rem', // 16px
    lineHeight: 1.25,
    letterSpacing: '-0.24px',
    color: palette.grey[800]
  },

  button: {
    fontWeight: 700,
    fontSize: '1rem', // 16px
    letterSpacing: 0,
    lineHeight: 1.2,
    color: palette.primary[600]
  },

  link1: {
    fontWeight: 600,
    fontSize: '1rem', // 16px
    lineHeight: 1.2,
    letterSpacing: '-0.2px',
    color: palette.primary[600],
    textDecoration: 'underline',
    textTransform: 'capitalize',
    textUnderlineOffset: '2px'
  },

  link2: {
    fontWeight: 600,
    fontSize: '0.875rem', // 14px
    lineHeight: 1.2,
    letterSpacing: '-0.2px',
    color: palette.grey[600],
    textDecoration: 'underline',
    textTransform: 'capitalize',
    textUnderlineOffset: '2px',
    cursor: 'pointer'
  }
}

export default theme
