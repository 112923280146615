import { ax } from '../../AxiosFactory'

const getPromotionCode = async (info) => {
  const res = await ax.post('insider-program/promotion-code/', info)
  return res
}

const verifyPromotionCode = async (codeInfo) => {
  const res = await ax.post('insider-program/coupon-code/verify/', codeInfo)
  return res
}

export { getPromotionCode, verifyPromotionCode }
