import { ax } from '../../AxiosFactory'

const getSchedule = async (timeStart, timeEnd) => {
  const res = await ax.get('agent/getevents/', {params: {timeStart, timeEnd}})
  return res
}

const setCalendar = async (scheduleInfo) => {
  const res = await ax.post('agent/send-email/', { info: scheduleInfo })
  return res
}

export { getSchedule, setCalendar }
